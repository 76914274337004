<div id="default-fixed-menu" class="flex-align-items bg-white rounded shadow">
  <app-site-selector
    class="flex-align-items"
    style="margin: 0 10px; gap: 10px; flex-wrap: wrap"
    [ctx]="ctx"
    (change)="refresh()"
    [showTags]="ctx != ADMIN_CONTEXT"
  />
  <div class="flex-align-items" style="flex-wrap: wrap; gap: 10px; margin-left: auto">
    <button
      app-date-range-selector
      [ranges]="['15M', '1H', '4H', 'T', 'Y', '7', '30']"
      [minDateDays]="29"
      [start]="sites[ctx].current.period.start"
      [end]="sites[ctx].current.period.end"
      [ctx]="ctx"
      [timePicker]="true"
      (period)="onPeriodEmitted($event)"
      class="btn btn-primary"
      id="reportrange-dashboard{{ ctx === ADMIN_CONTEXT && '-admin' }}"
      style="position: relative; left: -5px"
    >
      <i class="icofont icofont-ui-calendar"></i>&nbsp; <span class="d-none d-md-inline-block"></span>&nbsp;&nbsp;
      <div class="arrow-down"></div>
    </button>
    <button
      class="btn btn-primary btn-reload"
      title="{{ 'Refresh' | translate }}"
      (click)="refresh()"
      [disabled]="isLoading"
    >
      <i class="icon-reload"></i>
    </button>
    <div
      class="switch-refresh flex-align-items space-between ml-3"
      style="position: relative; top: 5px; margin: 0 10px"
      [hidden]="!sites.showAutoRefresh(ctx)"
    >
      <div class="switch-refresh-text">
        <label style="padding: 0 10px 0 0" class="text-ellipsis">{{ 'RefreshEveryMinute' | translate }}</label>
        <div class="loading-bar activity" [ngStyle]="{ 'width.%': ((countNextRefresh % 60) / 60) * 100 }"></div>
      </div>
      <div class="switch-container ml-1">
        <label class="switch">
          <input
            type="checkbox"
            [(ngModel)]="sites[ctx].current.period.autoRefresh"
            (change)="sites[ctx].current.period.autoRefresh ? setRefreshInterval() : clearRefreshInterval()"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isNewOrga()" class="row" class="to-right">
  <a
    *ngIf="ctx != ADMIN_CONTEXT && !isLoading && sites[ctx]?.store.sites.length > 0; else noPrint"
    class="ogo-link"
    (click)="toPrint()"
    >{{ 'PrintThisReport' | translate }}</a
  >
  <ng-template #noPrint>&nbsp;</ng-template>
</div>

<div *ngIf="isNewOrga()" class="bg-blue new-site">
  {{ 'WelcomeOgo' | translate }}&nbsp;
  <a (click)="editHote()" class="new-site-link">{{ 'AjouterUnSite' | translate }}</a>
  {{ 'ToStart' | translate }}
</div>

<div class="row">
  <div class="col-md-6 col-xl-3">
    <div class="card bg-c-pink order-card">
      <div class="counts">
        <div class="flex space-between">
          <div class="counts-text">
            <h6
              class="m-b-20 clickable text-ellipsis"
              (click)="trackAction('blocked')"
              [innerHTML]="'Bloquees' | translate"
            ></h6>
            <ng-container *ngIf="!isLoading">
              <h2 class="text-ellipsis" *ngIf="totals" [title]="totals.attack | numberFormat">
                {{ totals[BLOCKED_BY_BRAIN] | numberFormat }}
              </h2>
              <h2 class="text-ellipsis" *ngIf="!totals">0</h2>
            </ng-container>
            <span *ngIf="isLoading" class="text-placeholder cards">
              <span class="activity"></span>
            </span>
          </div>
          <app-donut [data]="repartitions?.[BLOCKED_BY_BRAIN]" (sliceClicked)="selectSite($event.name)"></app-donut>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-xl-3">
    <div class="card bg-c-suspect order-card">
      <div class="counts">
        <div class="flex space-between">
          <div class="counts-text">
            <h6
              class="m-b-20 clickable text-ellipsis"
              (click)="trackAction('suspicious')"
              [innerHTML]="'Suspectes' | translate"
            ></h6>
            <ng-container *ngIf="!isLoading">
              <h2 class="text-ellipsis" *ngIf="totals" [title]="totals['suspect'] | numberFormat">
                {{ totals['suspect'] | numberFormat }}
              </h2>
              <h2 class="text-ellipsis" *ngIf="!totals">0</h2>
            </ng-container>
            <span *ngIf="isLoading" class="text-placeholder cards">
              <span class="activity"></span>
            </span>
          </div>
          <app-donut [data]="repartitions?.[SUSPECT]" (sliceClicked)="selectSite($event.name)"></app-donut>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-xl-3">
    <div class="card bg-c-robot order-card">
      <div class="counts">
        <div class="flex space-between">
          <div class="counts-text">
            <h6 class="m-b-20 text-ellipsis" [innerHTML]="'Robots' | translate"></h6>
            <ng-container *ngIf="!isLoading">
              <h2 class="text-ellipsis" *ngIf="totals" [title]="totals['robot'] | numberFormat">
                {{ totals['robot'] | numberFormat }}
              </h2>
              <h2 class="text-ellipsis" *ngIf="!totals">0</h2>
            </ng-container>
            <span *ngIf="isLoading" class="text-placeholder cards">
              <span class="activity"></span>
            </span>
          </div>
          <app-donut [data]="repartitions?.[ROBOT]" (sliceClicked)="selectSite($event.name)"></app-donut>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-xl-3">
    <div class="card bg-c-green order-card">
      <div class="counts">
        <div class="flex space-between">
          <div class="counts-text">
            <h6
              class="m-b-20 clickable text-ellipsis"
              (click)="trackAction('')"
              [innerHTML]="'RequetesAnalysees' | translate"
            ></h6>
            <ng-container *ngIf="!isLoading">
              <h2 class="text-ellipsis" *ngIf="totals" [title]="totals[ANALYZED] | numberFormat">
                {{ totals[ANALYZED] | numberFormat }}
              </h2>
              <h2 class="text-ellipsis" *ngIf="!totals">0</h2>
            </ng-container>
            <span *ngIf="isLoading" class="text-placeholder cards">
              <span class="activity"></span>
            </span>
          </div>
          <app-donut [data]="repartitions?.[ANALYZED]" (sliceClicked)="selectSite($event.name)"></app-donut>
        </div>
      </div>
    </div>
  </div>

  <div id="app-donut" class="col-xl-6">
    <div class="card" style="min-height: 400px">
      <div *ngIf="isLoading" class="theme-loader flex">
        <div class="loader-track">
          <div class="loader-bar"></div>
        </div>
      </div>
      <app-donut
        *ngIf="!isLoading && blockAttack.length != 0; else !isLoading && emptyAttacks"
        [data]="blockAttack"
        [title]="translate.instant('TypesAttaques')"
      />
      <ng-template #emptyAttacks>
        <p class="graph-title-no-data text-center mt-3">{{ 'TypesAttaques' | translate }}</p>
        <p class="text-center" style="margin-top: 140px">{{ 'NoData' | translate }}</p>
      </ng-template>
    </div>
  </div>

  <div id="app-graph" class="col-xl-6">
    <div class="card" style="min-height: 400px">
      <div *ngIf="isLoading" class="theme-loader flex">
        <div class="loader-track">
          <div class="loader-bar"></div>
        </div>
      </div>
      <span *ngIf="!isLoading && totals[TRAFIC] != 0 && chartInterval" class="chart-interval"
        >Interval : {{ chartInterval | durationSeconds }}</span
      >
      <app-graph
        *ngIf="!isLoading && totals[TRAFIC] != 0; else !isLoading && emptyTrafic"
        [exportButtonSpacing]="{ x: 20, y: -30 }"
        [data]="audience"
        [cursor]="'default'"
        (zoneSelected)="onPeriodEmittedFromZoneSelected($event)"
      ></app-graph>
      <ng-template #emptyTrafic>
        <p class="graph-title-no-data text-center mt-3">{{ 'AnalyseTrafic' | translate }}</p>
        <p class="text-center" style="margin-top: 140px">{{ 'NoData' | translate }}</p>
      </ng-template>
    </div>
  </div>

  <div class="col-md-12 world-map">
    <div class="card">
      <div class="btn-group" role="group">
        <button
          (click)="changeOrigine(TRAFIC)"
          type="button"
          class="btn btn-square"
          [class.btn-inverse]="originSelected == TRAFIC"
        >
          {{ 'Requests' | translate }}
        </button>
        <button
          (click)="changeOrigine(ROBOT)"
          type="button"
          class="btn btn-square"
          [class.btn-inverse]="originSelected == ROBOT"
        >
          {{ 'Robots' | translate }}
        </button>
        <button
          (click)="changeOrigine(BLOCKED)"
          type="button"
          class="btn btn-square"
          [class.btn-inverse]="originSelected == BLOCKED"
        >
          {{ 'RequetesBloqueesByAI' | translate }}
        </button>
        <button
          (click)="changeOrigine(BLOCKED_COUNTRY)"
          type="button"
          class="btn btn-square"
          [class.btn-inverse]="originSelected == BLOCKED_COUNTRY"
          [ngbTooltip]="sites[ctx]?.current.selectedSites.length != 1 ? ('MustSelectOneSite' | translate) : ''"
          [disabled]="sites[ctx]?.current.selectedSites.length != 1"
        >
          {{ 'Blocked Countries' | translate }}
        </button>
      </div>
      <app-world-map
        [origine]="originSelected"
        [data]="pays ? pays[originSelected] : null"
        [height]="'700px'"
        (countryClick)="onCountryClick($event)"
        class="app-world-map"
      />
    </div>
  </div>

  <div class="col-md-12" *ngIf="isAdminContext() ? isASiteSelected() : true">
    <div class="card">
      <div
        class="btn-group"
        data-original-title=".btn-xlg"
        data-placement="top"
        data-toggle="tooltip"
        role="group"
        title=""
      >
        <button
          (click)="selectTable(Table.TOP_BLOCKED_IPS)"
          class="btn btn-md btn-square waves-effect waves-light"
          [ngClass]="{ 'btn-inverse': pageSelected == Table.TOP_BLOCKED_IPS }"
          type="button"
          [innerHTML]="'topBlockedIps' | translate"
        ></button>
        <button
          (click)="selectTable(Table.SLOW_REQUESTS)"
          class="btn btn-md btn-square waves-effect waves-light"
          [ngClass]="{ 'btn-inverse': pageSelected == Table.SLOW_REQUESTS }"
          type="button"
          [innerHTML]="'RequetesLentes' | translate"
        ></button>
        <button
          (click)="selectTable(Table.CPU_CONSUMING_REQUESTS)"
          class="btn btn-md btn-square waves-effect waves-light"
          [ngClass]="{ 'btn-inverse': pageSelected == Table.CPU_CONSUMING_REQUESTS }"
          type="button"
          [innerHTML]="'PagesPlusVisitees' | translate"
        ></button>
      </div>
      <div *ngIf="!isTableLoading; else loader">
        <ng-container [ngSwitch]="pageSelected">
          <ng-container *ngSwitchCase="Table.TOP_BLOCKED_IPS">
            <app-blocked-ips-table
              [topBlockedIps]="topBlockedIps"
              (trackCountry)="trackCountry($event)"
              (trackIp)="trackIp($event)"
              (whitelistIp)="addWhitelistedIp($event)"
            />
          </ng-container>

          <ng-container *ngSwitchCase="Table.SLOW_REQUESTS">
            <app-slow-requests-table [slowRequests]="slowRequests" (clickPath)="trackResponseTime($event)" />
          </ng-container>

          <ng-container *ngSwitchCase="Table.CPU_CONSUMING_REQUESTS">
            <app-cpu-consuming-requests-table
              [cpuConsumingRequests]="cpuConsumingRequests"
              (clickPath)="trackResponseTime($event)"
            />
          </ng-container>
        </ng-container>
      </div>
      <ng-template #loader>
        <div class="component-loader">
          <div class="loader-track">
            <div class="loader-bar"></div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
