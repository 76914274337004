import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService as NgxToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(private toastr: NgxToastrService) {}
  error<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<any> {
    return this.toastr.error(message, title, { timeOut: 30000, extendedTimeOut: 300000, ...override });
  }

  success<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<any> {
    return this.toastr.success(message, title, override);
  }

  info<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<any> {
    return this.toastr.info(message, title, override);
  }

  warning<ConfigPayload = any>(message?: string, title?: string, override?: Partial<IndividualConfig<ConfigPayload>>): ActiveToast<any> {
    return this.toastr.warning(message, title, override);
  }

  clear(toastId?: number): void {
    this.toastr.clear(toastId);
  }
}
