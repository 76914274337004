<ngx-datatable
  [messages]="{ emptyMessage: translate.instant('NoData') }"
  #myTable
  class="data-table table-responsive task-list-table"
  [limit]="print ? undefined : 10"
  [columnMode]="'flex'"
  [rows]="cpuConsumingRequests"
  [headerHeight]="'auto'"
  [footerHeight]="50"
>
  <ngx-datatable-column [flexGrow]="4" name="Sites" headerClass="site" cellClass="site">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'site' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.site }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="7" name="Pages" headerClass="path" cellClass="path">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'pages' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span class="clickable" (click)="clickPath.emit(row)">{{ row.path }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="Nombre" headerClass="number" cellClass="number">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'nombre' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.count }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" [name]="'Temps (Moyen)'" headerClass="duration" cellClass="duration">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'TempsMoyen' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <ng-template #popContent>
        <div>Min: {{ row.min | duration }}</div>
        <div>Avg: {{ row.avg | duration }}</div>
        <div>Max: {{ row.max | duration }}</div>
      </ng-template>
      <span
        class="pointer" container="body" triggers="mouseenter:mouseleave"
        [ngbPopover]="popContent">{{ row.avg | duration }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [flexGrow]="2" name="ConsumedTime" headerClass="duration" cellClass="duration">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'ConsumedTime' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.sum | duration }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template let-rowCount="rowCount" let-curPage="curPage" let-pageSize="pageSize">
      <datatable-pager
        [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'"
        [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'"
        [page]="curPage"
        [size]="pageSize"
        [count]="rowCount"
        (change)="myTable.onFooterPage($event)"/>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
