import { Validators } from './../../shared/validators/validators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { Global } from '../../global';
import { HostModeService } from '../../shared/hostMode/hostMode.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { copyObject, subnet } from '../../shared/utils/data-utils';
import { AuthService } from 'app/services/auth.service';
import { EXTERNAL_HELP_ENGLISH_LINK, EXTERNAL_HELP_FRENCH_LINK } from 'app/app-routing.module';

@Component({
  selector: 'app-access-control-rules',
  templateUrl: './access-control-rules.component.html',
  styleUrls: ['./access-control-rules.component.scss', '../../../assets/icon/icofont/css/icofont.scss', '../add-site.component.scss'],
})
export class AccessControlRulesComponent implements OnInit {
  lang: string;

  @Input() hote: any = {};
  @Input() rules: any = [];
  @Output() getRules = new EventEmitter();

  @ViewChild('accessRuleUriElement') accessRuleUriElement: ElementRef;

  tmpRule: any = {};
  rulesInProgress = false;
  uriFocused = false;
  whitelistFocused = false;

  EXTERNAL_HELP_FRENCH_LINK = EXTERNAL_HELP_FRENCH_LINK;
  EXTERNAL_HELP_ENGLISH_LINK = EXTERNAL_HELP_ENGLISH_LINK;

  constructor(
    private toastr: ToastrService,
    private http: HttpClient,
    private translate: TranslateService,
    private auth: AuthService,
    public hostModeService: HostModeService,
  ) {}

  ngOnInit(): void {
    this.lang = this.auth.getCurrentLanguage();
  }

  onFocusUri(element) {
    if (!element.value) element.value = '/';
  }

  onBlurUri(element) {
    if (element.value == '/') element.value = '';
  }

  addToRule(rule, category, event) {
    let value = event.target.value.trim();

    if (category == 'whitelistedIps') {
      value = subnet(value);
    }

    if (rule[category].find((element) => element == value)) {
      return this.toastr.error(this.translate.instant('AlreadyExists'));
    }

    rule[category].push(value);

    if (category == 'uris') {
      event.target.value = '/';
    } else {
      event.target.value = '';
    }
  }

  deleteFromRule(rule, category, value) {
    rule[category].splice(
      rule[category].findIndex((item) => item == value),
      1,
    );
  }

  startEditing(rule) {
    this.tmpRule = copyObject(rule); // deep copy in order to restore if cancelling editing
    rule.editing = true;
  }

  validateEditing(rule) {
    if (rule.uris.length && rule.whitelistedIps.length) {
      rule.editing = false;

      if (rule.hasOwnProperty('new')) {
        delete rule.new;
      }

      const { editing, createdAt, updatedAt, ...data } = rule;

      if (!rule.id) {
        this.rulesInProgress = true;
        this.http
          .post(Global.baseUrl + 'rule/create', {
            datas: [
              {
                hoteId: this.hote.id,
                ...data,
              },
            ],
          })
          .subscribe(
            (res: any) => {
              if (!res.hasError) {
                this.rules[this.rules.findIndex((r) => r == rule)] = res.items[0];
                this.toastr.success(this.translate.instant('OperationSuccess'));
                this.getRules.emit();
              } else {
                this.rules.splice(
                  this.rules.findIndex((r) => r == rule),
                  1,
                );
                this.toastr.error(this.translate.instant('OperationFailed'));
              }
              this.rulesInProgress = false;
            },
            (error: any) => {
              console.log('Error : ', error);
            },
          );
      } else {
        this.updateRules([{ hoteId: this.hote.id, ...data }]);
      }
    }
  }

  updateRules(datas) {
    this.rulesInProgress = true;
    this.http
      .post(Global.baseUrl + 'rule/update', {
        datas: datas,
      })
      .subscribe(
        (res: any) => {
          if (!res.hasError) {
            res.items.forEach((item) => {
              this.rules[this.rules.findIndex((r) => r.id == item.id)] = item;
            });
            this.toastr.success(this.translate.instant('OperationSuccess'));
            this.getRules.emit();
          } else {
            this.toastr.error(this.translate.instant('OperationFailed'));
          }
          this.rulesInProgress = false;
        },
        (error: any) => {
          console.log('Error : ', error);
        },
      );
  }

  cancelEditing(rule) {
    rule.editing = false;
    this.rules[this.rules.findIndex((r) => r.priority == rule.priority)] = this.tmpRule;
  }

  addRule() {
    if (!this.hostModeService.isAdvanced(this.hote.mode)) {
      return this.hostModeService.promptSuscribeToMode('SuscribeToAdvanced');
    }

    this.rules.push({
      new: true, // front only
      editing: true, // front only
      priority: this.rules.length
        ? Math.max.apply(
            Math,
            this.rules.map((o) => o.priority),
          ) + 1
        : 1,
      isActive: true,
      uris: [],
      whitelistedIps: [],
      action: 'brain',
      comments: '',
    });
    setTimeout(() => this.accessRuleUriElement.nativeElement.focus(), 1);
  }

  deleteRule(rule) {
    return Swal.fire({
      html: this.translate.instant(rule.id ? 'VoulezVousAbandonnerCetteNouvelleRegle' : 'VoulezVousSupprimerCetteRegle'),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
      title: this.translate.instant('Confirmation'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        if (rule.id) {
          this.rulesInProgress = true;
          this.http
            .post(Global.baseUrl + 'rule/delete', {
              datas: [
                {
                  id: rule.id,
                },
              ],
            })
            .subscribe(
              (res: any) => {
                if (!res.hasError) {
                  this.toastr.success(this.translate.instant('OperationSuccess'));
                  this.rules.splice(
                    this.rules.findIndex((r) => r.id == rule.id),
                    1,
                  );
                  this.rules.filter((r) => r.priority > rule.priority).map((r) => (r.priority -= 1));
                } else {
                  this.toastr.error(this.translate.instant('OperationFailed'));
                }
                this.rulesInProgress = false;
                this.getRules.emit();
              },
              (error: any) => {
                console.log('Error : ', error);
              },
            );
        } else {
          this.rules.splice(
            this.rules.findIndex((r) => r.new),
            1,
          );
        }
      }
    });
  }

  isCreatingRule() {
    return this.rules.some((rule) => rule.new);
  }

  isEditingRule() {
    return this.rules.some((rule) => rule.editing);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.rules, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.rules.map((rule, index) => (rule.priority = index + 1));
      this.updateRules([this.rules[event.currentIndex]]);
    }
  }

  validateUri(uri) {
    return Validators.validateUri(uri);
  }

  validateIp(ip) {
    return Validators.validateIp(ip);
  }
}
