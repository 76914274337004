import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextEnum, SitesService } from 'app/services/sites.service';
import { DATE_LAST_90_DAYS, DATE_NOW } from 'app/shared/date-range-selector/calendar-data.service';
import { Bar } from 'app/shared/highcharts/bar-horizontal/bar';
import {
  BANDWIDTH,
  computeInterval,
  Graph,
  GraphType,
  RepartitionType,
  RESPONSE_TIME,
  RESPONSE_TIME_SUM,
  RESPONSES_CODES,
  TRAFIC,
  TRAFIC_SORT_BY_BLOCKED,
} from 'app/shared/highcharts/graph/graph';
import moment from 'moment';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { DateRange, durationMs } from 'app/shared/utils/date-range';
import { Router } from '@angular/router';
import {
  DateHistogramLogRequest,
  LogFilters,
  LogRequest,
  LogsService,
  Rps,
  SiteTrafficBreakdown,
  UrlBandwidth,
  UrlResponseCodesCount,
  UrlStats,
} from 'app/services/logs.service';
import { ClickEvent } from 'app/shared/highcharts/graph/graph.component';
import Highcharts from 'highcharts';
import { map } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'app-my-logs-graph',
  templateUrl: './my-logs-graphs.component.html',
  styleUrls: ['./my-logs-graphs.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class MyLogsGraphsComponent implements OnInit, AfterViewInit {
  @Input() filters: LogFilters;
  @Input() ctx: ContextEnum;

  @Output() trackResponseTimeGte = new EventEmitter<number>();
  @Output() trackStatusCode = new EventEmitter<string>();
  @Output() trackAction = new EventEmitter<string>();
  @Output() trackContentLengthGte = new EventEmitter<number>();
  @Output() refresh = new EventEmitter<void>();
  @Output() getLogs = new EventEmitter<void>();
  @Output() onPeriodEmitted = new EventEmitter<DateRange>();

  @ViewChild('graphOverTimePanel') graphOverTimePanel: ElementRef;
  @ViewChild('graphRepartitionsPanel') graphRepartitionsPanel: ElementRef;
  @ViewChild('graphRepartitionsContainer') graphRepartitionsContainer: ElementRef;
  @ViewChild('graphRepartitions') graphRepartitions: ElementRef;

  breakpointSubscription: Subscription;

  graphType: GraphType = TRAFIC;
  repartitionsType: RepartitionType = TRAFIC;

  lang: string;
  Math = Math;

  TRAFIC: GraphType = TRAFIC;
  RESPONSE_TIME: GraphType = RESPONSE_TIME;
  RESPONSE_TIME_SUM: RepartitionType = RESPONSE_TIME_SUM;
  RESPONSES_CODES: GraphType = RESPONSES_CODES;
  BANDWIDTH: GraphType = BANDWIDTH;
  TRAFIC_SORT_BY_BLOCKED: RepartitionType = TRAFIC_SORT_BY_BLOCKED;

  graphOverTimeData: Partial<{ [property in keyof GraphType]: Highcharts.Chart }> = {};
  graphRepartitionsData: Partial<{ [property in keyof RepartitionType]: Highcharts.Chart }> = {};

  chartInterval = null;

  isRepartitionsPanelOpened = true;

  graphsRendered = {
    overTime: false,
    repartitions: false,
  };

  constructor(
    private auth: AuthService,
    private logsService: LogsService,
    public sites: SitesService,
    public translate: TranslateService,
    private router: Router,
    private graph: Graph,
    private bar: Bar,
    private renderer: Renderer2,
  ) {
    this.graphType = this.getRouterParam('graphType') || TRAFIC;
  }

  ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngAfterViewInit() {
    // this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 1395px)']).subscribe(() => {
    //   this.openRepartitionsPanel();
    // });
  }

  ngOnDestroy() {
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }
  }

  resetData() {
    this.graphOverTimeData = {};
    this.graphRepartitionsData = {};
  }

  onPeriodEmittedFromZoneSelected(p: DateRange) {
    this.onPeriodEmitted.emit(p);
  }

  toggleRepartitionsPanel() {
    this.graphRepartitionsPanel.nativeElement.classList.contains('collapsed')
      ? this.openRepartitionsPanel()
      : this.closeRepartitionsPanel();
  }

  openRepartitionsPanel() {
    this.isRepartitionsPanelOpened = true;
    if (!this.graphRepartitionsData[this.repartitionsType]?.series?.length) {
      this.loadGraphRepartitions();
    }
    this.graphRepartitionsPanel.nativeElement.classList.remove('collapsed');
    this.graphOverTimePanel.nativeElement.classList.remove('expanded');
    this.renderer.setStyle(this.graphRepartitions.nativeElement, 'min-width', 'unset');
  }

  closeRepartitionsPanel() {
    this.isRepartitionsPanelOpened = false;
    const minWidth = this.graphRepartitions.nativeElement.offsetWidth;
    this.renderer.setStyle(this.graphRepartitions.nativeElement, 'min-width', `${minWidth}px`); // chart doesn't collapse during opening with this hack
    this.graphOverTimePanel.nativeElement.classList.add('expanded');
    this.graphRepartitionsPanel.nativeElement.classList.add('collapsed');
  }

  async setGraphType(graphType: GraphType) {
    this.graphType = graphType;
    this.repartitionsType = graphType;
    return this.loadGraphs();
  }

  async loadGraphs() {
    return Promise.all([this.loadGraphOverTime(), this.loadGraphRepartitions()]);
  }

  async loadGraphOverTime() {
    const type = this.graphType;

    if (this.graphOverTimeData[type]?.series?.length) {
      // already have data for this selection, no need to reload
      return;
    }

    this.graphsRendered.overTime = false;

    let res: any = await firstValueFrom(
      this.logsService.getDateHistograms(type, this.getDateHistogramLogRequest(), this.ctx),
    );
    this.graphOverTimeData = { ...this.graphOverTimeData, [type]: this.graph.formatGraphData(type, res, false) };
  }

  async setRepartitionType(repartitionType: RepartitionType) {
    this.repartitionsType = repartitionType;
    return this.loadGraphRepartitions();
  }

  async loadGraphRepartitions() {
    if (!this.isRepartitionsPanelOpened) {
      return;
    }

    const type = this.repartitionsType;

    if (this.graphRepartitionsData[type]?.series?.length) {
      // already have data for this selection, no need to reload
      return;
    }

    this.graphsRendered.repartitions = false;

    this.graphRepartitionsData[type] = await graphBuilders[type](this.logsService, this.bar).buildGraph(
      this.getLogRequest(),
      this.ctx,
    );

    this.graphsRendered.repartitions = true;
  }

  repartitionsCategoryClicked(url: string) {
    const decomposedUrl = url.split('/');
    this.sites.setSite(this.ctx, decomposedUrl.at(0));
    this.filters.path = decomposedUrl.length > 1 ? '/' + decomposedUrl.slice(1).join('/') : '';
    this.refresh.emit();
  }

  repartitionsBarClicked(event) {
    switch (this.graphType) {
      case TRAFIC: {
        this.sites.setSite(this.ctx, event?.point?.category);
        this.filters.action = event?.point?.series?.userOptions?.custom?.slug;
        break;
      }

      case RESPONSE_TIME: {
        const decomposedUrl = event?.point?.category.split('/');
        this.sites.setSite(this.ctx, decomposedUrl.at(0));
        this.filters.path = decomposedUrl.length > 1 ? '/' + decomposedUrl.slice(1).join('/') : '';
        break;
      }

      case RESPONSES_CODES: {
        const decomposedUrl = event?.point?.category.split('/');
        this.sites.setSite(this.ctx, decomposedUrl.at(0));
        this.filters.statusCodes = [event?.point?.series?.userOptions?.custom?.slug];
        break;
      }

      case BANDWIDTH: {
        const decomposedUrl = event?.point?.category.split('/');
        this.sites.setSite(this.ctx, decomposedUrl.at(0));
        this.filters.path = decomposedUrl.length > 1 ? '/' + decomposedUrl.slice(1).join('/') : '';
        this.filters.cacheStatus = event?.point?.series?.userOptions?.custom?.slug;
        break;
      }

      default:
        break;
    }

    this.refresh.emit();
  }

  zoomOut() {
    const period = this.sites[this.ctx].current.period;
    const interval = durationMs(period);

    let start = moment.max([moment(period.start).subtract(Math.round(interval / 2)), DATE_LAST_90_DAYS()]);
    const end = moment.min([moment(start).add(interval * 2), DATE_NOW()]);
    start = moment.max([moment(end).subtract(interval * 2), DATE_LAST_90_DAYS()]);

    this.onPeriodEmitted.emit({ start, end });
  }

  strafe(direction: StrafeDirection) {
    const now = DATE_NOW();
    const period = this.sites[this.ctx].current.period;
    const interval = durationMs(period);
    const shift = Math.round(interval / 2);

    let start, end;
    if (direction == 'right') {
      end = moment.min(now, moment(period.end).add(shift));
      start = moment(end).subtract(interval);
    } else if (direction == 'left') {
      start = moment.max(DATE_LAST_90_DAYS(), moment(period.start).subtract(shift));
      end = moment(start).add(interval);
    }

    this.onPeriodEmitted.emit({ start, end });
  }

  canGoForward(end) {
    return moment(end).isBefore(moment());
  }

  disableGoForward(end) {
    return moment(end).isSame(moment(), 'minute') || !this.canGoBack(end);
  }

  canGoBack(start) {
    return moment(start).isAfter(DATE_LAST_90_DAYS());
  }

  disableGoBack(start) {
    return moment(start).isSame(DATE_LAST_90_DAYS(), 'minute') || !this.canGoForward(start);
  }

  track(event: ClickEvent) {
    switch (event.category) {
      case RESPONSE_TIME:
        return this.trackResponseTimeGte.emit(event.slug);
      case TRAFIC:
        return this.trackAction.emit(event.slug);
      case RESPONSES_CODES:
        return this.trackStatusCode.emit(event.slug);
      case BANDWIDTH:
        return this.trackContentLengthGte.emit(event.slug);
    }
  }

  getDateHistogramLogRequest(): DateHistogramLogRequest {
    this.chartInterval = computeInterval(this.sites[this.ctx].current.period);
    return {
      ...this.getLogRequest(),
      aggregationTimeBound: `${this.chartInterval}s`,
    };
  }

  getLogRequest(): LogRequest {
    return { ...this.sites[this.ctx].buildRequestData(), ...this.filters };
  }

  getRouterParam(param) {
    return this.router?.getCurrentNavigation()?.extras?.state?.[param];
  }

  noSitesFallback() {
    // if no sites, give to highcharts fake chart with empty data
    Object.keys(this.graphsRendered).forEach((key) => (this.graphsRendered[key] = true));
    [TRAFIC, TRAFIC_SORT_BY_BLOCKED, RESPONSE_TIME, RESPONSES_CODES, BANDWIDTH].forEach((t) => {
      this.graphOverTimeData[t] = { chart: {}, lang: {}, title: { text: null }, credits: { enabled: false } };
      this.graphRepartitionsData[t] = {
        chart: {},
        lang: {},
        title: { text: null },
        credits: { enabled: false },
        plotOptions: { series: { cursor: null, events: {} } },
      };
    });
  }
}

type StrafeDirection = 'left' | 'right';

abstract class BreakdownGraphBuilder<T> {
  constructor(
    protected readonly logs: LogsService,
    protected readonly bars: Bar,
  ) {}

  protected abstract getData(request: LogRequest, context: ContextEnum): Observable<T[]>;

  protected abstract formatData(data: T[]): Highcharts.Options;

  public async buildGraph(request: LogRequest, context: ContextEnum): Promise<Highcharts.Options> {
    const data = await firstValueFrom(this.getData(request, context));
    return this.formatData(data);
  }
}

class TrafficBreakdown extends BreakdownGraphBuilder<SiteTrafficBreakdown & Rps> {
  protected formatData(data: (SiteTrafficBreakdown & Rps)[]): Highcharts.Options {
    return this.bars.formatDataTrafic(data, 'total');
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<(SiteTrafficBreakdown & Rps)[]> {
    return this.logs.getTrafficBreakdown('traffic/top-sites/by-total', request, context);
  }
}

class TrafficByBlockedBreakdown extends BreakdownGraphBuilder<SiteTrafficBreakdown & Rps> {
  protected formatData(data: (SiteTrafficBreakdown & Rps)[]): Highcharts.Options {
    return this.bars.formatDataTrafic(data, 'blocked');
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<(SiteTrafficBreakdown & Rps)[]> {
    return this.logs.getTrafficBreakdown('traffic/top-sites/by-blocked', request, context);
  }
}

class ResponseCodeBreakdown extends BreakdownGraphBuilder<UrlResponseCodesCount> {
  protected formatData(data: UrlResponseCodesCount[]): Highcharts.Options {
    return this.bars.formatDataResponsesCodes(data);
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<UrlResponseCodesCount[]> {
    return this.logs
      .getUrlBreakdown<UrlResponseCodesCount>('top-urls-by-http-error', request, context)
      .pipe(map((items) => _.orderBy(items, [(item) => item.count4xx + item.count5xx], ['desc'])));
  }
}

class ResponseTimeBreakdown extends BreakdownGraphBuilder<UrlStats> {
  protected formatData(data: UrlStats[]): Highcharts.Options {
    return this.bars.formatDataResponseTime(data);
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<UrlStats[]> {
    return this.logs.getUrlBreakdown('slow-urls', request, context);
  }
}

class ResponseTimeSumBreakdown extends BreakdownGraphBuilder<UrlStats> {
  protected formatData(data: UrlStats[]): Highcharts.Options {
    return this.bars.formatDataResponseTimeSum(data);
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<UrlStats[]> {
    return this.logs.getUrlBreakdown('cpu-consuming-urls', request, context);
  }
}

class BandwidthBreakdown extends BreakdownGraphBuilder<UrlBandwidth> {
  protected formatData(data: UrlBandwidth[]): Highcharts.Options {
    return this.bars.formatDataBandwidth(data);
  }

  protected getData(request: LogRequest, context: ContextEnum): Observable<UrlBandwidth[]> {
    return this.logs.getUrlBreakdown('top-urls-by-bandwidth', request, context);
  }
}

type GraphOptionsBuilder = (logs: LogsService, bars: Bar) => BreakdownGraphBuilder<any>;
const graphBuilders: { [key in RepartitionType]: GraphOptionsBuilder } = {
  [TRAFIC]: (logs: LogsService, bars: Bar) => new TrafficBreakdown(logs, bars),
  [TRAFIC_SORT_BY_BLOCKED]: (logs: LogsService, bars: Bar) => new TrafficByBlockedBreakdown(logs, bars),
  [RESPONSE_TIME]: (logs: LogsService, bars: Bar) => new ResponseTimeBreakdown(logs, bars),
  [RESPONSE_TIME_SUM]: (logs: LogsService, bars: Bar) => new ResponseTimeSumBreakdown(logs, bars),
  [RESPONSES_CODES]: (logs: LogsService, bars: Bar) => new ResponseCodeBreakdown(logs, bars),
  [BANDWIDTH]: (logs: LogsService, bars: Bar) => new BandwidthBreakdown(logs, bars),
};
