<div id="default-fixed-menu" style="flex-wrap: wrap; padding: 0 10px 10px 10px" class="bg-white rounded shadow">
  <div class="flex-align-items" style="margin: 10px">
    <app-site-selector
      class="flex-align-items"
      style="gap: 10px; flex-wrap: wrap"
      [ctx]="ctx"
      (change)="onSiteSelectionChanged()"
      [showTags]="ctx != ADMIN_CONTEXT"
    >
    </app-site-selector>
    <label style="margin: 0 10px">{{ 'IPs' | translate }}</label>
    <ng-select
      class="selector custom-scrollbar no-arrow"
      [addTag]="addTag"
      [isOpen]="false"
      [(ngModel)]="filters.ips"
      [searchable]="true"
      [multiple]="true"
      [clearable]="true"
      [placeholder]="'AllF' | translate"
      (change)="refresh()"
    ></ng-select>
    <label class="badge badge-sm bg-dark" placement="left" style="margin-left: 10px" [ngbTooltip]="tooltipIPs">?</label>
    <ng-template #tooltipIPs>
      <div innerHTML="{{ 'TooltipIPsMyLogs' | translate }}"></div>
    </ng-template>
  </div>
  <div class="flex-align-items" style="margin: 10px">
    <label style="margin: 0 10px">{{ 'StatusCodes' | translate }}</label>
    <ng-select
      class="selector custom-scrollbar no-arrow status-code"
      [(ngModel)]="filters.statusCodes"
      [isOpen]="false"
      [searchable]="true"
      autocomplete="on"
      bindLabel=""
      [addTag]="addTag"
      [multiple]="true"
      [clearable]="true"
      [placeholder]="'All' | translate"
      (change)="refresh()"
    ></ng-select>
    <label class="badge badge-sm bg-dark" placement="left" style="margin-left: 10px" [ngbTooltip]="tooltipStatusCodes"
      >?</label
    >
    <ng-template #tooltipStatusCodes>
      <div innerHTML="{{ 'StatusCodesTooltip' | translate }}"></div>
    </ng-template>
  </div>
  <div class="flex-align-items search-input" style="margin: 10px">
    <label>{{ 'Path' | translate }}</label>
    <input
      [(ngModel)]="filters.path"
      class="form-control"
      (keydown.enter)="refresh()"
      placeholder="{{ 'All' | translate }}"
    />
  </div>
  <div class="flex-align-items search-input" style="margin: 10px">
    <label style="min-width: 160px">{{ 'ResponseTimeGte' | translate }}</label>
    <input
      type="number"
      [(ngModel)]="filters.responseTimeGte"
      (keydown.enter)="refresh()"
      class="form-control [&::-webkit-inner-spin-button]:appearance-none"
      style="max-width: 80px"
    />
    <div>&nbsp;ms</div>
  </div>
  <div class="flex-align-items search-input" style="margin: 10px">
    <label>{{ 'ContentLengthGte' | translate }}</label>
    <input
      type="number"
      [(ngModel)]="filters.contentLengthGte"
      (keydown.enter)="refresh()"
      class="form-control [&::-webkit-inner-spin-button]:appearance-none"
      style="max-width: 80px"
    />
    <div>&nbsp;{{ 'bytes' | translate }}</div>
  </div>
  <div class="flex-align-items search-input" style="margin: 10px">
    <label>{{ 'CacheStatus.FilterLabel' | translate }}</label>
    <select
      [(ngModel)]="filters.cacheStatus"
      (change)="refresh()"
      style="padding: 5px 10px; margin: 0 10px; width: 200px"
    >
      <option value="" selected>{{ 'All' | translate }}</option>
      <option value="HIT">{{ 'CacheStatus.HIT' | translate }}</option>
      <option value="MISS">{{ 'CacheStatus.MISS' | translate }}</option>
      <option value="NONE">{{ 'CacheStatus.NONE' | translate }}</option>
    </select>
  </div>

  <div class="flex-align-items search-input" style="margin: 10px">
    <label>{{ 'Pays' | translate }}</label>
    <select [(ngModel)]="filters.country" (change)="refresh()" style="padding: 5px 10px; margin: 0 10px; width: 200px">
      <option value="" selected>{{ 'All' | translate }}</option>
      <option *ngFor="let p of countries" [value]="p.code">{{ p.code | uppercase }} {{ p.name }}</option>
    </select>
  </div>
  <div class="flex-align-items search-input" style="margin: 10px">
    <label>{{ 'Action' | translate }}</label>
    <select
      [(ngModel)]="filters.action"
      (change)="onActionChanged()"
      style="padding: 5px 10px; margin: 0 10px; width: 200px"
    >
      <option value="" selected>{{ 'AllF' | translate }}</option>
      <option value="authorized">{{ 'Autorisees' | translate }}</option>
      <option value="suspicious">{{ 'Suspectes' | translate }}</option>
      <option value="blocked">{{ 'Bloquees' | translate }}</option>
    </select>
  </div>
  <div class="flex-align-items search-input" style="margin: 10px">
    <label>{{ 'Cause' | translate }}</label>
    <select
      [(ngModel)]="filters.cause"
      [title]="(!filters.action ? 'NoActionSelected' : '') | translate"
      [disabled]="!filters.action || filters.action == 'suspicious'"
      (change)="refresh()"
      style="padding: 5px 10px; margin: 0 10px; width: 200px"
    >
      <option value="" selected>{{ 'AllF' | translate }}</option>
      <option *ngIf="filters.action == 'authorized'" value="analyzedOK">{{ 'AuthorizedByAI' | translate }}</option>
      <option *ngIf="filters.action == 'authorized'" value="passthroughRule">
        {{ 'PassthroughByRule' | translate }}
      </option>
      <option *ngIf="filters.action == 'authorized'" value="whitelistedIp">{{ 'WhitelistedIp' | translate }}</option>
      <option *ngIf="filters.action == 'authorized'" value="urlException">{{ 'urlException' | translate }}</option>

      <option *ngIf="filters.action == 'blocked'" value="analyzedKO">{{ 'BlockedByAI' | translate }}</option>
      <option *ngIf="filters.action == 'blocked'" value="geoBlocked">{{ 'Geoblocked' | translate }}</option>
      <option *ngIf="filters.action == 'blocked'" value="blockedByRule">{{ 'BlockedByRule' | translate }}</option>
    </select>
  </div>
  <div class="flex-align-items search-input" style="margin: 10px">
    <label>{{ 'AccessControlRule' | translate }}</label>
    <select
      [(ngModel)]="filters.rule"
      [title]="(sites[ctx].current.selectedSites.length != 1 ? 'NoHostSelected' : '') | translate"
      [disabled]="sites[ctx].current.selectedSites.length != 1"
      (change)="refresh()"
      style="padding: 5px 10px; margin: 0 10px; width: 75px"
    >
      <option value="" selected>-</option>
      <option *ngFor="let r of rules" [value]="r.id">#{{ r.priority }}</option>
    </select>
    <label
      class="badge badge-sm bg-dark"
      style="position: unset"
      placement="left"
      ngbTooltip="{{ 'RuleTooltip' | translate }}"
      >?</label
    >
  </div>
  <button
    app-date-range-selector
    [disabled]="isLoading"
    [start]="sites[ctx].current.period.start"
    [end]="sites[ctx].current.period.end"
    [minDateDays]="90"
    [ranges]="['15M', '1H', '4H', 'T', 'Y', '7', '30', '90']"
    [ctx]="ctx"
    (period)="onPeriodEmitted($event)"
    [timePicker]="true"
    class="btn btn-primary"
    id="reportrange-tracking-{{ ctx === ADMIN_CONTEXT && '-admin' }}"
    style="margin: 0 10px 10px 10px"
  >
    <i class="icofont icofont-ui-calendar"></i>&nbsp; <span></span>&nbsp;
    <div class="arrow-down"></div>
  </button>
  <button
    class="btn btn-primary btn-download"
    title="{{ 'Download' | translate }}"
    (click)="downloadLogs()"
    [disabled]="isLoading"
  >
    <i class="icofont icofont-download"></i>
  </button>
  <a id="downloadAnchorElem" style="display: none"></a>
  <button
    class="btn btn-primary btn-reload"
    title="{{ 'Refresh' | translate }}"
    (click)="refresh()"
    [disabled]="isLoading"
  >
    <i class="icon-reload"></i>
  </button>
  <div class="flex-align-items space-between" style="margin: 0 10px 10px 10px" [hidden]="!sites.showAutoRefresh(ctx)">
    <div>
      <label style="padding: 0 10px 0 0">{{ 'RefreshEveryMinute' | translate }}</label>
      <div class="loading-bar activity" [ngStyle]="{ 'width.%': ((countNextRefresh % 60) / 60) * 100 }"></div>
    </div>
    <div class="switch-container ml-1">
      <label class="switch">
        <input
          type="checkbox"
          [(ngModel)]="sites[ctx].current.period.autoRefresh"
          (change)="sites[ctx].current.period.autoRefresh ? setRefreshInterval() : clearRefreshInterval()"
        />
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</div>

<app-my-logs-graph
  #graphsComponent
  (refresh)="refresh()"
  (getLogs)="loadData()"
  (onPeriodEmitted)="onPeriodEmittedFromGraph($event)"
  (trackContentLengthGte)="trackContentLengthGte($event)"
  (trackAction)="trackAction($event)"
  (trackStatusCode)="trackStatusCode($event)"
  (trackResponseTimeGte)="trackResponseTimeGte($event)"
  [filters]="filters"
  [ctx]="ctx"
/>

<div class="logs-container card">
  <ngx-datatable
    *ngIf="!isLoading; else loader"
    [messages]="{ emptyMessage: translate.instant('NoData') }"
    #logsTable
    class="material data-table table-responsive"
    [rows]="logs"
    [limit]="size"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="40"
    [scrollbarH]="logsTableMustScroll"
    [columnMode]="'force'"
  >
    <ngx-datatable-row-detail [rowHeight]="'auto'">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
        <div style="padding: 20px">
          <json-viewer
            [json]="row.requestDetails"
            [expanded]="true"
            [highlightedFields]="row?.drivesList?.highlight"
          ></json-viewer>
          <div *ngIf="row?.drivesList?.drives?.length">
            <hr />
            <div class="bg-drives" style="text-align: left">
              <h6>{{ (row?.drivesList?.drives.length > 1 ? 'AnomaliesDetected' : 'AnomalyDetected') | translate }}</h6>
              <div style="line-height: 2">
                <div *ngFor="let d of row.drivesList.drives">
                  {{ d.category != d.label ? d.category + ' - ' : '' }}{{ d.label }}
                  <label
                    *ngIf="d.description"
                    class="badge badge-sm bg-dark"
                    placement="bottom"
                    container="body"
                    [ngbTooltip]="d.description"
                    >?</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>

    <ngx-datatable-column [minWidth]="80" [maxWidth]="80">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
        <span (click)="toggleExpandRow(row)" class="ogo-link" style="font-size: 12px">
          <a
            href="javascript:void(0)"
            class="ogo-link icon"
            [class.icon-angle-down]="expanded"
            [class.icon-angle-right]="!expanded"
            style="font-size: 11px; font-weight: bold"
            title="{{ 'Details' | translate }}"
          >
          </a>
          {{ 'Details' | translate }}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [minWidth]="200" [maxWidth]="200" [resizeable]="false" cellClass="date">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Date' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span title="{{ row.date | dateFormat }}">{{ row.date | dateFormat }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="2">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Site' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span title="{{ row.site }}">
          <small *ngIf="row.dryRun" class="label pull-left mr-2" style="background-color: #333">{{
            'ModeAudit' | translate
          }}</small>
          <a (click)="!isLoading && trackSite(row.site)" class="clickable">{{ row.site }}</a></span
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="3">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Path' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <small
          *ngIf="row.rewriteOriginalPath"
          class="label pull-left mr-2"
          title=""
          placement="bottom"
          ngbTooltip="{{ 'RemplacedURL' | translate }}"
          style="background-color: #333; cursor: default"
          >R</small
        >
        <span class="clickable" (click)="!isLoading && trackPath(row.path)" title="{{ row.path }}">{{ row.path }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [minWidth]="235" cellClass="ip-address">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'IP' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="flex-align-items">
          <div class="flex-ip-flag" title="{{ row.clientIP + ' (' + row.countryCode + ')' }}" style="width: 68%">
            <div
              *ngIf="row.countryCode"
              [attr.title]="row.countryName"
              class="pointer fi fi-{{ row.countryCode.toLowerCase() }}"
              (click)="trackCountry(row.countryCode)"
            ></div>
            &nbsp;
            <div class="text-ellipsis ip-address" style="position: relative; top: -1px">{{ row.clientIP }}</div>
          </div>
          <div style="margin-left: auto; min-width: 43px">
            <i
              (click)="!isLoading && trackIp(row.clientIP, 'in')"
              style="color: #4099ff; font-size: 0.8rem"
              class="ml-2 pointer ogo-link icofont icofont-ui-zoom-in right"
            ></i>
            <i
              (click)="!isLoading && trackIp(row.clientIP, 'out')"
              style="color: #4099ff; font-size: 0.8rem"
              class="ml-2 pointer ogo-link icofont icofont-ui-zoom-out right"
            ></i>
          </div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="150"
      [maxWidth]="150"
      [resizeable]="false"
      *ngIf="![RESPONSE_TIME, BANDWIDTH].includes(graphType)"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader">
          {{ 'Credibility' | translate }}
          <label
            class="badge badge-sm bg-dark"
            container="body"
            placement="bottom"
            ngbTooltip="{{ 'CredibilityTooltip' | translate }}"
            >?</label
          >
        </p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div
          #progressBar
          class="progress-bar-container progress-bar-gradient"
          [title]="
            row.rt &&
            row.credibility != -1 &&
            row.cause != 'whitelistedIp' &&
            row.cause != 'urlException' &&
            row.action != 'redirect'
              ? Math.trunc(row.credibility / 1000) + '%'
              : ''
          "
        >
          <div
            [ngStyle]="{ width: 100 - (row.credibility || 100) / 1000 + '%' }"
            style="min-width: 0.01em"
            class="progress-bar-cache"
          >
            <div
              *ngIf="row.credibility == -1 && row.cause != 'geoBlocked' && row.cause != 'whitelistedIp'"
              class="progress-badge ban"
            >
              {{ 'Banned' | translate }}
            </div>
            <div *ngIf="row.credibility == -1 && row.cause == 'geoBlocked'" class="progress-badge ban">
              {{ 'geoBlocked' | translate }}
            </div>
            <div *ngIf="row.credibility == -1 && row.cause == 'whitelistedIp'" class="progress-badge exception">
              {{ 'whitelistedIp' | translate }}
            </div>
            <div *ngIf="row.cause == 'urlException'" class="progress-badge exception">
              {{ 'urlException' | translate }}
            </div>
            <div *ngIf="row.cause == 'passthroughRule'" class="progress-badge exception">
              {{ 'passthroughRule' | translate }}
            </div>
            <div *ngIf="row.action == 'redirect'" class="progress-badge exception">{{ 'redirect' | translate }}</div>
            <div *ngIf="row.cause == 'blockedByRule'" class="progress-badge exception">
              {{ 'blockedByRule' | translate }}
            </div>
          </div>
          <div
            [ngStyle]="{ top: '-10px', background: row.credibility / 1000 < row.rt ? '#ff5370' : '' }"
            class="progress-bar-progressbar progress-{{ row.credibility / 1000 }}"
          ></div>
          <div
            *ngIf="
              row.rt &&
              row.credibility != -1 &&
              row.cause != 'whitelistedIp' &&
              row.cause != 'urlException' &&
              row.action != 'redirect'
            "
            class="progress-bar-threshold"
            [ngStyle]="{ width: (progressBar.offsetWidth / 100) * row.rt + 'px' }"
          ></div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="150"
      [maxWidth]="150"
      [resizeable]="false"
      *ngIf="![RESPONSE_TIME, BANDWIDTH].includes(graphsComponent.graphType)"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Action' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span
          title="{{ row.action | translate }}"
          (click)="row.action && row.action != 'redirect' && trackAction(row.action)"
          [ngStyle]="{
            textDecoration: row.action && row.action != 'redirect' ? 'underline' : 'none',
            cursor: row.action && row.action != 'redirect' ? 'pointer' : 'default'
          }"
          class="action-{{ row.action }}"
        >
          {{ row.action | translate }}
          {{ row.dryRun && row.action.includes('block') ? '(N/A)' : '' }}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="150"
      [maxWidth]="150"
      [resizeable]="false"
      *ngIf="![RESPONSE_TIME, BANDWIDTH].includes(graphsComponent.graphType)"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'MainCause' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span
          title="{{ row.cause | translate }}"
          (click)="row.cause && row.causeFilterable && trackCause(row.action, row.cause)"
          [ngStyle]="{
            textDecoration: row.cause && row.causeFilterable ? 'underline' : 'none',
            cursor: row.cause && row.causeFilterable ? 'pointer' : 'default'
          }"
        >
          {{ row.mainAttackLabel || (row.cause | translate) }}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="100"
      [maxWidth]="100"
      [resizeable]="false"
      *ngIf="[RESPONSE_TIME, BANDWIDTH].includes(graphsComponent.graphType)"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Method' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span title="{{ row.method }}">{{ row.method }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [minWidth]="70" [maxWidth]="70" [resizeable]="false" cellClass="status-code">
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'StatusCode' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span class="clickable" (click)="trackStatusCode(row.code)" title="{{ row.code }}">{{ row.code }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="180"
      [maxWidth]="180"
      [resizeable]="false"
      *ngIf="graphsComponent.graphType == RESPONSE_TIME"
      cellClass="number"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader">{{ 'ResponseTime' | translate }}</p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span title="{{ row.responseTimeMs }}">{{ row.responseTimeMs | duration }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="80"
      [maxWidth]="80"
      [resizeable]="false"
      *ngIf="![RESPONSE_TIME, BANDWIDTH].includes(graphsComponent.graphType)"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'Rule' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div
          (click)="hasClickableRule(row) && trackRule(row.site, row.rulePriority)"
          [ngStyle]="{
            textDecoration: hasClickableRule(row) ? 'underline' : 'none',
            cursor: hasClickableRule(row) ? 'pointer' : 'default'
          }"
          placement="left"
          container="body"
          ngbTooltip="{{ getRuleDetails(row.rulePriority) }}"
        >
          {{ row.rulePriority ? '#' + row.rulePriority : '-' }}
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [flexGrow]="1"
      [resizeable]="false"
      *ngIf="graphsComponent.graphType == BANDWIDTH"
      cellClass="number"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'ContentLengthHeader' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span title="{{ row.contentLength }}">{{ row.contentLength | numberFormat }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [minWidth]="100"
      [maxWidth]="100"
      [resizeable]="false"
      *ngIf="graphsComponent.graphType == BANDWIDTH"
    >
      <ng-template let-column="column" ngx-datatable-header-template>
        <p class="dataheader" [innerHtml]="'CacheStatus.TableHeader' | translate"></p>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span title="{{ row.requestDetails?.responseHeaders?.['x-ogo-cache'] }}">{{
          row.requestDetails?.responseHeaders?.['x-ogo-cache']
        }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-curPage="filters.index" let-pageSize="size">
        <small class="ogo-ngx-datatable-footer"
          >Total : {{ total | number: '' : (lang == 'fr' ? 'fr-FR' : 'en-US') }}
          {{ total > 1 ? ('reqs' | translate) : ('req' | translate) }}
        </small>
        <div class="flex-align-items ml-5">
          <small class="ogo-ngx-datatable-footer">{{ 'TotalPerPage' | translate }} :&nbsp;</small>
          <select
            class="form-control form-control-sm"
            [(ngModel)]="size"
            (change)="loadLogsPage()"
            style="min-width: 60px"
          >
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <datatable-pager
          [pagerLeftArrowIcon]="'datatable-icon-left'"
          [pagerRightArrowIcon]="'datatable-icon-right'"
          [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'"
          [page]="index + 1"
          [size]="size"
          [count]="total > 10000 ? 10000 : total"
          (change)="changePage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>

  <ng-template #loader>
    <div class="component-loader">
      <div class="loader-track">
        <div class="loader-bar"></div>
      </div>
    </div>
  </ng-template>
</div>
