<div class="card p-3" style="overflow-x: auto">
  <div class="row">
    <div class="col-12">
      <h5 style="display: flex; align-items: center; justify-content: space-between">
        <div>
          {{ 'RulesManagement' | translate }}
          <label class="badge badge-sm bg-dark" placement="bottom" ngbTooltip="{{ 'RulesManagementTooltip' | translate }}">?</label>
        </div>
      </h5>
      <p style="margin-top: 10px">{{ 'AccessControlDescription' | translate }}</p>
      <hr style="margin-bottom: 0.8rem" />
    </div>
    <div class="col-md-12" style="margin-top: 10px; min-width: 1120px">
      <div *ngIf="!hostModeService.isAdvanced(hote.mode)" class="bg-blue" style="margin-bottom: 10px; margin-top: -10px">{{ 'SubscribeToAdvanced' | translate }}</div>
      <div cdkDropList class="drag-list" (cdkDropListDropped)="drop($event)">
        <div class="add-site-table-header" id="rules-header">
          <span></span>
          <span class="flex-align-items"
            >{{ 'Active' | translate }}&nbsp;
            <label class="badge badge-sm bg-dark" placement="right" ngbTooltip="{{ 'RuleActiveTooltip' | translate }}">?</label>
          </span>
          <span class="flex-align-items"
            >{{ 'Priority' | translate }}&nbsp;
            <label class="badge badge-sm bg-dark" placement="right" ngbTooltip="{{ 'RulePriorityTooltip' | translate }}">?</label>
          </span>
          <span class="flex-align-items"
            >URLs&nbsp;
            <a class="ogo-link" target="_blank" [href]="lang == 'fr' ? EXTERNAL_HELP_FRENCH_LINK + '/expression-reguliere-d-ogo' : EXTERNAL_HELP_ENGLISH_LINK + '/ogo-s-regular-expression'"
              >({{ 'RegexpGuide' | translate }}<i class="icofont icofont-external-link ml-1"></i>)</a
            >
            <ng-template #urlExpTempExp>
              <div innerHTML="{{ 'UriExceptionExemple' | translate }}"></div>
            </ng-template>
          </span>
          <span class="flex-align-items"
            >{{ 'AuthorizedIps' | translate }}&nbsp;
            <label class="badge badge-sm bg-dark" placement="right" ngbTooltip="{{ 'RuleAuthorizedIpsTooltip' | translate }}">?</label>
          </span>
          <span class="flex-align-items"
            >{{ 'AssociatedAction' | translate }}&nbsp;
            <label class="badge badge-sm bg-dark" placement="right" ngbTooltip="{{ 'RuleAssociatedActionTooltip' | translate }}">?</label>
          </span>
          <span class="flex-align-items" *ngIf="hote.clusterSupportsCache" style="width: 160px" [ngClass]="{ 'disabled-column': !hote.cacheEnabled }"
            >{{ 'Cache' | translate }}&nbsp;
            <div class="text-no-wrap">
              <label class="badge badge-sm bg-dark">{{ 'BETA' | translate }}</label>
              <label class="badge badge-sm bg-dark ml-1" placement="top" [ngbTooltip]="ruleCacheTooltip">?</label>
            </div>
          </span>
          <ng-template #ruleCacheTooltip>
            <div innerHTML="{{ 'RuleCacheTooltip' | translate }}"></div>
          </ng-template>
          <span style="width: 20%">{{ 'Comments' | translate }}</span>
          <span style="width: 110px">Actions</span>
        </div>
        <div *ngFor="let rule of rules" class="drag-container">
          <div class="drag-box" cdkDrag [cdkDragDisabled]="isEditingRule() || rulesInProgress" [ngClass]="{ 'no-drag': isEditingRule() || rulesInProgress }">
            <span>
              <div *ngIf="!isEditingRule() || rulesInProgress" class="grip-container" style="top: 23px !important">
                <div class="grip-column">
                  <div class="grip"></div>
                  <div class="grip"></div>
                  <div class="grip"></div>
                </div>
                <div class="grip-column">
                  <div class="grip"></div>
                  <div class="grip"></div>
                  <div class="grip"></div>
                </div>
              </div>
            </span>
            <span>
              <div class="switch-container" style="position: relative; left: 7px; top: 4px">
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="rule.isActive" [ngStyle]="{ cursor: rule.editing ? 'pointer' : 'default' }" [disabled]="!rule.editing" />
                  <span class="slider round" [ngStyle]="{ opacity: rule.editing ? '1' : '0.5', cursor: rule.editing ? 'pointer' : 'default' }"></span>
                </label>
              </div>
            </span>
            <span title="{{ (isEditingRule() ? 'PriorityTooltipInEdit' : 'PriorityTooltipOutOfEdit') | translate }}">
              <span *ngIf="rule.priority">#{{ rule.priority }}</span>
            </span>
            <span>
              <span *ngIf="!rule.editing" title="{{ rule.uris.join(', ') }}">{{ rule.uris.join(', ') }}</span>
              <div *ngIf="rule.editing" style="margin-top: 15px">
                <div class="group">
                  <input
                    class="md"
                    type="text"
                    #accessRuleUriElement
                    (focus)="uriFocused = true; onFocusUri($event.target)"
                    (blur)="uriFocused = false; $event.target.value != '/' && validateUri($event.target.value) && addToRule(rule, 'uris', $event); onBlurUri($event.target)"
                    (keyup.enter)="validateUri($event.target.value) && addToRule(rule, 'uris', $event)"
                    style="font-size: 14px"
                    required
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="mat-label">{{ 'EnterURIPrefix' | translate }}</label>
                  ZZ
                </div>
                <i *ngIf="uriFocused" style="font-size: 11px">{{ 'PressEnterToAdd' | translate }}</i>
                <table class="table table-hover table-fixed">
                  <tbody>
                    <tr *ngFor="let uri of rule.uris">
                      <td class="text-ellipsis" title="{{ uri }}">{{ uri }}</td>
                      <td style="width: 20px">
                        <i
                          (click)="deleteFromRule(rule, 'uris', uri)"
                          style="color: #e74c3c; font-size: 20px; position: relative; left: -15px; cursor: pointer"
                          class="pointer icofont icofont-ui-delete"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </span>
            <span>
              <span *ngIf="!rule.editing" title="{{ rule.whitelistedIps.join(', ') }}">{{ rule.whitelistedIps.join(', ') }}</span>
              <div *ngIf="rule.editing" style="margin-top: 15px">
                <div class="group">
                  <input
                    class="md"
                    type="text"
                    (focus)="whitelistFocused = true"
                    (blur)="whitelistFocused = false; validateIp($event.target.value) && addToRule(rule, 'whitelistedIps', $event)"
                    (keyup.enter)="validateIp($event.target.value) && addToRule(rule, 'whitelistedIps', $event)"
                    style="font-size: 14px"
                    required
                  />
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label class="mat-label">{{ 'EnterWhitelistedIp' | translate }}</label>
                </div>
                <i *ngIf="whitelistFocused" style="font-size: 11px">{{ 'PressEnterToAdd' | translate }}</i>
                <table class="table table-hover table-fixed">
                  <tbody>
                    <tr *ngFor="let whitelistedIp of rule.whitelistedIps; let i = index">
                      <td class="text-ellipsis" title="{{ whitelistedIp }}">{{ whitelistedIp }}</td>
                      <td style="width: 20px">
                        <i
                          (click)="deleteFromRule(rule, 'whitelistedIps', whitelistedIp)"
                          title="{{ 'supprimer' | translate }}"
                          style="color: #e74c3c; font-size: 20px; position: relative; left: -15px; cursor: pointer"
                          class="pointer icofont icofont-ui-delete"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </span>
            <span>
              <span *ngIf="!rule.editing">{{ rule.action == 'brain' ? ('Analyzed' | translate) : ('Panic' | translate) }}</span>
              <div *ngIf="rule.editing" style="position: relative; top: 26px">
                <select [(ngModel)]="rule.action" class="form-control">
                  <option value="bypass">{{ 'Panic' | translate }}</option>
                  <option value="brain">{{ 'Analyzed' | translate }}</option>
                </select>
              </div>
            </span>
            <span *ngIf="hote.clusterSupportsCache" style="width: 160px">
              <div class="form-group">
                <input
                  type="checkbox"
                  [(ngModel)]="rule.cache"
                  [disabled]="!rule.editing || !hote.cacheEnabled"
                  id="checkbox-cache"
                  [ngStyle]="{
                    backgroundColor: hote.cacheEnabled ? '#fff' : '#cfcfcf',
                    cursor: hote.cacheEnabled ? (rule.editing ? 'pointer' : 'default') : 'not-allowed',
                    position: 'relative',
                    top: rule.editing ? '25px' : 'unset'
                  }"
                />
              </div>
            </span>
            <span style="width: 20%">
              <span [title]="rule.comments" *ngIf="!rule.editing">{{ rule.comments }}</span>
              <textarea
                *ngIf="rule.editing"
                placeholder="{{ 'CommentsOptionnal' | translate }}"
                [(ngModel)]="rule.comments"
                style="height: 100%; width: 100%; position: relative; top: -5px; border: 1px solid #c3c3c3"
                maxlength="255"
              ></textarea>
            </span>
            <span style="width: 110px">
              <div class="flex-align-items rules-actions">
                <button
                  *ngIf="!rule.editing"
                  (click)="!isEditingRule() && !rulesInProgress && startEditing(rule)"
                  title="{{ 'LastUpdateAt' | translate }} : {{ rule.updatedAt | date: (lang === 'fr' ? 'dd MMM yyyy, HH:mm' : 'dd MMM yyyy, hh:mm a') }}"
                  [ngStyle]="{ outline: '', color: isEditingRule() || rulesInProgress ? '#dbd9da' : '#4099ff', cursor: isEditingRule() || rulesInProgress ? 'default' : 'pointer' }"
                  style="font-size: 17px"
                  class="transparent-btn pointer icofont icofont-edit-alt mr-2"
                ></button>
                <button
                  *ngIf="rule.editing"
                  (click)="validateEditing(rule)"
                  title="{{ 'Validate' | translate }}"
                  [ngStyle]="{
                    color: rule.uris.length == 0 || rule.whitelistedIps.length == 0 ? '#dbd9da' : '#4099ff',
                    cursor: rule.uris.length == 0 || rule.whitelistedIps.length == 0 ? 'default' : 'pointer'
                  }"
                  style="font-size: 25px"
                  class="transparent-btn pointer icofont icofont-check"
                ></button>
                <button
                  *ngIf="!rule.editing"
                  (click)="(!isEditingRule() || rule.editing) && deleteRule(rule)"
                  title="{{ 'supprimer' | translate }}"
                  [ngStyle]="{
                    color: isEditingRule() || rulesInProgress ? '#dbd9da' : '#e74c3c',
                    cursor: isEditingRule() || rulesInProgress ? 'default' : 'pointer'
                  }"
                  style="font-size: 17px"
                  class="transparent-btn pointer icofont icofont-ui-delete mr-1"
                ></button>
                <button
                  *ngIf="rule.new"
                  (click)="(!isEditingRule() || rule.editing) && deleteRule(rule)"
                  title="{{ 'Annuler' | translate }}"
                  [ngStyle]="{ color: isEditingRule() && !rule.editing ? '#dbd9da' : '#e74c3c', cursor: isEditingRule() && !rule.editing ? 'default' : 'pointer' }"
                  style="font-size: 23px"
                  class="transparent-btn pointer icofont icofont-close mr-1"
                ></button>
                <button
                  *ngIf="rule.editing && !rule.new"
                  (click)="cancelEditing(rule)"
                  title="{{ 'CancelChanges' | translate }}"
                  style="color: #e74c3c; font-size: 23px"
                  class="transparent-btn pointer icofont icofont-close mr-1"
                ></button>
              </div>
            </span>
          </div>
        </div>
        <div *ngIf="!rules.length" style="text-align: center; padding: 15px">{{ 'NoRules' | translate }}</div>
      </div>
      <br />
      <div style="margin-bottom: 10px">
        <a *ngIf="!isEditingRule() && !isCreatingRule()" class="ogo-link" (click)="addRule()">+ {{ 'AddRule' | translate }}</a>
      </div>
    </div>
  </div>
</div>
