<div [hidden]="addSiteView" class="row">
  <div class="col-md-12">
    <app-card>
      <div class="row">
        <div class="col-md-12 col-xl-12 md-tabs">
          <div class="mb-1">
            <div class="row flex-align-items">
              <div
                [hidden]="
                  adminClusterView || !auth.userHasFeature(ACL_SITE_EDIT) || !auth.currentOrganization.clusters.length
                "
                style="margin: 0 15px"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-primary waves-effect"
                  (click)="editHote()"
                  style="margin: 10px 0"
                >
                  <i class="icofont icofont-plus"></i>
                  <span [innerHTML]="'AjouterUnSite' | translate"></span>
                  <br />
                </button>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Cluster</label>
                  <select
                    [(ngModel)]="cluster.clusterId"
                    (ngModelChange)="cluster.tag = undefined; resetPagination()"
                    class="form-control form-control-sm"
                  >
                    <option *ngIf="!adminClusterView" [value]="undefined">{{ 'All' | translate }}</option>
                    <option *ngFor="let item of clusters" value="{{ item.id }}">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>{{ 'Statut' | translate }}</label>
                  <select
                    [(ngModel)]="cluster.statutHoteId"
                    (ngModelChange)="resetPagination()"
                    class="form-control form-control-sm"
                  >
                    <option [value]="undefined">
                      {{ 'All' | translate }}
                    </option>
                    <option *ngFor="let item of statutHotes" value="{{ item.id }}">
                      {{ item.libelle }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2" *ngIf="!adminClusterView">
                <div class="form-group">
                  <label>Tag</label>
                  <select
                    [(ngModel)]="cluster.tag"
                    (ngModelChange)="resetPagination()"
                    class="form-control form-control-sm"
                  >
                    <option [value]="undefined">
                      {{ 'All' | translate }}
                    </option>
                    <option *ngFor="let tag of tags" value="{{ tag }}">
                      {{ tag }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ 'Search' | translate }}</label>
                  <input
                    #searchInput
                    type="text"
                    placeholder="Email, Site, Destination"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-condensed" style="border: 1px solid #ccc">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    {{ 'Statut' | translate }}
                    <label
                      class="badge badge-sm bg-dark"
                      placement="top"
                      container="body"
                      ngbTooltip="{{ 'StatusIs' | translate }}"
                      >?</label
                    >
                  </th>
                  <th>
                    {{ 'Site' | translate }}
                  </th>
                  <th [hidden]="adminClusterView">
                    {{ 'Cluster' | translate }}
                    <label
                      class="badge badge-sm bg-dark"
                      placement="top"
                      container="body"
                      ngbTooltip="{{ 'ClusterIs' | translate }}"
                      >?</label
                    >
                  </th>
                  <th>
                    {{ 'DestinationIp' | translate }}
                    <label
                      class="badge badge-sm bg-dark"
                      placement="top"
                      container="body"
                      ngbTooltip="{{ 'DestinIs' | translate }}"
                      >?</label
                    >
                  </th>
                  <th *ngIf="adminClusterView">
                    {{ 'Owner' | translate }}
                  </th>
                  <th>
                    {{ 'Creation' | translate }}
                  </th>
                  <th *ngIf="adminClusterView">
                    {{ 'Mode' | translate }}
                    <label class="badge badge-sm bg-dark" placement="bottom" container="body" [ngbTooltip]="modeTooltip"
                      >?</label
                    >
                    <ng-template #modeTooltip>
                      <div innerHTML="{{ 'ModeTooltip' | translate }}"></div>
                    </ng-template>
                  </th>
                  <th>
                    {{ 'Actions' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="!hosts.length">
                  <td [attr.colspan]="adminClusterView ? 8 : 7" style="text-align: center">
                    {{ 'NoData' | translate }}
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let row of hosts
                      | paginate
                        : {
                            itemsPerPage: pageSize,
                            currentPage: index + 1,
                            totalItems: total
                          }
                  "
                >
                  <td style="width: 1%">
                    <label class="ogo-checkbox-container" *ngIf="adminClusterView || auth.canDoMoreThanView(row)">
                      <input type="checkbox" [(ngModel)]="row.isSelected" (click)="toggleSelected($event, row)" />
                      <span class="ogo-checkbox-checkmark"></span>
                    </label>
                  </td>
                  <td>
                    <span *ngIf="lang === 'fr'" class="label" [style.background-color]="row.statutHoteCouleur">{{
                      row.statutHoteLibelle
                    }}</span>
                    <span *ngIf="lang === 'en'" class="label" [style.background-color]="row.statutHoteCouleur">{{
                      row.statutHoteLibelleEn
                    }}</span>
                    <span *ngIf="row.dryRun === true" class="label" [style.background-color]="'#333'">{{
                      'ModeAudit' | translate
                    }}</span>
                    <span *ngIf="row.panicMode === true" class="label" [style.background-color]="'#333'">{{
                      'Mode Panique' | translate
                    }}</span>
                    <span *ngIf="row.isACertificateInError" [ngbTooltip]="'CertificateError' | translate"
                      ><i class="icon-warning icofont icofont-warning" style="top: 3px; cursor: default"></i
                    ></span>
                  </td>
                  <td>
                    <span
                      *ngIf="row.mode === hostModeService.expert"
                      class="label"
                      [style.background-color]="'#42389e'"
                      title="Expert"
                      >E</span
                    >
                    <span
                      *ngIf="row.mode === hostModeService.advanced"
                      class="label"
                      [style.background-color]="'#4099ff'"
                      title="{{ 'Advanced' | translate }}"
                      >A</span
                    >
                    <span
                      *ngIf="row.managed"
                      class="label mr-2 managed-service-badge"
                      title="{{ 'Managed' | translate }}"
                      >M</span
                    >
                    <a *ngIf="auth.canEdit(row)" href="javascript:" (click)="editHote(row)">{{ row.nom }}</a>
                    <span *ngIf="!auth.canEdit(row)">{{ row.nom }}</span>
                    <a class="ogo-link" target="_blank" [href]="row.destHostScheme + '://' + row.nom">
                      <i class="icofont icofont-external-link ml-2"></i>
                    </a>
                  </td>
                  <td [hidden]="adminClusterView">
                    <a>{{ row.clusterNom }}</a>
                  </td>
                  <td>
                    <a
                      >{{ row.destHostScheme }}://{{
                        isValidIpv6(row.destHost) ? '[' + row.destHost + ']' : row.destHost
                      }}{{ row.port ? ':' + row.port : '' }}</a
                    >
                  </td>
                  <td
                    *ngIf="adminClusterView"
                    class="flex-align-items"
                    [title]="row.ownerCompanyName + ' (' + row.utilisateurEmail + ') ' + row.ownerCode"
                  >
                    <span> {{ row.ownerCompanyName }} </span>
                    <app-badge-organization [code]="row.ownerCode"></app-badge-organization>
                  </td>
                  <td class="date">
                    {{ row.createdAt | date: 'dd/MM/yyyy' }}
                  </td>
                  <td *ngIf="adminClusterView" style="padding: 5px 10px !important">
                    <select
                      [ngModel]="row.mode"
                      (change)="toggleMode(row.id, row.mode, $event)"
                      class="form-control"
                      style="font-weight: normal; min-width: 105px"
                    >
                      <option *ngFor="let m of hostModeService.modes" [value]="m.value">
                        {{ m.libelle | translate }}
                      </option>
                    </select>
                  </td>
                  <td>
                    <i
                      *ngIf="auth.canEdit(row)"
                      class="pointer icon-reload"
                      style="font-size: 16px; margin-right: 8px; transition: color 0.5s linear"
                      title="{{ 'RafraichirStatutHote' | translate }}"
                      [ngStyle]="{
                        color: row.isRefreshing ? '#adadad' : 'rgb(64, 153, 255)',
                        cursor: row.isRefreshing ? 'not-allowed' : 'cursor'
                      }"
                      (click)="!row.isRefreshing && refreshStatus(row)"
                    ></i>
                    <i
                      *ngIf="auth.canShare(row)"
                      title="{{ 'MesPartages' | translate }}"
                      style="color: rgb(14, 110, 253); font-size: 20px"
                      class="pointer icofont icofont-share ml-1"
                      (click)="showSharedHosts(row)"
                    ></i>
                    <sup
                      *ngIf="row.shares.length && auth.canShare(row)"
                      class="badge1"
                      (click)="showSharedHosts(row)"
                      >{{ row.shares.length }}</sup
                    >
                    <i
                      *ngIf="auth.canEdit(row)"
                      title="{{ 'Configuration' | translate }}"
                      (click)="editHote(row)"
                      style="color: #4099ff; font-size: 20px"
                      [ngStyle]="{ marginLeft: auth.canShare(row) ? (row.shares.length ? '-5px' : '10px') : '5px' }"
                      class="pointer icon-settings"
                    ></i>
                    <i
                      *ngIf="auth.canDelete(row)"
                      title="{{ 'supprimer' | translate }}"
                      [attr.name]="row.nom + '-delete'"
                      (click)="showModalDelete(row)"
                      style="color: #e74c3c; font-size: 20px"
                      class="pointer icofont icofont-ui-delete ml-2"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="hosts.length" class="flex-align-items float-left" style="margin-top: 10px; margin-left: 10px">
              <a class="ogo-link mr-3" style="font-weight: bold" (click)="selectAll()">{{ 'SelectAll' | translate }}</a>
              <i
                title="{{ 'AuditPanic' | translate }}"
                [ngStyle]="{
                  color: selectedHosts.length ? '#4099ff' : '#a5a5a5',
                  cursor: selectedHosts.length ? 'pointer' : 'default'
                }"
                (click)="selectedHosts.length && modalMultipleAuditPanic.show()"
                style="font-size: 20px; transition: all 0.5s"
                class="pointer icon-settings"
              ></i
              >&nbsp;&nbsp;
              <i
                title="{{ 'PartageMultiple' | translate }}"
                [ngStyle]="{
                  color: canMultipleShare() ? '#4099ff' : '#a5a5a5',
                  cursor: canMultipleShare() ? 'pointer' : 'default'
                }"
                (click)="canMultipleShare() && showModalMultipleShares()"
                style="font-size: 20px; transition: all 0.5s"
                class="icofont icofont-share"
              ></i
              >&nbsp;&nbsp;
              <i
                title="{{ 'SuppressionMultiple' | translate }}"
                [ngStyle]="{
                  color: selectedHosts.length ? '#4099ff' : '#a5a5a5',
                  cursor: selectedHosts.length ? 'pointer' : 'default'
                }"
                (click)="selectedHosts.length && modalMultipleDeletes.show()"
                style="font-size: 20px; transition: all 0.5s"
                class="icofont icofont-ui-delete"
              ></i
              >&nbsp;&nbsp;
              <i
                title="{{ 'CertificatMultiple' | translate }}"
                [ngStyle]="{
                  color: selectedHosts.length ? '#4099ff' : '#a5a5a5',
                  cursor: selectedHosts.length ? 'pointer' : 'default'
                }"
                (click)="selectedHosts.length && modalMultipleCertificat.show()"
                style="font-size: 20px; transition: all 0.5s"
                class="icofont icofont-papers"
              ></i>
              <i
                title="{{ 'TagsMultiple' | translate }}"
                [ngStyle]="{
                  color: selectedHosts.length ? '#4099ff' : '#a5a5a5',
                  cursor: selectedHosts.length ? 'pointer' : 'default'
                }"
                (click)="selectedHosts.length && modalMultipleTags.show()"
                style="font-size: 20px; transition: all 0.5s; margin-left: 7px"
                class="icofont icofont-tags"
              ></i>
              <i
                title="{{ 'RefreshMultiple' | translate }}"
                [ngStyle]="{
                  color: selectedHosts.length && !sitesInRefresh ? '#4099ff' : '#a5a5a5',
                  cursor: selectedHosts.length && !sitesInRefresh ? 'pointer' : 'default'
                }"
                (click)="selectedHosts.length && !sitesInRefresh && multipleRefreshStatus()"
                style="font-size: 17px; transition: all 0.5s; margin-left: 7px"
                class="icon-reload"
              ></i>
            </div>
            <div class="flex-align-items pull-right">
              <div class="sites-total mr-3">Total : {{ total }}</div>
              <pagination-controls
                style="margin-top: 10px"
                *ngIf="hosts.length"
                (pageChange)="changePage($event)"
                previousLabel="{{ 'Previous' | translate }}"
                nextLabel="{{ 'Next' | translate }}"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>

<app-modal-basic #modalShare [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      {{ 'MesPartages' | translate }} /
      <small class="text-primary">{{ hoteSelected.nom }}</small>
      <a
        class="ml-2"
        target="_blank"
        [href]="
          lang == 'fr'
            ? EXTERNAL_HELP_FRENCH_LINK + '/gestion-des-sites-partage-d-un-site'
            : EXTERNAL_HELP_ENGLISH_LINK + '/sharing-a-site'
        "
      >
        <label class="badge badge-sm bg-dark pointer">?</label>
      </a>
    </h4>
    <button type="button" class="close basic-close" (click)="modalShare.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <p class="mb-0" [innerHTML]="'PartagerSite' | translate"></p>
        <div class="row">
          <div class="col-md-12 p-3">
            <div class="group flex-align-items space-between mb-3 ng-select-no-dropdown">
              <div style="flex-basis: 80%">
                <ng-select
                  #addShareInput
                  (paste)="onPasteShare($event)"
                  class="select-site-selector"
                  [multiple]="true"
                  [addTag]="true"
                  [(ngModel)]="shareInput"
                  [clearable]="false"
                  [searchable]="true"
                  [openOnEnter]="false"
                  [closeOnSelect]="true"
                  [placeholder]="'EnterEmailOrOrganizationCode' | translate"
                  [inputAttrs]="{ 'data-form-type': 'other' }"
                  (focus)="shareTooltip = true"
                  (blur)="shareTooltip = false; handleShareBlur()"
                  (search)="handleShareType($event)"
                ></ng-select>
              </div>
              <button
                class="create-share"
                (click)="confirmCreateShare()"
                [disabled]="!shareInput.length"
                class="btn btn-sm btn-primary"
              >
                {{ 'Partager' | translate }}
              </button>
            </div>
            <div *ngIf="shareTooltip" style="margin-top: -10px; margin-bottom: 10px; font-size: 12px; color: #969696">
              <i>{{ 'PressEnterToAdd' | translate }}{{ 'SeparatedEmailsOrOrganizationsCodes' | translate }}</i>
            </div>
            <div class="form-radio">
              <form>
                <ng-container *ngFor="let role of shareRoles">
                  <div class="radio radiofill radio-inline">
                    <label class="mt-1">
                      <input [(ngModel)]="sharedRole" [value]="role" name="radio" type="radio" />
                      <i class="helper"></i>
                      <label-share-role [role]="role" />
                      {{ 'ShareSite.' + role + '.description' | translate }}
                    </label>
                  </div>
                </ng-container>
              </form>
            </div>
          </div>
        </div>
        <br />
        <b>{{ 'Liste des partages actifs' | translate }}</b>
        <div class="share-list custom-scrollbar">
          <table *ngIf="currentShares.length" class="table table-xs table-bordered" style="margin-top: 10px">
            <tbody>
              <tr *ngFor="let item of currentShares; let i = index">
                <td class="flex-align-items">
                  <div class="text-ellipsis" style="max-width: 235px">
                    <div class="flex-align-items">
                      <div [title]="item.organizationCompanyName" class="text-ellipsis" style="max-width: 50%">
                        {{ item.organizationCompanyName }}
                      </div>
                      <app-badge-organization [code]="item.organizationCode"></app-badge-organization>
                    </div>
                  </div>
                  <div class="ml-2"><label-share-role [role]="item.role" /></div>
                  <i
                    (click)="deleteShare(hoteSelected.nom, item.organizationCode, i)"
                    style="color: #e74c3c; font-size: 20px; margin-left: auto"
                    class="icofont icofont-ui-delete"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p *ngIf="!currentShares.length" class="grey-disable">
          {{ 'AucunPartageActifPourLeMoment' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button (click)="modalShare.hide()" class="btn btn-sm btn-danger">
      {{ 'Fermer' | translate }}
    </button>
  </div>
</app-modal-basic>

<app-modal-basic #modalDelete [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHTML]="'suprimerHote' | translate"></small>
    </h4>
    <button type="button" class="close basic-close" (click)="modalDelete.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <form>
          <div class="form-group">
            <label>{{ 'Site' | translate }} :</label>
            <input type="text" value="{{ hoteSelected.nom }}" class="form-control form-control-sm" readonly />
          </div>
          <div class="form-group">
            <label>Cluster :</label>
            <input type="text" value="{{ hoteSelected.clusterNom }}" class="form-control form-control-sm" readonly />
          </div>
          <div class="form-group">
            <label>Destination / IP :</label>
            <input type="text" value="{{ hoteSelected.destHost }}" class="form-control form-control-sm" readonly />
          </div>
          <div [hidden]="hoteSelected?.countCert == 0" class="form-group">
            <label>{{ 'CertificatSsl' | translate }} : </label>
            &nbsp;<strong>{{ hoteSelected?.countCert }}</strong>
          </div>
          <div [hidden]="hoteSelected?.countUrlExcept == 0" class="form-group">
            <label>{{ 'URLExceptions' | translate }} : </label>
            &nbsp;<strong>{{ hoteSelected?.countUrlExcept }}</strong>
          </div>
          <div *ngIf="hoteSelected?.shares !== undefined" class="form-group">
            <label>{{ 'Partages' | translate }} : </label>
            &nbsp;<strong>{{ hoteSelected?.shares.length }}</strong>
          </div>
          <div [hidden]="hoteSelected?.countWhitelistedIp == 0" class="form-group">
            <label>{{ 'WhitelistedIps' | translate }} : </label>
            &nbsp;<strong>{{ hoteSelected?.countWhitelistedIp }}</strong>
          </div>
          <div [hidden]="hoteSelected?.countRule == 0" class="form-group">
            <label>{{ 'RulesManagement' | translate }} : </label>
            &nbsp;<strong>{{ hoteSelected?.countRule }}</strong>
          </div>
          <div [hidden]="hoteSelected?.countRewriteRule == 0" class="form-group">
            <label>{{ 'RewriteRules' | translate }} : </label>
            &nbsp;<strong>{{ hoteSelected?.countRewriteRule }}</strong>
          </div>
          <div class="form-group form-check">
            <input
              type="checkbox"
              name="check-delete"
              [checked]="hoteSelected.confirmDelete"
              style="width: inherit"
              (change)="hoteSelected.confirmDelete = !hoteSelected.confirmDelete"
              class="form-check-input"
            />
            <label class="form-check-label">{{ 'ConfirmSuppreSite' | translate }}</label>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      [disabled]="!hoteSelected.confirmDelete"
      name="validate-delete"
      (click)="deleteHost()"
      class="btn btn-primary btn-sm"
      [innerHTML]="'delete' | translate"
    ></button>
    &nbsp;
    <button (click)="modalDelete.hide()" class="btn btn-danger btn-sm" [innerHTML]="'Fermer' | translate"></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleAuditPanic [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHTML]="'AuditPanic' | translate"></small>
    </h4>
    <button type="button" class="close basic-close" (click)="modalMultipleAuditPanic.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered" style="margin-top: 20px">
              <tbody>
                <tr *ngFor="let row of selectedHosts">
                  <td>
                    {{ row.nom }}
                    <b
                      class="mode-letter"
                      title="{{ 'PanicMode' | translate }}"
                      (click)="updateHost(row, 'panicMode', null, true)"
                      [ngStyle]="{
                        color: row.panicMode ? '#0e6efd' : '#8c8e92'
                      }"
                      >{{ 'Panic' | translate }}</b
                    >
                    <b
                      class="mode-letter"
                      title="{{ 'AuditMode' | translate }}"
                      (click)="updateHost(row, 'dryRun', null, true)"
                      [ngStyle]="{
                        color: row.dryRun ? '#0e6efd' : '#8c8e92'
                      }"
                      >Audit</b
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="margin-bottom: 30px">
            <button
              [disabled]="allEnabled('dryRun', false)"
              (click)="allUpdateHost('dryRun', false)"
              class="btn btn-primary btn-mini pull-right"
              [innerHTML]="'ToutEnleverModeAudit' | translate"
              style="margin: 5px"
            ></button>
            <button
              [disabled]="allEnabled('dryRun', true)"
              (click)="allUpdateHost('dryRun', true)"
              class="btn btn-primary btn-mini pull-right"
              [innerHTML]="'ToutPasserModeAudit' | translate"
              style="margin: 5px"
            ></button>
          </div>
          <div>
            <button
              [disabled]="allEnabled('panicMode', false)"
              (click)="allUpdateHost('panicMode', false)"
              class="btn btn-primary btn-mini pull-right"
              [innerHTML]="'ToutEnleverModePanic' | translate"
              style="margin: 5px"
            ></button>
            <button
              [disabled]="allEnabled('panicMode', true)"
              (click)="allUpdateHost('panicMode', true)"
              class="btn btn-primary btn-mini pull-right"
              [innerHTML]="'ToutPasserModePanic' | translate"
              style="margin: 5px"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      (click)="modalMultipleAuditPanic.hide()"
      style="margin-top: 5px; margin-right: 5px"
      class="btn btn-danger btn-sm pull-right"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleShares [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHTML]="'PartageMultiple' | translate"></small>
      <a
        class="ml-2"
        target="_blank"
        [href]="
          lang == 'fr'
            ? EXTERNAL_HELP_FRENCH_LINK + '/gestion-des-sites-partage-d-un-site'
            : EXTERNAL_HELP_ENGLISH_LINK + '/sharing-a-site'
        "
      >
        <label class="badge badge-sm bg-dark pointer">?</label>
      </a>
    </h4>
    <button type="button" class="close basic-close" (click)="closeMultipleShares()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <p [innerHTML]="'PartagerSites' | translate"></p>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered" style="margin-top: 20px">
              <tbody>
                <tr *ngFor="let row of selectedHosts">
                  <td *ngIf="hostModeService.isAdvanced(row.mode) && row.accessRights.includes('SHARE')">
                    {{ row.nom }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="group flex-align-items space-between mb-3 ng-select-no-dropdown">
            <div style="flex-basis: 80%">
              <ng-select
                #addMultiShareInput
                (paste)="onPasteShare($event)"
                class="select-site-selector"
                [multiple]="true"
                [addTag]="true"
                [(ngModel)]="shareInput"
                [clearable]="false"
                [openOnEnter]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                [placeholder]="'EnterEmailOrOrganizationCode' | translate"
                [inputAttrs]="{ 'data-form-type': 'other' }"
                (focus)="shareTooltip = true"
                (blur)="$event.preventDefault(); shareTooltip = false; handleShareBlur()"
                (search)="handleShareType($event)"
              ></ng-select>
            </div>
            <button (click)="allCreateShare()" [disabled]="!shareInput.length" class="btn btn-sm btn-primary">
              {{ 'Partager' | translate }}
            </button>
          </div>
          <div *ngIf="shareTooltip" style="margin-top: -10px; margin-bottom: 10px; font-size: 12px; color: #969696">
            <i>{{ 'PressEnterToAdd' | translate }}{{ 'SeparatedEmailsOrOrganizationsCodes' | translate }}</i>
          </div>
          <div class="form-radio" style="margin-top: 20px">
            <form>
              <ng-container *ngFor="let role of shareRoles">
                <div class="radio radiofill radio-inline">
                  <label class="mt-1">
                    <input [(ngModel)]="multipleSharedRole" [value]="role" name="radio" type="radio" />
                    <i class="helper"></i>
                    <span class="label {{ 'share-' + role }}" style="padding: 4px 9px">{{
                      'ShareSite.' + role + '.label' | translate
                    }}</span>
                    {{ 'ShareSite.' + role + '.description' | translate }}
                  </label>
                </div>
              </ng-container>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      (click)="closeMultipleShares()"
      style="margin-top: 5px; margin-right: 5px"
      class="btn btn-danger btn-sm pull-right"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleDeletes [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHTML]="'SuppressionMultiple' | translate"></small>
    </h4>
    <button
      type="button"
      class="close basic-close"
      (click)="modalMultipleDeletes.hide(); multipleConfirmDelete = false"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <p>{{ 'DeleteSites' | translate }}</p>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered" style="margin-top: 20px">
              <tbody>
                <tr *ngFor="let row of selectedHosts">
                  <td>
                    {{ row.nom }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group form-check">
            <input
              type="checkbox"
              [(ngModel)]="multipleConfirmDelete"
              style="width: inherit"
              class="form-check-input"
            />
            <label class="form-check-label">{{ 'ConfirmSuppreSites' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
    <button
      [disabled]="!multipleConfirmDelete"
      (click)="allDelete()"
      class="btn btn-primary btn-sm"
      [innerHTML]="'DeleteDomains' | translate"
    ></button>
    &nbsp;
    <button
      (click)="modalMultipleDeletes.hide(); multipleConfirmDelete = false"
      class="btn btn-danger btn-sm"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleCertificat [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHTML]="'CertificatMultiple' | translate"></small>
    </h4>
    <button type="button" class="close basic-close" (click)="modalMultipleCertificat.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <p>{{ 'ImportForSites' | translate }}</p>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered" style="margin-top: 20px">
              <tbody>
                <tr *ngFor="let row of selectedHosts">
                  <td>
                    {{ row.nom }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group bg-orange p-2">
            {{ 'DoNotForgetConcatIntermediates' | translate }}
          </div>
          <form>
            <div class="form-group">
              <label>{{ 'PKCS12File' | translate }}</label>
              <div class="">
                <button (click)="loadPkcs12.click()" class="btn btn-block btn-primary btn-sm">
                  {{ 'ImportPKCS12' | translate }}
                  <input
                    #loadPkcs12
                    id="loadPkcs12"
                    type="file"
                    name="loadPkcs12"
                    accept=".p12,.pfx"
                    hidden
                    (change)="handlePkcs12($event.target.files)"
                    multiple="false"
                  />
                </button>
                <p *ngIf="p12File">
                  <small class="text-primary">{{ p12File.name }}</small>
                </p>
              </div>
            </div>
            <div class="form-group">
              <label>{{ 'MotDePasse' | translate }}</label>
              <input
                type="password"
                [(ngModel)]="p12Password"
                (keydown.enter)="handleKeyEnterP12($event)"
                name="p12Password"
                class="form-control form-control-sm"
              />
            </div>
            <div class="form-group flex-align-items">
              <input type="checkbox" [(ngModel)]="makeCertificateActive" name="makeCertificateActive" />
              <label class="form-check-label" for="makeCertificateActive" style="margin-left: 5px">{{
                'MakeCertificateActive' | translate
              }}</label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer pull-right">
    <button
      (click)="saveP12Certif()"
      class="btn btn-primary btn-sm"
      [disabled]="!p12File || !p12Password"
      [innerHTML]="'Import' | translate"
    ></button>
    &nbsp;
    <button
      (click)="modalMultipleCertificat.hide()"
      class="btn btn-danger btn-sm"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>

<app-modal-basic #modalMultipleTags [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <small [innerHTML]="'TagsMultiple' | translate"></small>
    </h4>
    <button type="button" class="close basic-close" (click)="modalMultipleTags.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <p>{{ 'TagsMultipleDesc' | translate }}</p>
          <div class="multiple-action-table-container custom-scrollbar">
            <table class="table table-xs table-bordered" style="margin-top: 20px">
              <tbody>
                <tr *ngFor="let row of selectedHosts">
                  <td>
                    {{ row.nom }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-site-tags [tags]="tags" (add)="pushTags($event)"></app-site-tags>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer pull-right">
    <button
      (click)="createTags()"
      class="btn btn-primary btn-sm"
      [disabled]="!selectedTags.length"
      [innerHTML]="'ToTag' | translate"
    ></button>
    &nbsp;
    <button
      (click)="modalMultipleTags.hide()"
      class="btn btn-danger btn-sm"
      [innerHTML]="'Fermer' | translate"
    ></button>
  </div>
</app-modal-basic>

<div *ngIf="addSiteView" class="row">
  <div class="col-12">
    <button (click)="addSiteView = false; loadHosts()" class="btn btn-round btn-primary btn-mini mb-3">
      < {{ 'BackToAllSites' | translate }}
    </button>
  </div>
  <div class="col-12">
    <app-add-site (hoteAdded)="hoteAdded()" [hote]="hoteSelected" [user]="user"></app-add-site>
  </div>
</div>
