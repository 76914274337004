import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlStats } from 'app/services/logs.service';
import { TrackPathEvent } from 'app/theme/dashboard/track-path.event';

@Component({
  selector: 'app-cpu-consuming-requests-table',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './cpu-consuming-requests-table.component.html',
  styleUrl: './cpu-consuming-requests-table.component.scss',
})
export class CpuConsumingRequestsTableComponent {
  @Input() cpuConsumingRequests: UrlStats[];
  @Input() print: boolean = false;

  @Output() clickPath = new EventEmitter<TrackPathEvent>();

  constructor(public translate: TranslateService) {
  }
}
