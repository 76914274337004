<div class="card">
  <div class="col-md-12">
    <div class="row" style="padding-top: 15px">
      <div class="col-md-8 mt-2">
        <h4 class="mb-0">
          {{ 'OrganizationParameters' | translate }}
          <label class="badge badge-sm bg-dark"
            ><a style="color: inherit" target="_blank" [href]="lang == 'fr' ? EXTERNAL_HELP_FRENCH_LINK + '/utilisateurs-et-organisations' : EXTERNAL_HELP_ENGLISH_LINK + '/users-and-organisations'"
              >?</a
            ></label
          >
        </h4>
      </div>
      <div *ngIf="auth.userHasFeature(ACL_ORGANIZATION_MANAGE)" class="col-md-4">
        <button type="button" (click)="updateOrganization()" [hidden]="!editField" class="btn btn-primary btn-sm pull-right" [innerHTML]="'Enregistrer' | translate"></button>
        <button
          type="button"
          (click)="organization = previousOrganization; editField = false"
          [hidden]="!editField"
          class="btn btn-danger btn-sm pull-right mr-2"
          [innerHTML]="'Annuler' | translate"
        ></button>
        <button type="button" (click)="onEditField()" [hidden]="editField" class="btn btn-primary btn-sm pull-right" [innerHTML]="'Modifier' | translate"></button>
      </div>
    </div>
    <hr />
    <div class="row mb-2">
      <div class="col-md-8 mt-2 mb-3">
        <h5 [innerHTML]="'Informations' | translate"></h5>
      </div>
      <div class="col-md-12">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">
            {{ 'OrganizationCode' | translate }}
            <label class="badge badge-sm bg-dark ml-1" placement="right" ngbTooltip="{{ 'OrganizationCodeTooltip' | translate }}">?</label>
          </label>
          <div class="col-sm-9 flex-align-items">
            <app-badge-organization [code]="organization.code"></app-badge-organization>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" [innerHTML]="'OrganizationName' | translate"></label>
          <div class="col-sm-9">
            <input type="text" style="font-weight: bold" [(ngModel)]="organization.companyName" name="companyName" [disabled]="!editField" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" [innerHtml]="'Owner' | translate"></label>
          <div class="col-sm-9">
            <input [hidden]="editField && auth.userHasFeature(ACL_ORGANIZATION_OWNER_MANAGE)" type="text" [(ngModel)]="organization.owner.email" name="email" disabled class="form-control" />
            <ng-select
              [hidden]="!editField || !auth.userHasFeature(ACL_ORGANIZATION_OWNER_MANAGE)"
              #searchUsers
              bindLabel="email"
              bindValue="email"
              [items]="users"
              [multiple]="false"
              [closeOnSelect]="true"
              [(ngModel)]="organization.owner.email"
              notFoundText="{{ 'Min3Chars' | translate }}"
            >
            </ng-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" [innerHTML]="'Rue' | translate"></label>
          <div class="col-sm-9">
            <input type="text" [(ngModel)]="organization.street" name="rue" [disabled]="!editField" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" [innerHTML]="'CodePostal' | translate"></label>
          <div class="col-sm-9">
            <input type="text" [(ngModel)]="organization.zipCode" name="codepostal" [disabled]="!editField" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" [innerHTML]="'Ville' | translate"></label>
          <div class="col-sm-9">
            <input type="text" [(ngModel)]="organization.city" name="ville" [disabled]="!editField" class="form-control" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" [innerHtml]="'Pays' | translate"></label>
          <div class="col-sm-9">
            <select [(ngModel)]="organization.countryCode" name="countries" class="form-control" [disabled]="!editField">
              <option *ngFor="let p of countries" [value]="p.countryCode">
                {{ lang == 'fr' ? p.countryNameFr : p.countryNameEn }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
