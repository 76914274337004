import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlockedIp } from 'app/services/logs.service';
import { CountryService } from 'app/services/country.service';

@Component({
  selector: 'app-blocked-ips-table',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './blocked-ips-table.component.html',
  styleUrl: './blocked-ips-table.component.scss',
})
export class BlockedIPsTableComponent {
  @Input() topBlockedIps: BlockedIp[];
  @Input() print: boolean = false;

  @Output() trackCountry = new EventEmitter<string>();
  @Output() trackIp = new EventEmitter<string>();
  @Output() whitelistIp = new EventEmitter<WhitelistIpEvent>();

  constructor(
    public translate: TranslateService,
    public countries: CountryService,
  ) {}

  async ngOnInit() {
    await this.countries.init();
  }
}

export interface WhitelistIpEvent {
  ip: string;
  site: string;
}
