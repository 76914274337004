import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import { DASHBOARD_ROUTE } from 'app/app-routing.module';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { Global } from 'app/global';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipLoader = req.headers.has('Skip-Loader') && req.headers.get('Skip-Loader') === 'true';

    if (!skipLoader) {
      this.showLoader();
    } else {
      req.headers.delete('Skip-Loader');
    }

    const lang = this.auth.getCurrentLanguage();
    let headers = req.headers;

    if (req.method === 'POST' && headers.get('formData') !== 'true') {
      headers = headers.append('Content-Type', 'application/json');
    }

    if (this.auth?.currentOrganization?.id) {
      headers = headers.append('lang', lang).append('x-ogo-organizationId', String(this.auth.currentOrganization.id));
    }

    let opt = { withCredentials: true, headers: headers, method: req.method, body: req.body };

    req = req.clone(opt);

    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.url) {
            if (event.status == 401 || event.body?.status?.code == 924) {
              this.auth.logout();
            }
            this.onEnd();
          }
        },
        error: (err) => {
          if (err.status == 401) {
            this.auth.logout();
          } else if (Global.reloadOnError && (err.status == 404 || err.status == 400)) {
            window.location.reload();
          }
          console.log(err);
          const message = err?.error?.detail;
          this.toastr.error(this.translate.instant('Erreur') + (message ? ': ' + message : ''));
          this.onEnd();
        },
      }),
    );
  }

  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
