import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Highcharts from 'highcharts';
import moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { DATE_NOW } from '../../date-range-selector/calendar-data.service';
import { DateRange } from 'app/shared/utils/date-range';

export interface ClickEvent {
  category: string;
  slug: any;
}

@Component({
  selector: 'app-graph',
  templateUrl: './graph.html',
  styleUrls: ['./graph.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class GraphComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') chart: ElementRef;

  @Input() fontSize: string = '14px';
  @Input() height: string = '400px';
  @Input() exportButtonSpacing = null;
  @Input() data: any = {};
  @Input() cursor: string = null;

  @Output() zoneSelected = new EventEmitter<DateRange>();
  @Output() columnClicked = new EventEmitter<ClickEvent>();
  @Output() isRendered = new EventEmitter<void>();

  constructor(
    private translate: TranslateService,
    private auth: AuthService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      this.drawChart(this.data);
    }
  }

  ngAfterViewInit() {
    this.drawChart(this.data);
  }

  columnClick = (event: any) => {
    // for unidentified reason, custom for spline in on event.point, and for column on event.point.series.options
    this.columnClicked.emit({
      category: event?.point?.custom?.category ?? event.point.series.options.custom.category,
      slug: event?.point?.custom?.slug ?? event.point.series.options.custom.slug ?? event.point.y,
    });
  };

  drawChart(data: any) {
    if (!this.chart?.nativeElement || !data?.chart) return;

    data = this.setGlobalOptions(data);

    Highcharts.chart(data);
  }

  toMoment(x) {
    return moment(Math.round(JSON.parse(JSON.stringify(x))));
  }

  setGlobalOptions(data) {
    data.chart.renderTo = this.chart.nativeElement;

    data.chart.events = {
      selection: (event) => {
        if (event.xAxis) {
          this.zoneSelected.emit({
            start: this.toMoment(event.xAxis[0].min),
            end: moment.min([DATE_NOW(), this.toMoment(event.xAxis[0].max)]),
          });
        }
        return false; // import, disable natural zooming
      },
      render: () => {
        this.isRendered.emit();
      },
      load: drawVerticalBar,
    };

    if (data?.chart?.type) {
      data.plotOptions[data.chart.type].cursor = this.cursor || 'pointer';
      data.plotOptions[data.chart.type].events = {
        click: this.columnClick,
      };
    }

    if (data?.exporting?.buttons?.contextButton && this.exportButtonSpacing) {
      data.exporting.buttons.contextButton.x = this.exportButtonSpacing.x;
      data.exporting.buttons.contextButton.y = this.exportButtonSpacing.y;
    }

    data.lang = {
      noData: this.translate.instant('NoData'),
      printChart: this.translate.instant('PrintChart'),
      downloadPNG: this.translate.instant('DownloadPNG'),
      downloadJPEG: this.translate.instant('DownloadJPEG'),
      downloadSVG: this.translate.instant('DownloadSVG'),
      downloadCSV: this.translate.instant('DownloadCSV'),
      downloadXLS: this.translate.instant('DownloadXLS'),
    };

    if (this.auth.getCurrentLanguage() == 'fr') {
      Highcharts.setOptions({
        lang: {
          weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
          months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
          shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
          shortWeekdays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        },
      });
    }

    if (data?.title?.style) {
      data.title.style = {
        fontSize: this.fontSize,
      };
    }

    return data;
  }
}

function drawVerticalBar() {
  const chart = this;

  chart.customCrosshair = chart.renderer
    .path(['M', 0, 0, 'L', 0, chart.plotHeight])
    .attr({
      'stroke-width': 2,
      stroke: '#dbd9da',
      zIndex: 0,
    })
    .add();

  Highcharts.addEvent(chart.container, 'mousemove', function (e) {
    const x = chart.pointer.normalize(e).chartX;
    if (x >= chart.plotLeft && x <= chart.plotLeft + chart.plotWidth) {
      chart.customCrosshair.attr({
        d: ['M', x, chart.plotTop, 'L', x, chart.plotTop + chart.plotHeight],
      });
    }
  });

  Highcharts.addEvent(chart.container, 'mouseleave', function () {
    chart.customCrosshair.attr({
      d: ['M', 0, 0, 'L', 0, 0],
    });
  });
}
