<div class="container-fluid">
  <app-cluster-selector
    [cluster]="cluster"
    [clusters]="clusters"
    (selected)="clusterSelected($event)"
  ></app-cluster-selector>

  <div class="card p-3">
    <div class="row">
      <div class="col-12">
        <h5 class="flex-align-items space-between">
          <div>
            {{ 'IAParameters' | translate }}
            <label class="badge badge-sm bg-dark"
              ><a
                style="color: inherit"
                target="_blank"
                [href]="
                  lang == 'fr'
                    ? EXTERNAL_HELP_FRENCH_LINK + '/mode-expert'
                    : EXTERNAL_HELP_ENGLISH_LINK + '/expert-mode'
                "
                >?</a
              ></label
            >
          </div>
          <div>
            <button
              *ngIf="!editMode"
              type="button"
              (click)="toggleEditMode()"
              class="btn btn-primary btn-sm pull-right"
              [innerHTML]="'Modifier' | translate"
            ></button>
            <button
              *ngIf="editMode"
              type="button"
              (click)="toggleEditMode()"
              class="btn btn-sm btn-primary btn-sm pull-right"
              [innerHTML]="'Enregistrer' | translate"
              [disabled]="errors.length || isLoading"
            ></button>
            <button
              type="button"
              (click)="cancelUpdate()"
              [hidden]="!editMode"
              class="btn btn-danger btn-sm pull-right mr-2"
              [innerHTML]="'Annuler' | translate"
            ></button>
          </div>
        </h5>
        <p style="margin-top: 10px">{{ 'IAParametersDescription' | translate }}</p>
        <hr style="margin-bottom: 0.8rem" />
        <div *ngIf="brainParams.length">
          <div class="expert-container custom-scrollbar">
            <div *ngFor="let g of brainParams" class="group-container transition" [ngClass]="{ disabled: !editMode }">
              <div class="flex-align-items space-between pointer" (click)="g.open = !g.open">
                <h5>
                  <span *ngIf="g.hasModifiedParam" class="blue-bullet-point"></span>
                  {{ g.groupName }}
                  <span *ngIf="g.countModifiedDrives > 0" class="count-modified">({{ g.countModifiedDrives }})</span>
                </h5>
                <i *ngIf="!g.open" class="icofont icofont-simple-down collapse-arrow"></i>
                <i *ngIf="g.open" class="icofont icofont-simple-up collapse-arrow"></i>
              </div>
              <ng-container *ngIf="g.open">
                <div *ngFor="let d of g.drives" class="drive-container" [ngClass]="{ modified: !!d.hasModifiedParam }">
                  <div class="drive-label">
                    <span *ngIf="d.hasModifiedParam" class="blue-bullet-point"></span>
                    {{ d.label }}<span *ngIf="d.desc">&nbsp;-&nbsp;</span
                    ><span class="little">{{ d.desc }} </span>&nbsp;
                    <label *ngIf="d.uuid == '41150EF72BD544529AA67E9B1C0310DC'" class="badge badge-sm bg-dark"
                      ><a
                        style="color: inherit"
                        target="_blank"
                        [href]="
                          lang == 'fr'
                            ? EXTERNAL_HELP_FRENCH_LINK + '/smart-rate-limiting'
                            : EXTERNAL_HELP_ENGLISH_LINK + '/smart-rate-limiting-9de42b71'
                        "
                        >?</a
                      ></label
                    >
                    <span *ngIf="adminView" class="drive-uuid"> {{ d.uuid }} </span>
                  </div>
                  <div class="flex-align-items wrap drive-parameters-container">
                    <div *ngFor="let p of d.params">
                      <div *ngIf="p.type == 'toggleButton'" class="switch-container">
                        <label class="input-label active">{{ p.label }}</label>
                        <span style="margin-right: 5px">{{ 'Désactivé' | translate }}</span>
                        <label class="switch">
                          <input
                            [(ngModel)]="p.value"
                            type="checkbox"
                            [ngStyle]="{ cursor: editMode ? 'pointer' : 'not-allowed' }"
                            [disabled]="!editMode"
                          />
                          <span
                            class="slider round"
                            [ngStyle]="{
                              opacity: editMode ? '1' : '0.5',
                              cursor: editMode ? 'pointer' : 'not-allowed'
                            }"
                          ></span>
                        </label>
                        <span> {{ 'Activé' | translate }}</span>
                      </div>
                      <div *ngIf="p.type == 'input'" class="flex-align-items">
                        <label class="input-label">{{ p.label }}</label>
                        <input
                          [(ngModel)]="p.value"
                          [disabled]="!editMode"
                          [placeholder]="p.recommendedValue"
                          class="form-control form-control-sm value"
                          [ngClass]="{ modified: p.value != p.defaultValue || p.value != p.recommendedValue }"
                        />
                        <small class="min-max">Min : {{ p.min }} - Max : {{ p.max }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <hr style="margin-bottom: 0.8rem" />
          <button
            (click)="editMode && restoreRecommended()"
            class="restore-default btn btn-sm btn-outline-primary btn-md pull-right"
            [disabled]="!editMode || isLoading"
          >
            {{ 'RestoreRecommended' | translate }}
          </button>
        </div>
        <div *ngIf="!brainParams.length" class="theme-loader">
          <div class="loader-track">
            <div class="loader-bar"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
