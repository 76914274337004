import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode } from '@siemens/ngx-datatable';
import { Validators } from 'app/shared/validators/validators';

import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { Global } from 'app/global';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';
import { UsersService } from '../../../services/users.service';
import { fullName } from 'app/shared/utils/data-utils';
import { EXTERNAL_HELP_ENGLISH_LINK, EXTERNAL_HELP_FRENCH_LINK } from 'app/app-routing.module';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-organization-users',
  templateUrl: './organization-users.component.html',
  styleUrls: ['./organization-users.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class OrganizationUsersComponent implements OnInit {
  lang: string;

  ColumnMode = ColumnMode;
  @ViewChild('modalAddUser') modalAddUser: any;
  @ViewChild('addUserInput') addUserInput: NgSelectComponent;

  lastAddUserInput = '';
  page = 0;
  size = 10;
  total = 0;

  newUsers = [];
  newUsersRole = this.users.roles.viewer;

  EXTERNAL_HELP_FRENCH_LINK = EXTERNAL_HELP_FRENCH_LINK;
  EXTERNAL_HELP_ENGLISH_LINK = EXTERNAL_HELP_ENGLISH_LINK;

  constructor(
    public users: UsersService,
    private http: HttpClient,
    private toastr: ToastrService,
    public translate: TranslateService,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
  }

  async addUser() {
    let promises = [];
    for (let email of [...new Set(this.newUsers.map((e) => e.label.trim()))]) {
      if (!Validators.validateEmail(email)) {
        this.toastr.error(this.translate.instant('InvalidEmail'));
        return;
      }
      promises.push(this.putUser(email, this.newUsersRole));
    }

    await Promise.all(promises);
    await this.loadUsers(this.page);
    this.toastr.success(this.translate.instant('OperationSuccess'));
    this.modalAddUser.hide();
    this.newUsers = [];
    this.newUsersRole = this.users.roles.viewer;
  }

  async updateUser(email, role) {
    await this.putUser(email, role);
    await this.loadUsers(this.page);
    this.toastr.success(this.translate.instant('OperationSuccess'));
  }

  async putUser(email, role) {
    return await firstValueFrom(this.http.put(`${Global.baseUrl}v2/organizations/${this.auth.currentOrganization.id}/users/${email}`, { role }));
  }

  async deleteUser(email) {
    await firstValueFrom(this.http.delete(`${Global.baseUrl}v2/organizations/${this.auth.currentOrganization.id}/users/${email}`));
    await this.loadUsers(this.page);
    this.toastr.success(this.translate.instant('OperationSuccess'));
  }

  changePage(e) {
    this.page = e.page - 1;
    this.loadUsers(this.page);
  }

  async loadUsers(page) {
    let res: any = await this.auth.getOrganizationUsers(this.auth.currentOrganization.id, page, this.size);
    this.auth.currentOrganization.users = res.content;
    this.auth.currentOrganization.nbUsers = res.totalElements;
  }

  onPasteEmails(event) {
    const value = event.clipboardData.getData('text/plain');

    if (value != null && value.match(/[,;]/)) {
      event.preventDefault();
      const split = value.split(/[,;]/);
      split.forEach((s) => (this.newUsers = [...this.newUsers, { label: s.trim() }]));
      event.target.value = '';
    }
  }

  showModalUpdate(previous, email, event) {
    return Swal.fire({
      html: this.translate.instant('ConfirmChangeRole', {
        email,
        role: this.translate.instant(this.users.rolesMap.find((r) => r.value == event.target.value).libelle),
      }),
      title: this.translate.instant('Confirmation'),
      ...this.commonAlertOptions(),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.updateUser(email, event.target.value);
      } else {
        event.target.value = previous;
      }
    });
  }

  showModalDelete(email) {
    return Swal.fire({
      html: this.translate.instant('DeleteUserAlert', { email }),
      title: this.translate.instant('supprimer'),
      ...this.commonAlertOptions(),
    } as SweetAlertOptions).then((result) => {
      if (result.value) this.deleteUser(email);
    });
  }

  commonAlertOptions() {
    return {
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
    };
  }

  handleAddUserType(event) {
    this.lastAddUserInput = event.term;
  }

  handleAddUserBlur() {
    if (this.lastAddUserInput) {
      this.newUsers = [...this.newUsers, { label: this.lastAddUserInput }];
      this.lastAddUserInput = '';
    }
  }

  showModalAddUser() {
    this.modalAddUser.show();
    setTimeout(() => this.addUserInput.focus(), 1);
  }

  protected readonly fullName = fullName;
}
