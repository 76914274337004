import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Global } from '../global';
import { ACL_SITE_EDIT, AuthService } from '../services/auth.service';
import { MenuItems } from '../shared/menu-items/menu-items';
import { SitesService } from 'app/services/sites.service';
import User from 'app/services/entities/user';
import {
  ADD_ORGANIZATION_ROUTE,
  DASHBOARD_ROUTE,
  EXTERNAL_HELP_ENGLISH_LINK,
  EXTERNAL_HELP_FRENCH_LINK,
  MY_ORGANIZATION_ROUTE,
  MY_PROFILE_ROUTE,
  MY_SITES_ROUTE,
} from 'app/app-routing.module';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss', '../../assets/icon/icofont/css/icofont.scss'],
  providers: [TranslateService, Title],
  animations: [
    trigger('notificationBottom', [
      state(
        'an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        }),
      ),
      state(
        'an-animate',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE,
        }),
      ),
      transition('an-off <=> an-animate', [animate('400ms ease-in-out')]),
    ]),
    trigger('docNotif', [
      state(
        'an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        }),
      ),
      state(
        'an-animate',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE,
        }),
      ),
      transition('an-off <=> an-animate', [animate('400ms ease-in-out')]),
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          width: '300px',
          // transform: 'translate3d(0, 0, 0)'
        }),
      ),
      state(
        'out',
        style({
          width: '0',
          // transform: 'translate3d(100%, 0, 0)'
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
    trigger('mobileHeaderNavRight', [
      state(
        'nav-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        }),
      ),
      state(
        'nav-on',
        style({
          height: AUTO_STYLE,
        }),
      ),
      transition('nav-off <=> nav-on', [animate('400ms ease-in-out')]),
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [style({ opacity: 0 }), animate('400ms ease-in-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ transform: 'translate(0)' }), animate('400ms ease-in-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LayoutComponent implements OnInit, AfterViewChecked {
  lang: string;

  /* CSS & HTML Stuff */
  navType: string;
  themeLayout: string;
  verticalPlacement: string;
  verticalLayout: string;
  pcodedDeviceType: string;
  verticalNavType: string;
  verticalEffect: string;
  vnavigationView: string;
  freamType: string;
  sidebarImg: string;
  sidebarImgType: string;
  layoutType: string;
  headerTheme: string;
  pcodedHeaderPosition: string;
  liveNotification: string;
  liveNotificationClass: string;
  profileNotification: string;
  profileNotificationClass: string;
  changeOrganization: string;
  changeOrganizationnizationClass: string;
  chatSlideInOut: string;
  innerChatSlideInOut: string;
  searchWidth: number;
  searchWidthString: string;
  navRight: string;
  windowWidth: number;
  chatTopPosition: string;
  toggleOn: boolean;
  navBarTheme: string;
  activeItemTheme: string;
  pcodedSidebarPosition: string;
  menuTitleTheme: string;
  dropDownIcon: string;
  subItemIcon: string;
  configOpenRightBar: string;
  displayBoxLayout: string;
  isVerticalLayoutChecked: boolean;
  isSidebarChecked: boolean;
  isHeaderChecked: boolean;
  headerFixedMargin: string;
  sidebarFixedHeight: string;
  itemBorderStyle: string;
  subItemBorder: boolean;
  itemBorder: boolean;
  titre: any;
  /* End of CSS & HTML Stuff */

  MY_ORGANIZATION_ROUTE = MY_ORGANIZATION_ROUTE;
  MY_PROFILE_ROUTE = MY_PROFILE_ROUTE;
  ADD_ORGANIZATION_ROUTE = ADD_ORGANIZATION_ROUTE;
  DASHBOARD_ROUTE = DASHBOARD_ROUTE;
  ACL_SITE_EDIT = ACL_SITE_EDIT;
  hideAddDomain: boolean;

  constructor(
    private http: HttpClient,
    public menuItems: MenuItems,
    public auth: AuthService,
    private sites: SitesService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    // Init last action date
    // For decconection feature
    sessionStorage.setItem('lastAction', Date.now().toString());

    this.navType = 'st2';
    this.themeLayout = 'vertical';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.pcodedDeviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.vnavigationView = 'view1';
    this.freamType = 'theme1';
    this.sidebarImg = 'false';
    this.sidebarImgType = 'img1';
    this.layoutType = 'light';

    this.headerTheme = 'themelight5';
    this.pcodedHeaderPosition = 'fixed';

    this.liveNotification = 'an-off';
    this.profileNotification = 'an-off';
    this.changeOrganization = 'an-off';

    this.chatSlideInOut = 'out';
    this.innerChatSlideInOut = 'out';

    this.searchWidth = 0;

    this.navRight = 'nav-on';

    this.hideAddDomain = false;

    this.windowWidth = window.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    this.toggleOn = true;
    this.navBarTheme = 'theme1';
    this.activeItemTheme = 'theme10';
    this.pcodedSidebarPosition = 'fixed';
    this.menuTitleTheme = 'theme6';
    this.dropDownIcon = 'style3';
    this.subItemIcon = 'style6';

    this.displayBoxLayout = 'd-none';
    this.isVerticalLayoutChecked = false;
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;
    this.headerFixedMargin = '56px';
    this.sidebarFixedHeight = 'calc(100vh - 56px)';
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.itemBorder = true;

    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);

    this.router.events.subscribe((e: NavigationEnd) => {
      if (e instanceof NavigationEnd) {
        if (e.url == '/' + MY_SITES_ROUTE) {
          this.hideAddDomain = true;
        } else {
          this.hideAddDomain = false;
        }
      }

      let currentRoute = this.route.root;
      let title = '';
      do {
        const childrenRoutes = currentRoute.children;
        currentRoute = null;
        childrenRoutes.forEach((routes) => {
          if (routes.outlet === 'primary') {
            title = routes.snapshot.data.title;
            currentRoute = routes;
          }
        });
      } while (currentRoute);
      if (title !== undefined) {
        this.titre = title;
      }
    });
  }

  ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  changeCurrentOrganization(organizationId: number) {
    this.auth.changeCurrentOrganization(organizationId);
  }

  changeLang(lang) {
    if (this.auth.getCurrentLanguage() === lang) return;
    this.auth.setUser(new User({ ...this.auth.user, lang }));
    location.reload();
  }

  logout() {
    this.http.post(Global.baseUrl + 'utilisateur/deconnexion', {}).subscribe((res: any) => {
      this.auth.logout();
    });
  }

  goToDocumentation() {
    window.open(this.lang == 'fr' ? EXTERNAL_HELP_FRENCH_LINK : EXTERNAL_HELP_ENGLISH_LINK, '_blank');
  }

  addSite() {
    this.router.navigate([MY_SITES_ROUTE], { state: { function: 'editHote' } });
  }

  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    let reSizeFlag = true;
    if (this.pcodedDeviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
      reSizeFlag = false;
    } else if (this.pcodedDeviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setHeaderAttributes(windowWidth) {
    if (windowWidth < 992) {
      this.navRight = 'nav-off';
    } else {
      this.navRight = 'nav-on';
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.pcodedDeviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else if (windowWidth < 768) {
      this.pcodedDeviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.pcodedDeviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  toggleHeaderNavRight() {
    this.navRight = this.navRight === 'nav-on' ? 'nav-off' : 'nav-on';
    this.chatTopPosition = this.chatTopPosition === 'nav-on' ? '112px' : '';
  }

  toggleLiveNotification() {
    this.liveNotification = this.liveNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.liveNotificationClass = this.liveNotification === 'an-animate' ? 'active' : '';
  }

  toggleProfileNotification() {
    this.profileNotification = this.profileNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.profileNotificationClass = this.profileNotification === 'an-animate' ? 'active' : '';
  }

  async toggleChangeOrganization() {
    this.changeOrganization = this.changeOrganization === 'an-off' ? 'an-animate' : 'an-off';
    this.changeOrganizationnizationClass = this.changeOrganization === 'an-animate' ? 'active' : '';
  }

  notificationOutsideClick(ele: string) {
    if (ele === 'live' && this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    } else if (ele === 'profile' && this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    } else if (ele === 'organization' && this.changeOrganization === 'an-animate') {
      this.toggleChangeOrganization();
    }
  }

  searchOn() {
    document.querySelector('#main-search').classList.add('open');
    const searchInterval = setInterval(() => {
      if (this.searchWidth >= 200) {
        clearInterval(searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth + 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  searchOff() {
    const searchInterval = setInterval(() => {
      if (this.searchWidth <= 0) {
        document.querySelector('#main-search').classList.remove('open');
        clearInterval(searchInterval);
        return false;
      }
      this.searchWidth = this.searchWidth - 15;
      this.searchWidthString = this.searchWidth + 'px';
    }, 35);
  }

  toggleOpened() {
    this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
    if (this.windowWidth < 992 && this.navRight === 'nav-on') {
      this.toggleHeaderNavRight();
    }
    this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
  }

  onClickedOutsideSidebar(e: Event) {
    if (
      (this.windowWidth < 992 && this.toggleOn && this.verticalNavType !== 'offcanvas') ||
      this.verticalEffect === 'overlay'
    ) {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
    }
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.sidebarImg = 'false';
    } else {
      this.menuTitleTheme = 'theme6';
      this.navBarTheme = 'theme1';
      this.sidebarImg = 'false';
    }
  }

  setLayoutType(type: string) {
    this.layoutType = type;
    if (type === 'dark') {
      this.headerTheme = 'theme1';
      this.sidebarImg = 'false';
      this.navBarTheme = 'theme1';
      this.menuTitleTheme = 'theme6';
      document.querySelector('body').classList.add('dark');
    } else if (type === 'light') {
      this.sidebarImg = 'false';
      this.headerTheme = 'theme5';
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      document.querySelector('body').classList.remove('dark');
    } else if (type === 'img') {
      this.sidebarImg = 'true';
      this.headerTheme = 'theme1';
      this.navBarTheme = 'theme1';
      this.menuTitleTheme = 'theme6';
      document.querySelector('body').classList.remove('dark');
    }
  }

  setVerticalLayout() {
    this.isVerticalLayoutChecked = !this.isVerticalLayoutChecked;
    if (this.isVerticalLayoutChecked) {
      this.verticalLayout = 'box';
      this.displayBoxLayout = '';
    } else {
      this.verticalLayout = 'wide';
      this.displayBoxLayout = 'd-none';
    }
  }

  setSidebarPosition() {
    this.isSidebarChecked = !this.isSidebarChecked;
    this.pcodedSidebarPosition = this.isSidebarChecked === true ? 'fixed' : 'absolute';
    this.sidebarFixedHeight = this.isHeaderChecked === true ? 'calc(100vh + 56px)' : 'calc(100vh - 56px)';
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition = this.isHeaderChecked === true ? 'fixed' : 'relative';
    this.headerFixedMargin = this.isHeaderChecked === true ? '56px' : '';
  }
}
