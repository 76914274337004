import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/services/users.service';

import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService, CLUSTER_DELEGATION_ADMIN, CLUSTER_DELEGATION_USE } from '../../services/auth.service';
import { Global } from '../../global';

import { AdminService } from 'app/admin.service';
import Organization from 'app/services/entities/organization';
import { Validators } from 'app/shared/validators/validators';
import { DASHBOARD_ROUTE, EXTERNAL_HELP_ENGLISH_LINK, EXTERNAL_HELP_FRENCH_LINK } from 'app/app-routing.module';
import _ from 'lodash';
import { Page } from 'app/shared/page';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-organization',
  templateUrl: './add-edit-organization.component.html',
  styleUrls: ['./add-edit-organization.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class AddEditOrganizationComponent implements OnInit {
  lang: string;

  @ViewChild('searchUsers') searchUsers: NgSelectComponent;
  @Input() fromAdmin = false;
  @Input() organization: Organization = this.emptyOrganization();
  @Output() cancel: any = new EventEmitter();

  emptyOrganization(): Organization {
    return new Organization({ companyName: '', street: '', city: '', zipCode: '', countryCode: 'FR', clusters: [], owner: { email: '' } });
  }

  countries = [];
  clusters: any = [];
  availableClusters: any = [];
  total = 0;
  size = 10;
  page = 0;

  clustersRead = [];
  clustersAdmin = [];

  EXTERNAL_HELP_FRENCH_LINK = EXTERNAL_HELP_FRENCH_LINK;
  EXTERNAL_HELP_ENGLISH_LINK = EXTERNAL_HELP_ENGLISH_LINK;

  constructor(
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    private auth: AuthService,
    private toastr: ToastrService,
    private users: UsersService,
    public admin: AdminService,
  ) {}

  async ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();

    this.getCountries();

    if (this.fromAdmin) {
      combineLatest([this.getClusters(), this.organization.id ? this.getOrganizationClustersDelegations(this.organization.id) : of([])]).subscribe(([clusters, delegations]) => {
        this.clusters = clusters;
        const delegationsByRole = _.groupBy(delegations, 'role');
        this.clustersRead = delegationsByRole[CLUSTER_DELEGATION_USE] || [];
        this.clustersAdmin = delegationsByRole[CLUSTER_DELEGATION_ADMIN] || [];
        this.filterAvailableCluster();
      });

      if (this.organization.id) {
        await this.getOrganizationUsers(this.organization.id);
      }
    }
  }

  changePage(evt) {
    this.page = evt.page - 1;
    this.getOrganizationUsers(this.organization.id);
  }

  async getOrganizationUsers(id) {
    const res: any = await this.admin.getOrganizationUsers(id, this.page, this.size);
    this.organization.users = res.content;
    this.organization.users.map((user: any) => (user.role = this.translate.instant(this.users.rolesMap.find((r) => r.value == user.role).libelle)));
    this.total = res.totalElements;
  }

  createOrganization(organization: Organization) {
    if (this.fromAdmin && !Validators.validateEmail(organization?.owner?.email)) {
      return this.toastr.error(this.translate.instant('InvalidEmail'));
    }

    const { clusters, ...rest } = organization;

    this.http.post(this.getOrganizationUrl(), this.fromAdmin ? this.createFormatData(organization) : rest).subscribe({
      next: async () => {
        // reload organizations list
        await this.auth.loadOrganizations();
        this.back();
      },
      error: () => {
        this.toastr.error(this.translate.instant('OperationFailed'));
      },
    });
  }

  updateOrganization(organization: any) {
    if (this.fromAdmin && organization?.owner?.email) {
      organization.owner.email = organization.owner.email.trim();
      if (!Validators.validateEmail(organization.owner.email)) {
        return this.toastr.error(this.translate.instant('InvalidEmail'));
      }
    }

    this.http.put(this.getOrganizationUrl(organization.id), this.updateFormatData(organization)).subscribe({
      next: () => this.back(),
      error: () => this.toastr.error(this.translate.instant('OperationFailed')),
    });
  }

  filterAvailableCluster() {
    const clusterNames = this.clustersRead.concat(this.clustersAdmin).map((c) => c.nom);
    this.availableClusters = this.clusters.filter((c) => !clusterNames.includes(c.nom));
  }

  createFormatData(organization) {
    return {
      ...organization,
      clusters: this.clustersFormData(),
    };
  }

  updateFormatData(organization) {
    let { owner, ...rest } = organization;

    return {
      clusters: this.clustersFormData(),
      ownerEmail: owner.email,
      ...rest,
    };
  }

  clustersFormData() {
    return _.fromPairs(
      _.concat(
        this.clustersRead.map((c) => [c.clusterId, CLUSTER_DELEGATION_USE]),
        this.clustersAdmin.map((c) => [c.clusterId, CLUSTER_DELEGATION_ADMIN]),
      ),
    );
  }

  getOrganizationClustersDelegations(id): Observable<any[]> {
    return this.http.get<Page<any>>(this.getOrganizationUrl(id, '/cluster-delegations/received')).pipe(
      map((delegations) =>
        delegations.content.map((delegation) => ({
          id: delegation.cluster.id,
          clusterId: delegation.cluster.clusterId,
          nom: delegation.cluster.name,
          role: delegation.role,
        })),
      ),
    );
  }

  private getOrganizationUrl(organizationId = null, suffix: string = '') {
    let url = `${Global.baseUrl}${this.fromAdmin ? 'admin' : 'v2'}/organizations`;
    if (organizationId !== null) {
      url += `/${organizationId}${suffix}`;
    }
    return url;
  }

  getClusters(): Observable<any[]> {
    return this.http.post(Global.baseUrl + 'cluster/get', { data: {} }).pipe(map((res: any) => res.items));
  }

  getCountries() {
    this.http.get('/assets/data/countries_' + this.auth.getCurrentLanguage() + '.json').subscribe((res: any) => {
      this.countries = res.map((p: any) => ({
        countryNameEn: p.name,
        countryNameFr: p.name,
        countryCode: p.alpha2,
      }));
    });
  }

  back() {
    if (this.fromAdmin) {
      this.cancel.emit();
    } else {
      this.router.navigate([DASHBOARD_ROUTE]);
    }
  }

  canValidate() {
    return this.organization.companyName && (this.organization.id ? this.organization.owner.email : true);
  }

  isCreate(): boolean {
    return this.organization?.id === undefined;
  }

  isUpdate(): boolean {
    return this.organization?.id !== undefined;
  }
}
