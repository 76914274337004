import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextEnum, Site, SitesService } from 'app/services/sites.service';
import _ from 'lodash';
import { debounceTime, Subject } from 'rxjs';
import { copyObject } from '../utils/data-utils';

@Component({
  selector: 'app-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss'],
})
export class SiteSelectorComponent {
  @Input() ctx: ContextEnum;
  @Input() showTags = true;
  @Input() showSites = true;
  @Output() change = new EventEmitter<Object>();
  private debounceSubject: Subject<any> = new Subject();
  private previousSitesList: Site[] = [];

  constructor(
    public translate: TranslateService,
    public sites: SitesService,
  ) {
    this.debounceSubject.pipe(debounceTime(200)).subscribe(() => {
      this.change.emit();
    });
  }

  refreshSiteList(event) {
    this.sites.computeSiteList(this.ctx);
    this.hostChange(event);
  }

  hostChange(event) {
    if (this.previousSitesList.length && _.isEqual(this.previousSitesList, this.sites[this.ctx].current.selectedSites))
      return;
    this.previousSitesList = copyObject(this.sites[this.ctx].current.selectedSites);
    this.debounceSubject.next(event);
  }
}
