import { Validators } from 'app/shared/validators/validators';
import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { firstValueFrom, forkJoin, fromEvent, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CONTEXT, SitesService } from 'app/services/sites.service';
import { HostModeService } from 'app/shared/hostMode/hostMode.service';
import { ACL_SITE_ACTIONS, ACL_SITE_EDIT, AuthService, SHARE_ACCESS_RIGHT_ADMIN, SHARE_ACCESS_RIGHT_VIEW } from '../../services/auth.service';
import { Global } from '../../global';
import { EXTERNAL_HELP_ENGLISH_LINK, EXTERNAL_HELP_FRENCH_LINK } from 'app/app-routing.module';

@Component({
  selector: 'app-my-sites',
  templateUrl: './my-sites.component.html',
  styleUrls: ['./my-sites.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [style({ opacity: 0 }), animate('400ms ease-in-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ transform: 'translate(0)' }), animate('400ms ease-in-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class MySitesComponent implements OnInit, AfterViewInit {
  @ViewChild('AddSiteModal') addSiteModal: any;
  @ViewChild('addDomainModal') addDomainModal: any;
  @ViewChild('modalShare') modalShare: any;
  @ViewChild('tagInput') tagInput: any;
  @ViewChild('tagInputUrlExcept') tagInputUrlExcept: any;
  @ViewChild('modalDelete') modalDelete: any;
  @ViewChild('modalMultipleAuditPanic') modalMultipleAuditPanic: any;
  @ViewChild('modalMultipleShares') modalMultipleShares: any;
  @ViewChild('modalMultipleDeletes') modalMultipleDeletes: any;
  @ViewChild('modalMultipleCertificat') modalMultipleCertificat: any;
  @ViewChild('modalMultipleTags') modalMultipleTags: any;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('loadPkcs12') loadPkcs12: any;
  @ViewChild('addMultiShareInput') addMultiShareInput: NgSelectComponent;
  @ViewChild('addShareInput') addShareInput: NgSelectComponent;

  lang: string;

  adminClusterView: boolean;

  addSiteView = false;

  currentShares = [];
  shareInput = [];
  lastShareInput = '';
  shareTooltip = false;
  sharedRole = SHARE_ACCESS_RIGHT_VIEW;
  multipleSharedRole = SHARE_ACCESS_RIGHT_VIEW;

  hosts: any = [];
  user: any = {};
  clusters: any = [];
  selectedHosts: any = [];
  hostsList: any = [];
  tags = [];
  selectedTags = [];
  hoteSelected: any = {};
  cluster: any = {};

  certificat: any = [];
  p12File: any = null;
  p12Password = '';
  makeCertificateActive = true;

  index = 0;
  pageSize = 20;
  total = 0;

  sitesInRefresh = false;

  multipleConfirmDelete = false;

  sub: any;

  statutHotes: any = [];

  ACL_SITE_EDIT = ACL_SITE_EDIT;
  ACL_SITE_ACTIONS = ACL_SITE_ACTIONS;

  shareRoles = [SHARE_ACCESS_RIGHT_ADMIN, SHARE_ACCESS_RIGHT_VIEW];

  EXTERNAL_HELP_FRENCH_LINK = EXTERNAL_HELP_FRENCH_LINK;
  EXTERNAL_HELP_ENGLISH_LINK = EXTERNAL_HELP_ENGLISH_LINK;

  constructor(
    private http: HttpClient,
    public auth: AuthService,
    private toastr: ToastrService,
    private sites: SitesService,
    private translate: TranslateService,
    public hostModeService: HostModeService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state?.function == 'editHote') {
      this.addSiteView = true;
    }
  }

  ngOnInit() {
    this.user = this.auth.getUser();
    this.lang = this.auth.getCurrentLanguage();

    this.route.data.subscribe(async (v) => {
      this.adminClusterView = v.adminClusterView;
      if (this.adminClusterView && v.clusters) {
        this.clusters = v.clusters;
        this.cluster.clusterId = this.clusters[0].id;
      } else {
        if (!this.sites[CONTEXT].store.sitesList.length) {
          await this.sites.load(CONTEXT);
        }
        this.tags = this.sites[CONTEXT].store.tags;
        this.clusters = this.sites[CONTEXT].store.clusters;
      }
    });

    this.loadHosts();
    this.getHoteStatus();
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(200))
      .subscribe((evt: any) => {
        this.index = 0;
        this.loadHosts();
      });
  }

  search(value) {
    this.hosts = _.filter(this.hostsList, (p: any) => {
      const email = (p.utilisateurEmail || '').toLocaleLowerCase();
      const destHost = (p.destHost || '').toLocaleLowerCase();
      const nom = (p.nom || '').toLocaleLowerCase();
      const str = value.toLocaleLowerCase();
      return email.indexOf(str) > -1 || destHost.indexOf(str) > -1 || nom.indexOf(str) > -1;
    });
  }

  getHoteStatus() {
    this.http
      .post(Global.baseUrl + 'statutHote/get', {
        data: {},
      })
      .subscribe((res: any) => {
        this.statutHotes =
          res.items.map((p: any) => {
            p.libelle = this.lang === 'en' ? p.libelleEn : p.libelle;
            return p;
          }) || [];
      });
  }

  resetPagination() {
    this.index = 0;
    this.selectedHosts = [];
    this.loadHosts();
  }

  allUpdateHost(mode, value) {
    let observableBatch = [];

    this.selectedHosts.forEach((host) => {
      observableBatch.push(this.updateHost(host, mode, value, false));
    });

    forkJoin(observableBatch).subscribe((results: any) => {
      if (results.every((res) => ~res.hasError && res.items && res.items[0])) {
        this.toastr.success(this.translate.instant('ModifiedSitesSuccess'));

        this.selectedHosts.forEach((host) => {
          host[mode] = value;
        });
      } else {
        this.toastr.error(this.translate.instant('ImpossProv'));
      }
    });
  }

  allDelete() {
    forkJoin(this.selectedHosts.map((host) => this.deleteClient(host))).subscribe({
      next: (results) => {
        this.toastr.success(this.translate.instant('DomainsSupp'));
        this.modalMultipleDeletes.hide();
        this.resetPagination();
      },
      error: () => {
        this.toastr.error(this.translate.instant('Erreur'));
      },
    });
  }

  handleShareType(event) {
    this.lastShareInput = event.term;
  }

  handleShareBlur() {
    if (this.lastShareInput) {
      this.shareInput = [...this.shareInput, { label: this.lastShareInput }];
      this.lastShareInput = '';
    }
  }

  validateSharesInput(emails) {
    return emails.every((e) => Validators.validateEmail(e) || Validators.validateOrganizationCode(e));
  }

  closeMultipleShares() {
    this.modalMultipleShares.hide();
    this.shareInput = [];
  }

  updateHost(host: any, mode, value, callback) {
    let hostCopy = Object.assign({}, host);

    hostCopy[mode] = value ? value : !hostCopy[mode];

    const req = {
      datas: [
        {
          ...hostCopy,
        },
      ],
    };

    if (callback) {
      this.http.post(Global.baseUrl + 'hote/update', req).subscribe((res: any) => {
        if (!res.hasError && res.items && res.items[0]) {
          host[mode] = value ? value : !host[mode];
          this.toastr.success(this.translate.instant('SiteModifieSucc'));
        } else {
          this.toastr.error(this.translate.instant('ImpossProv'));
        }
      });
    } else {
      return this.http.post(Global.baseUrl + 'hote/update', req);
    }
  }

  getHoteByCluster(c: any) {
    let data = this.getSearchParameters(c);

    this.http.post(Global.baseUrl + 'hote/getByCluster', { index: this.index, size: this.pageSize, data }).subscribe((res: any) => {
      this.hosts = res.items || [];
      this.hostsList = res.items || [];
      this.total = res.count;
      this.retrieveCheckedHosts();
    });
  }

  getSearchParameters(cluster = null) {
    let data = {};
    let c = cluster || this.cluster;

    if (this.searchInput) {
      data['utilisateurEmail'] = this.searchInput.nativeElement.value;
      data['destHost'] = this.searchInput.nativeElement.value;
      data['nom'] = this.searchInput.nativeElement.value;
      data['utilisateurEmailParam'] = { operator: '%%' };
      data['destHostParam'] = { operator: '%%' };
      data['nomParam'] = { operator: '%%' };
    }

    if (c.clusterId !== 'undefined') {
      data['clusterId'] = c.clusterId;
    }

    if (c.statutHoteId !== 'undefined') {
      data['statutHoteId'] = c.statutHoteId;
    }

    if (c.tag && c.tag !== 'undefined') {
      data['tags'] = [{ tag: c.tag }];
    }

    return data;
  }

  changePage(evt: any) {
    this.index = evt - 1;
    this.loadHosts();
  }

  hoteAdded() {
    this.addSiteView = false;
    this.selectedHosts = [];
    this.refreshTags();
    this.loadHosts();
  }

  loadHosts() {
    if (this.adminClusterView) {
      this.addSiteView = false;
      this.getHoteByCluster(this.cluster);
    } else {
      let data = this.getSearchParameters();
      this.http
        .post(Global.baseUrl + 'hote/get', {
          index: this.index,
          size: this.pageSize,
          data: { isDeleted: false, ...data },
        })
        .subscribe((res: any) => {
          this.total = res.count;
          this.hosts = res.items || [];
          this.retrieveCheckedHosts();
        });
    }
  }

  retrieveCheckedHosts() {
    this.hosts.map((h) => {
      let found = this.selectedHosts.find((host) => h.nom == host.nom);
      if (found) {
        h.isSelected = true;
      }
    });
  }

  showSharedHosts(host: any) {
    if (!this.hostModeService.isAdvanced(host.mode)) {
      return this.hostModeService.promptSuscribeToMode('SuscribeToAdvanced');
    }

    this.hoteSelected = host;
    this.currentShares = host.shares;
    this.modalShare.show();
    setTimeout(() => this.addShareInput.focus(), 1);
  }

  toggleSelected(event, host) {
    if (event.target.checked) {
      host.isSelected = true;
      this.selectedHosts.push(host);
    } else {
      host.isSelected = false;
      this.selectedHosts.splice(
        this.selectedHosts.findIndex((h) => h.nom == host.nom),
        1,
      );
    }
  }

  allEnabled(mode, value) {
    return this.selectedHosts.every((h) => h[mode] == value);
  }

  showModalDelete(h: any) {
    this.modalDelete.show();
    this.hoteSelected = h;
  }

  showModalMultipleAuditPanic() {
    this.modalMultipleAuditPanic.show();
  }

  showModalMultipleShares() {
    this.modalMultipleShares.show();
    setTimeout(() => this.addMultiShareInput.focus(), 1);
  }

  canMultipleShare() {
    return !!this.selectedHosts.filter((h) => this.hostModeService.isAdvanced(h.mode) && h.accessRights.includes('SHARE')).length;
  }

  showModalMultipleDeletes() {
    this.modalMultipleDeletes.show();
  }

  editHote(h?: any) {
    this.addSiteView = true;
    this.hoteSelected = h || {};
  }

  onPasteShare(event) {
    const value = event.clipboardData.getData('text/plain');

    if (value != null && value.match(/[,;]/)) {
      event.preventDefault();
      const split = value.split(/[,;]/);
      split.forEach((s) => {
        if (s != '') {
          return (this.shareInput = [...this.shareInput, { label: s.trim() }]);
        }
      });
      event.target.value = '';
    }
  }

  async allCreateShare() {
    let emails = [...new Set(this.shareInput.map((e) => e.label.trim()))];

    if (!this.validateSharesInput(emails)) {
      return this.toastr.error(this.translate.instant('OneOfInvalidEmailOrCode'));
    }

    const sitesToShare = this.selectedHosts.filter((h) => this.hostModeService.isAdvanced(h.mode) && h.accessRights.includes('SHARE'));

    for (const site of sitesToShare) {
      for (const email of emails) {
        try {
          await this.postCreateShare(site.nom, email, this.multipleSharedRole);
        } catch (error) {
          return this.toastr.error(email + ' : ' + this.translate.instant(error?.error?.error || 'Erreur'));
        }
      }
      this.loadHosts();
      this.shareInput = [];
    }

    this.toastr.success(this.translate.instant('Les sites ont été partagés avec succès'));
  }

  async confirmCreateShare() {
    let emails = [...new Set(this.shareInput.map((e) => e.label.trim()))];

    if (!this.validateSharesInput(emails)) {
      return this.toastr.error(this.translate.instant('OneOfInvalidEmailOrCode'));
    }

    for (const email of emails) {
      let res: any = null;
      try {
        res = await this.postCreateShare(this.hoteSelected.nom, email, this.sharedRole);
      } catch (error) {
        return this.toastr.error(this.translate.instant(error?.error?.error || 'Erreur'));
      }
      this.currentShares.push({ organizationCompanyName: res.recipient.companyName, organizationCode: res.recipient.code, role: res.role });
    }
    this.shareInput = [];
    this.toastr.success(this.translate.instant('Les sites ont été partagés avec succès'));
  }

  async postCreateShare(siteName, recipient, role) {
    return await firstValueFrom(this.http.put(Global.baseUrl + `v2/organizations/${this.auth.currentOrganization.id}/sites/${siteName}/shares/${recipient}`, { role }));
  }

  async deleteShare(siteName, recipient, index) {
    await firstValueFrom(this.http.delete(Global.baseUrl + `v2/organizations/${this.auth.currentOrganization.id}/sites/${siteName}/shares/${recipient}`));
    this.loadHosts();
    this.currentShares.splice(index, 1);
    this.toastr.success(this.translate.instant('PartageSuppressionSucces'));
  }

  deleteHost() {
    this.deleteClient(this.hoteSelected).subscribe({
      next: (res) => {
        this.sites.purgeContext();
        this.loadHosts();
        this.modalDelete.hide();
        this.toastr.success(this.translate.instant('DomainSupp'));
      },
      error: (err) => this.toastr.error(this.translate.instant('ImpossibleSuppressionDomaine')),
    });
  }

  deleteClient(site): Observable<void> {
    return this.http.delete<void>(Global.baseUrl + `v2/organizations/${this.auth.currentOrganization.id}/sites/${site.nom}`);
  }

  async multipleRefreshStatus() {
    this.sitesInRefresh = true;

    let promises = this.selectedHosts.map((h) => firstValueFrom(this.refresh(h.id)));

    let res: any = await Promise.all(promises);
    // FIXME: res is an array and does not have a "hasError" property !!!
    if (!res.hasError) {
      this.toastr.success(this.translate.instant('StatusesUpdated'));
    } else {
      this.toastr.error(this.translate.instant('OperationFailed'));
    }

    this.loadHosts();
    this.sitesInRefresh = false;
  }

  refreshStatus(host) {
    host.isRefreshing = true;

    this.refresh(host.id)
      .pipe(
        map((res: any) => {
          if (res.hasError) {
            this.toastr.error(host.nom);
          }
        }),
      )
      .subscribe(() => {
        this.toastr.success(this.translate.instant('StatusUpdated'));
        this.loadHosts();
        host.isRefreshing = false;
      });
  }

  refresh(id) {
    return this.http.post(Global.baseUrl + 'hote/refreshStatus', {
      data: {
        id: id,
      },
    });
  }

  handlePkcs12(files: FileList) {
    const file = files.item(0) as File;
    this.p12File = file;
  }

  handleKeyEnterP12(event) {
    event.preventDefault();
    this.saveP12Certif();
  }

  saveP12Certif() {
    const file = this.p12File;

    let observableBatch = [];

    this.selectedHosts.forEach((host) => {
      const formData = new FormData();
      formData.append('passwordForP12File', this.p12Password);
      formData.append('fileP12ToUpload', file, file.name);
      formData.append('hoteId', host.id);
      formData.append('makeCertificateActive', this.makeCertificateActive ? 'true' : 'false');

      observableBatch.push(
        this.http.post(Global.baseUrl + 'certificat/uploadP12File', formData, {
          headers: { formData: 'true' },
          reportProgress: true,
          observe: 'events',
        }),
      );
    });

    forkJoin(observableBatch).subscribe((results: any) => {
      if (results.every((res) => res.body.hasError === false)) {
        this.toastr.success(this.translate.instant('CertificatsAjoutSucces'));
      } else {
        if (results.some((res) => res.body.status.message == 'WRONG_P12_PASSWORD')) {
          return this.toastr.error(this.translate.instant('WrongP12Password'));
        }
        if (results.some((res) => res.body.status.message == 'CERT_EXPIRED')) {
          return this.toastr.error(this.translate.instant('CertificatExpire'));
        }
        results
          .filter((res) => res.body.status.code == '999')
          .forEach((res) => {
            if (res.body.status.message.includes('NOT_SUITABLE')) {
              this.toastr.error(this.translate.instant('CertificateDoesNotFit') + res.body.status.message.split(':')[1].trim());
            }
          });
        results
          .filter((res) => res.body.hasError == false)
          .forEach((res) => {
            if (res.body.items[0]) {
              this.toastr.success(this.translate.instant('CertificatAjoutSucces') + ' : ' + res.body.items[0].hoteNom);
            }
          });
      }
      this.modalMultipleCertificat.hide();
      this.loadHosts();
      this.p12Password = '';
      this.loadPkcs12.nativeElement.value = '';
      this.p12File = null;
    });
  }

  isValidIpv6(string) {
    return string.match(
      /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/,
    );
  }

  pushTags(event) {
    this.selectedTags = event;
  }

  createTags() {
    this.sites.createTags(this.selectedHosts, this.selectedTags).then((res: any) => {
      if (!res.hasError) {
        this.toastr.success(this.translate.instant('OperationSuccess'));
        this.modalMultipleTags.hide();
        this.tags = [];
        this.sites.purgeContext();
        this.refreshTags();
        this.loadHosts();
      } else {
        this.toastr.error(this.translate.instant('OperationFailed'));
      }
    });
  }

  async refreshTags() {
    const newTags = await this.sites.loadTags();
    this.tags = _.uniq([...this.tags, ...newTags]);
  }

  selectAll() {
    let hosts = this.adminClusterView ? this.hosts : this.hosts.filter((h) => this.auth.canDoMoreThanView(h));
    hosts.forEach((h) => {
      h.isSelected = true;
      if (!this.selectedHosts.find((host) => host.nom == h.nom)) {
        this.selectedHosts.push(h);
      }
    });
  }

  toggleMode(id, previous, event) {
    this.http
      .post(Global.baseUrl + 'hote/update', {
        datas: [{ id, mode: event.target.value }],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.loadHosts();
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          event.target.value = previous;
          if (res.status.code == '938') {
            this.toastr.error(res.status.message);
          } else {
            this.toastr.error(this.translate.instant('OperationFailed'));
          }
        }
      });
  }
}
