import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { firstValueFrom, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { AuthService, CLUSTER_DELEGATION_ADMIN, CLUSTER_DELEGATION_USE } from '../../services/auth.service';
import { Global } from '../../../app/global';
import { Validators } from 'app/shared/validators/validators';

@Component({
  selector: 'app-delegation',
  templateUrl: './delegation.component.html',
  styleUrls: ['./delegation.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class DelegationComponent implements OnInit, OnDestroy, AfterViewInit {
  lang: string;
  @ViewChild('search') search: ElementRef;
  @ViewChild('modalAddDelegation') modalAddDelegation: any;

  isLoading: boolean = false;
  sub: any;
  share: any = {};
  cluster: any = {};
  clusters: any = [];
  clusterSharedWith: any = [];
  filter: any = { type: '' };

  index = 0;
  size = 10;
  total = 0;

  public roles = [CLUSTER_DELEGATION_USE, CLUSTER_DELEGATION_ADMIN];

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private toastr: ToastrService,
    private auth: AuthService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data: { clusters: any }) => {
      this.clusters = data.clusters;
      this.cluster = this.clusters[0];
      this.getDelegation();
    });
  }

  ngAfterViewInit(): void {
    fromEvent(document.querySelector('#search'), 'keyup')
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.index = 0;
        this.getDelegation();
      });
  }

  ngOnDestroy() {}

  changePage(evt: any) {
    this.index = evt - 1;
    this.getDelegation();
  }

  clusterSelected(cluster) {
    this.cluster = cluster;
    this.getDelegation();
  }

  async getDelegation() {
    this.isLoading = true;

    const params = this.getSearchParameters();
    const res: any = await firstValueFrom(this.http.get(Global.baseUrl + `v2/clusters/${this.cluster.clusterId}/delegations`, { params }));

    this.clusterSharedWith = res.content || [];
    this.total = res.totalElements || 0;

    this.isLoading = false;
  }

  getSearchParameters() {
    return new HttpParams()
      .set('companyName', this.search?.nativeElement?.value || '')
      .set('code', this.search?.nativeElement?.value || '')
      .set('role', this.filter?.type)
      .set('page', this.index)
      .set('size', this.size);
  }

  createDelegation(share) {
    if (!Validators.validateEmail(share.recipient) && !Validators.validateOrganizationCode(share.recipient)) {
      return this.toastr.error(this.translate.instant('OneOfInvalidEmailOrCode'));
    }

    this.http
      .put(Global.baseUrl + `v2/organizations/${this.auth.currentOrganization.id}/clusters/${this.cluster.clusterId}/delegations/${share.recipient}`, {
        role: share.role,
      })
      .subscribe(
        (res: any) => {
          this.share = {};
          this.toastr.success(this.translate.instant('EnregistrementReussi'));
          this.modalAddDelegation.hide();
          this.getDelegation();
        },
        (error: any) => {
          return this.toastr.error(this.translate.instant(error?.error?.error || 'Erreur'));
        },
      );
  }

  confirmUpdateDelegation(p: any) {
    let str = this.translate.instant(p.role == CLUSTER_DELEGATION_ADMIN ? 'ConfirmUpdateDelegation' : 'ConfirmDowngradeDelegation', {
      companyName: p.recipient.companyName,
      clusterName: this.cluster.nom,
    });
    Swal.fire({
      title: 'Confirmation',
      html: str,
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.updateDelegation(p);
      } else {
        this.getDelegation();
      }
    });
  }

  updateDelegation(p: any) {
    this.http
      .put(Global.baseUrl + `v2/organizations/${this.auth.currentOrganization.id}/clusters/${this.cluster.clusterId}/delegations/${p.recipient.code}`, {
        role: p.role,
      })
      .subscribe((res: any) => {
        this.toastr.success(this.translate.instant('EnregistrementReussi'));
        this.getDelegation();
      });
  }

  confirmDeleteDelegation(p: any) {
    Swal.fire({
      title: 'Confirmation',
      text: this.translate.instant('DeleteDelegation', { item: this.cluster.nom }),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('Confirm'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.deleteDelegation(p);
      }
    });
  }

  deleteDelegation(p: any) {
    this.http.delete(Global.baseUrl + `v2/organizations/${this.auth.currentOrganization.id}/clusters/${this.cluster.clusterId}/delegations/${p.recipient.code}`).subscribe((res: any) => {
      this.toastr.success(this.translate.instant('DelegationSupprimeeSucces'));
      this.getDelegation();
    });
  }
}
