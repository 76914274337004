<div class="app-modal-body">
  <div class="card p-3" style="overflow-x: auto">
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <h5>
            {{ 'Blocage de pays' | translate }}
          </h5>
          <p style="margin-top: 10px">{{ 'GeoblockTooltip' | translate }}</p>
          <hr />
          <div *ngIf="!hostModeService.isAdvanced(hote.mode)" class="bg-blue" style="margin-bottom: 10px; margin-top: -7px">{{ 'SubscribeToAdvanced' | translate }}</div>
          <div class="ml-4">
            <div class="row" style="margin-left: -20px; flex-wrap: no-wrap !important">
              <div class="col-12 blacklisted-table" style="min-width: 1000px">
                <div class="row no-gutters">
                  <div class="col-6 country-head">
                    <h6 class="text-success">{{ 'Pays autorisés' | translate }} ({{ countries.length }})</h6>
                  </div>
                  <div class="col-6 country-head" style="border-right: 1px solid #ccc">
                    <h6 class="text-danger">{{ 'Pays bloqués' | translate }} ({{ blockedCountries.length }})</h6>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-6 country-head">
                    <input #country class="form-control form-control-sm" placeholder="{{ 'Search' | translate }}..." type="text" />
                  </div>
                  <div class="col-6 country-head" style="border-right: 1px solid #ccc">
                    <input #blockCountry class="form-control form-control-sm" placeholder="{{ 'Search' | translate }}..." type="text" />
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-6 country-head">
                    <button (click)="blockAllCountries()" class="btn btn-mini btn-primary">
                      {{ 'AjouterTout' | translate }}
                    </button>
                  </div>
                  <div class="col-6 country-head" style="border-right: 1px solid #ccc">
                    <button (click)="enableAllCountries()" class="btn btn-mini btn-primary">
                      {{ 'SupprimerTout' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-6 country-block custom-scrollbar">
                    <div class="country-item" *ngFor="let p of countries; let i = index" [hidden]="p.hide" (click)="blockAvailableCountry(p, i)">
                      <span *ngIf="lang === 'fr'">{{ p.countryNameFr }}</span>
                      <span *ngIf="lang === 'en'">{{ p.countryNameEn }}</span>
                    </div>
                  </div>
                  <div class="col-6 country-block custom-scrollbar" style="border-right: 1px solid #ccc">
                    <div class="country-item block" *ngFor="let p of blockedCountries; let i = index" [hidden]="p.hide" (click)="enableBlockCountry(p, i)">
                      <span *ngIf="lang === 'fr'">{{ p.countryNameFr }}</span>
                      <span *ngIf="lang === 'en'">{{ p.countryNameEn }}</span>
                    </div>
                  </div>
                </div>
                <div class="pull-right">
                  <button
                    [disabled]="provisionningInProgress"
                    (click)="createBlacklistedCountries(hote)"
                    class="btn btn-sm btn-primary"
                    [innerHTML]="'valider' | translate"
                    style="margin-top: 10px"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
