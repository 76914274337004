import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TabComponent as Tab, TabComponent } from './tab.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tabs',
  styleUrls: ['../../../assets/icon/icofont/css/icofont.scss'],
  template: `
    <div class="tab-container" *ngIf="tabs.length">
      <ul class="nav settings-tabs" [hidden]="hide">
        <li *ngFor="let tab of tabs.toArray() | reverse" (click)="selectTab(tab)" [class.active]="tab.active">
          <a>{{ tab.title }}</a>
          <div *ngIf="tab.error" [ngbTooltip]="tab.errorTooltip" container="body" placement="bottom" style="display: inline">
            <i class="icon-warning icofont icofont-warning tab-warning"></i>
          </div>
          <label *ngIf="tab.beta" class="badge badge-sm bg-dark mx-2">{{ tab.betaTranslation }}</label>
        </li>
      </ul>
    </div>
    <ng-content></ng-content>
  `,
  styles: [
    `
      .tab-container {
        user-select: none;
        margin: 0;
        padding: 0;
        max-height: 35px;
      }

      ul.settings-tabs {
        margin: 0;
        list-style-type: none;
        line-height: 35px;
        max-height: 35px;
        overflow: hidden;
        display: inline-block;
        padding-right: 20px;
      }

      ul.settings-tabs > li.active {
        z-index: 2;
        background: white;
      }

      ul.settings-tabs > li:not(.active) > a {
        color: #a4a4a4;
      }

      ul.settings-tabs > li.active:before {
        border-color: transparent #efefef transparent transparent;
      }

      ul.settings-tabs > li.active:after {
        border-color: transparent transparent transparent #efefef;
      }

      ul.settings-tabs > li {
        float: left;
        margin: 5px -10px 0;
        border-top-right-radius: 25px 170px;
        border-top-left-radius: 20px 90px;
        padding: 0 30px 0 25px;
        height: 170px;
        background: #eee;
        position: relative;
        box-shadow: 0 1px 7px rgb(0 0 0 / 20%);
        max-width: 300px;
      }

      ul.settings-tabs > li:first-child {
        margin-left: 5px;
      }

      ul.settings-tabs > li > a {
        display: inline-block;
        max-width: 100%;
        text-overflow: ellipsis;
        text-decoration: none;
        color: #222;
      }

      ul.settings-tabs > li:before {
        border-color: transparent #ddd transparent transparent;
        -webkit-transform: rotate(48deg);
        left: -23px;
      }

      ul.settings-tabs > li:after {
        border-color: transparent transparent transparent #ddd;
        -webkit-transform: rotate(-48deg);
        right: -17px;
      }

      .tab-warning:before {
        top: 0px;
      }

      @media (max-width: 1120px) {
        .tab-container {
          max-height: 280px;
          margin-bottom: 10px;
        }

        ul.settings-tabs {
          max-height: 280px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .nav {
          flex-wrap: nowrap;
        }

        ul.settings-tabs > li {
          margin: 5px;
          height: 35px;
          max-width: none;
          box-shadow: none;
          background: none;
          border-radius: 3px;
        }

        ul.settings-tabs > li.active {
          z-index: 2;
          border: 1px solid #4099ff;
        }

        ul.settings-tabs > li:last-child {
          margin-left: 0;
        }
      }
    `,
  ],
})
export class TabsComponent implements AfterContentInit {
  @Input('hide') hide: boolean;

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  constructor(
    private router: Router,
    public translate: TranslateService,
  ) {}

  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    let activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: Tab) {
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => (tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;

    if (tab.queryStringNav) {
      this.addNavQueryString(tab);
    }
  }

  addNavQueryString(tab) {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = { tab: tab.queryStringNav };
    this.router.navigateByUrl(urlTree);
  }
}
