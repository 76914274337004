<div class="card">
  <div class="col-md-12">
    <div class="row" style="padding-top: 15px">
      <div class="col-md-12 mt-2 flex-align-items">
        <h4 class="mb-0">
          {{ 'LogExport' | translate }}
        </h4>
        <label style="position: relative; top: 4px" class="badge badge-sm bg-dark mx-2">{{ 'BETA' | translate }}</label>
        <label style="position: relative; top: 4px" class="badge badge-sm bg-dark"
          ><a style="color: inherit" target="_blank" [href]="lang == 'fr' ? EXTERNAL_HELP_FRENCH_LINK + '/export-de-log' : EXTERNAL_HELP_ENGLISH_LINK + '/log-export'">?</a></label
        >
      </div>
    </div>
    <hr />
    <div *ngIf="auth.currentOrganization.pushLog">
      <div class="flex-align-items">
        <h5>{{ 'Status' | translate }}</h5>
        <button (click)="getStats(auth.currentOrganization.id)" class="btn btn-primary btn-sm btn-reload ml-2" title="{{ 'Refresh' | translate }}">
          <i class="icon-reload"></i>
        </button>
      </div>
      <div class="mt-3">
        <div *ngIf="!stats && !isLoading && !statsError">{{ 'StatsWillAppearHere' | translate }}</div>
        <div *ngIf="statsError">{{ 'StatsNotReady' | translate }}</div>
        <span *ngIf="isLoading" class="text-placeholder cards"><span class="activity"></span></span>
        <div *ngIf="stats" class="stats flex-align-items">
          <div>
            {{ 'EventsSentCurrent' | translate }} : <span>{{ stats?.output_throughput?.current || '-' }}</span>
          </div>
          <div>
            {{ 'EventsSentInLastMinute' | translate }} : <span>{{ stats?.output_throughput?.last_1_minute || '-' }}</span>
          </div>
          <div>
            {{ 'EventsSentInLast15Minutes' | translate }} : <span>{{ stats?.output_throughput?.last_15_minutes || '-' }}</span>
          </div>
          <div>
            {{ 'EventsSentInLast1Hour' | translate }} : <span>{{ stats?.output_throughput?.last_1_hour || '-' }}</span>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <h5>{{ 'YourCurrentSetup' | translate }}</h5>
        <div class="log-export-type static">
          <div class="flex-align-items">
            <div class="flex-align-items" *ngIf="auth.currentOrganization.pushLog.type == TCP">
              <img [src]="'assets/images/' + TCP + '.png'" style="width: 25px" />
              <span> Logstash (TCP) </span>
            </div>
            <div class="flex-align-items" *ngIf="auth.currentOrganization.pushLog.type == SPLUNK">
              <img [src]="'assets/images/' + SPLUNK + '.png'" style="width: 48px" />
              <span> Splunk </span>
            </div>
            <div class="flex-align-items" *ngIf="auth.currentOrganization.pushLog.type == SEKOIA">
              <img [src]="'assets/images/' + SEKOIA + '.png'" style="width: 70px" />
              <span> Sekoia </span>
            </div>
            <div class="flex-align-items" *ngIf="auth.currentOrganization.pushLog.type == MICROSOFTSENTINEL">
              <img [src]="'assets/images/' + MICROSOFTSENTINEL + '.png'" style="width: 35px" />
              <span> Microsoft Sentinel </span>
            </div>
            <div class="flex-align-items" *ngIf="auth.currentOrganization.pushLog.type == MICROSOFTSENTINELV2">
              <img [src]="'assets/images/' + MICROSOFTSENTINEL + '.png'" style="width: 35px" />
              <span> Microsoft Sentinel v2 </span>
            </div>
            <div class="flex-align-items" *ngIf="auth.currentOrganization.pushLog.type == SYSLOG">
              <img [src]="'assets/images/' + SYSLOG + '.png'" style="width: 75px" />
              <span> Syslog (TCP) [QRadar, Cortex, Logpoint] </span>
            </div>
          </div>
          <json-viewer class="col-md-12" [json]="pushLogConf[auth.currentOrganization.pushLog.type]" [expanded]="true"></json-viewer>
        </div>
      </div>
      <p [innerHTML]="'EnableExportSiteYouOwn' | translate"></p>
      <div *ngIf="auth.currentOrganization.pushLog.type != SEKOIA" class="form-group bg-light p-2">{{ 'LogsOutgoingIps' | translate }} 37.59.251.192/29, 152.228.196.160/28</div>
      <div
        *ngIf="auth.currentOrganization.pushLog.type == SYSLOG || (auth.currentOrganization.pushLog.type == TCP && pushLogConf[auth.currentOrganization.pushLog.type].sslEnabled)"
        class="form-group bg-light p-2"
      >
        {{ 'TrustOgoCA' | translate }}.<br /><br />
        <a
          *ngIf="exportType == TCP || exportType == SYSLOG || (auth.currentOrganization.pushLog && (auth.currentOrganization.pushLog.type == TCP || auth.currentOrganization.pushLog.type == SYSLOG))"
          [href]="STATIC_LINK_OGO_CA_CERT"
          download
        >
          <button class="btn btn-primary" title="{{ 'Download' | translate }}">{{ 'Telecharger' | translate }} OGO CA Cert&nbsp;&nbsp;<i class="icofont icofont-download-alt"></i></button>
        </a>
        <br /><br />
        <a
          class="ogo-link"
          target="_blank"
          [href]="
            auth.currentOrganization.pushLog.type == SYSLOG
              ? lang == 'fr'
                ? EXTERNAL_HELP_FRENCH_LINK + '/configuration-d-export-syslog'
                : EXTERNAL_HELP_ENGLISH_LINK + '/syslog-ng-export-configuration'
              : lang == 'fr'
                ? EXTERNAL_HELP_FRENCH_LINK + '/configuration-d-export-tcp-logstash'
                : EXTERNAL_HELP_ENGLISH_LINK + '/tcp-logstash-export-configuration'
          "
          >{{ 'ExportGuide' | translate }} {{ auth.currentOrganization.pushLog.type | uppercase }}
          <i class="icofont icofont-external-link ml-1"></i>
        </a>
      </div>
      <hr />
      <button *ngIf="stepExportLog == 0" (click)="triggerDeletePushLogConf()" class="pull-right btn btn-sm btn-danger mb-3 ml-2">{{ 'supprimer' | translate }}</button>
      <button *ngIf="stepExportLog == 0" (click)="stepExportLog = 1" class="pull-right btn btn-sm btn-primary mb-3">{{ 'Modifier' | translate }}</button>
    </div>
    <div *ngIf="stepExportLog == Step.NOT_CONFIGURED" class="form-group" style="margin-bottom: 1.75em">
      <p>{{ 'ChooseAnExportType' | translate }}</p>
      <div class="row">
        <div class="col-md-4">
          <div class="log-export-type pointer flex-align-items" (click)="selectLogExportType(TCP)">
            <div class="log-export-image-container">
              <img [src]="'assets/images/' + TCP + '.png'" style="height: 35px; margin-left: 11px" />
            </div>
            <span> Logstash (TCP) </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="log-export-type pointer flex-align-items" (click)="selectLogExportType(SPLUNK)">
            <div class="log-export-image-container">
              <img [src]="'assets/images/' + SPLUNK + '.png'" style="width: 75px" />
            </div>
            <span> Splunk </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="log-export-type pointer flex-align-items" (click)="selectLogExportType(SEKOIA)">
            <div class="log-export-image-container">
              <img [src]="'assets/images/' + SEKOIA + '.png'" style="width: 75px" />
            </div>
            <span> Sekoia </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="log-export-type pointer flex-align-items" (click)="selectLogExportType(MICROSOFTSENTINEL)">
            <div class="log-export-image-container">
              <img [src]="'assets/images/' + MICROSOFTSENTINEL + '.png'" style="width: 50px" />
            </div>
            <span> Microsoft Sentinel </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="log-export-type pointer flex-align-items" (click)="selectLogExportType(MICROSOFTSENTINELV2)">
            <div class="log-export-image-container">
              <img [src]="'assets/images/' + MICROSOFTSENTINEL + '.png'" style="width: 50px" />
            </div>
            <span> Microsoft Sentinel V2 </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="log-export-type pointer flex-align-items" (click)="selectLogExportType(SYSLOG)">
            <div class="log-export-image-container">
              <img [src]="'assets/images/' + SYSLOG + '.png'" style="width: 75px" />
            </div>
            <span> Syslog (TCP) [QRadar, Cortex, Logpoint]</span>
          </div>
        </div>
      </div>
      <hr *ngIf="auth.currentOrganization.pushLog" />
      <button *ngIf="auth.currentOrganization.pushLog" (click)="stepExportLog = 0" class="pull-right btn btn-sm btn-danger mb-3 ml-2">{{ 'Annuler' | translate }}</button>
    </div>

    <div *ngIf="stepExportLog == Step.EDITING">
      <p>{{ 'PleaseFillFollowingInformations' | translate }}</p>
      <div class="col-md-4">
        <div class="log-export-type static">
          <div class="flex-align-items" style="margin: 5px">
            <div *ngIf="exportType == TCP">
              <img [src]="'assets/images/' + TCP + '.png'" style="width: 25px" />
              <span> Logstash (TCP) </span>
            </div>
            <div *ngIf="exportType == SPLUNK">
              <img [src]="'assets/images/' + SPLUNK + '.png'" style="width: 48px" />
              <span> Splunk </span>
            </div>
            <div *ngIf="exportType == SEKOIA">
              <img [src]="'assets/images/' + SEKOIA + '.png'" style="width: 70px" />
              <span> Sekoia </span>
            </div>
            <div *ngIf="exportType == MICROSOFTSENTINEL">
              <img [src]="'assets/images/' + MICROSOFTSENTINEL + '.png'" style="width: 35px" />
              <span> Microsoft Sentinel </span>
            </div>
            <div *ngIf="exportType == MICROSOFTSENTINELV2">
              <img [src]="'assets/images/' + MICROSOFTSENTINEL + '.png'" style="width: 35px" />
              <span> Microsoft Sentinel V2 </span>
            </div>
            <div *ngIf="exportType == SYSLOG">
              <img [src]="'assets/images/' + SYSLOG + '.png'" style="width: 50px" />
              <span> Syslog (TCP) [QRadar, Cortex, Logpoint]</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="exportType == TCP" class="col-md-4">
        <div class="form-group">
          <label>TCP Host</label>
          <input [(ngModel)]="pushLogConf.tcp.host" class="form-control" type="text" placeholder="logs.example.com" />
        </div>
        <div class="form-group">
          <label>TCP Port</label>
          <input [(ngModel)]="pushLogConf.tcp.port" class="form-control" type="text" placeholder="12345" />
        </div>
        <div class="form-group mt-4" style="margin-left: 3px">
          <input type="checkbox" [(ngModel)]="pushLogConf.tcp.sslEnabled" />
          <label class="ml-2">TLS</label>
        </div>
        <ng-container *ngTemplateOutlet="outgoingIps"></ng-container>
        <div class="form-group bg-light p-2" *ngIf="pushLogConf.tcp.sslEnabled">
          {{ 'TrustOgoCA' | translate }}. <br /><br />
          <a
            *ngIf="exportType == TCP || exportType == SYSLOG || (auth.currentOrganization.pushLog && (auth.currentOrganization.pushLog.type == TCP || auth.currentOrganization.pushLog.type == SYSLOG))"
            [href]="STATIC_LINK_OGO_CA_CERT"
            download
          >
            <button class="btn btn-primary" title="{{ 'Download' | translate }}">{{ 'Telecharger' | translate }} OGO CA Cert&nbsp;&nbsp;<i class="icofont icofont-download-alt"></i></button>
          </a>
          <br /><br />
          <a
            class="ogo-link"
            target="_blank"
            [href]="
              exportType == SYSLOG
                ? lang == 'fr'
                  ? EXTERNAL_HELP_FRENCH_LINK + '/configuration-d-export-syslog'
                  : EXTERNAL_HELP_ENGLISH_LINK + '/syslog-ng-export-configuration'
                : lang == 'fr'
                  ? EXTERNAL_HELP_FRENCH_LINK + '/configuration-d-export-tcp-logstash'
                  : EXTERNAL_HELP_ENGLISH_LINK + '/tcp-logstash-export-configuration'
            "
            >{{ 'ExportGuide' | translate }} {{ exportType | uppercase }}
            <i class="icofont icofont-external-link ml-1"></i>
          </a>
        </div>
      </div>

      <div *ngIf="exportType == SPLUNK" class="col-md-4">
        <div class="form-group">
          <label>Splunk URL</label>
          <input class="form-control" type="text" [(ngModel)]="pushLogConf.splunk.url" placeholder="<protocol>://<host>.splunkcloud.com:<port>/<endpoint>/raw" />
        </div>
        <div class="form-group">
          <label>Splunk HEC Token</label>
          <input class="form-control" type="text" [(ngModel)]="pushLogConf.splunk.hecToken" placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX" />
        </div>
        <div class="form-group mt-4 ml-1">
          <input type="checkbox" [ngModel]="pushLogConf.splunk.sslVerificationMode == 'full' ? true : false" (ngModelChange)="pushLogConf.splunk.sslVerificationMode = $event ? 'full' : 'none'" />
          <label class="ml-2"> &nbsp;{{ 'VerifyServerCertificate' | translate }} </label>
        </div>
        <ng-container *ngTemplateOutlet="outgoingIps"></ng-container>
      </div>

      <div *ngIf="exportType == SEKOIA" class="col-md-4">
        <div class="form-group">
          <label>Sekoia INTAKE_KEY</label>
          <input [(ngModel)]="pushLogConf.sekoia.intakeKey" class="form-control" type="text" />
        </div>
      </div>

      <div *ngIf="exportType == MICROSOFTSENTINEL" class="col-md-4">
        <div class="form-group">
          <label>Sentinel Workspace ID</label>
          <input [(ngModel)]="pushLogConf.microsoftSentinel.workspaceId" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label>Sentinel Workspace Key</label>
          <input [(ngModel)]="pushLogConf.microsoftSentinel.workspaceKey" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label>Sentinel Custom Log Table Name</label>
          <input [(ngModel)]="pushLogConf.microsoftSentinel.customLogTableName" class="form-control" type="text" />
        </div>
        <ng-container *ngTemplateOutlet="outgoingIps"></ng-container>
      </div>

      <div *ngIf="exportType == MICROSOFTSENTINELV2" class="col-md-4">
        <div class="form-group">
          <label>Client App ID</label>
          <input [(ngModel)]="pushLogConf.microsoftSentinelv2.clientAppId" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label>Client App Secret</label>
          <input [(ngModel)]="pushLogConf.microsoftSentinelv2.clientAppSecret" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label>Tenant ID</label>
          <input [(ngModel)]="pushLogConf.microsoftSentinelv2.tenantId" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label>Data Collection Endpoint</label>
          <input [(ngModel)]="pushLogConf.microsoftSentinelv2.dataCollectionEndpoint" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label>DCR Immutable ID</label>
          <input [(ngModel)]="pushLogConf.microsoftSentinelv2.dcrImmutableId" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label>DCR Stream Name</label>
          <input [(ngModel)]="pushLogConf.microsoftSentinelv2.dcrStreamName" class="form-control" type="text" />
        </div>
        <ng-container *ngTemplateOutlet="outgoingIps"></ng-container>
        <div class="form-group bg-light p-2">
          {{ 'LogExampleDesc' | translate }}<br /><br />
          <a [href]="STATIC_LINK_OGO_LOG_EXAMPLE" download>
            <button class="btn btn-primary" title="{{ 'Download' | translate }}">
              {{ 'Telecharger' | translate }} {{ 'LogExample' | translate }} Microsoft Sentinel V2&nbsp;&nbsp;<i class="icofont icofont-download-alt"></i>
            </button> </a
          ><br /><br />
          <a
            class="ogo-link"
            target="_blank"
            [href]="lang == 'fr' ? EXTERNAL_HELP_FRENCH_LINK + '/configuration-dexport-sentinel-v2' : EXTERNAL_HELP_ENGLISH_LINK + '/export-guide-for-microsoft-sentinel-v2'"
            >{{ 'ExportGuide' | translate }} Microsoft Sentinel V2
            <i class="icofont icofont-external-link ml-1"></i>
          </a>
        </div>
      </div>

      <div *ngIf="exportType == SYSLOG" class="col-md-4">
        <div class="form-group">
          <label
            >SYSLOG APP-NAME&nbsp;
            <label class="badge badge-sm bg-dark" placement="top"><a style="color: inherit" target="_blank" [href]="EXTERNAL_RFC_5424_SYSLOG_LINK">?</a></label>
          </label>
          <input [(ngModel)]="pushLogConf.syslog.appName" class="form-control" type="text" placeholder="OGO-LOGS" />
        </div>
        <div class="form-group">
          <label>SYSLOG Host</label>
          <input [(ngModel)]="pushLogConf.syslog.host" class="form-control" type="text" placeholder="logs.example.com" />
        </div>
        <div class="form-group">
          <label>SYSLOG Port</label>
          <input [(ngModel)]="pushLogConf.syslog.port" class="form-control" type="text" placeholder="12345" />
        </div>
        <div class="form-group mt-4" style="margin-left: 3px">
          <input type="checkbox" [(ngModel)]="pushLogConf.syslog.sslEnabled" />
          <label class="ml-2">TLS</label>
        </div>
        <ng-container *ngTemplateOutlet="outgoingIps"></ng-container>
        <div class="form-group bg-light p-2" *ngIf="pushLogConf.syslog.sslEnabled">
          {{ 'TrustOgoCA' | translate }}.<br /><br />
          <a
            *ngIf="exportType == TCP || exportType == SYSLOG || (auth.currentOrganization.pushLog && (auth.currentOrganization.pushLog.type == TCP || auth.currentOrganization.pushLog.type == SYSLOG))"
            [href]="STATIC_LINK_OGO_CA_CERT"
            download
          >
            <button class="btn btn-primary" title="{{ 'Download' | translate }}">{{ 'Telecharger' | translate }} OGO CA Cert&nbsp;&nbsp;<i class="icofont icofont-download-alt"></i></button> </a
          ><br /><br />
          <a
            class="ogo-link"
            target="_blank"
            [href]="
              exportType == SYSLOG
                ? lang == 'fr'
                  ? EXTERNAL_HELP_FRENCH_LINK + '/configuration-d-export-syslog'
                  : EXTERNAL_HELP_ENGLISH_LINK + '/syslog-ng-export-configuration'
                : lang == 'fr'
                  ? EXTERNAL_HELP_FRENCH_LINK + '/configuration-d-export-tcp-logstash'
                  : EXTERNAL_HELP_ENGLISH_LINK + '/tcp-logstash-export-configuration'
            "
            >{{ 'ExportGuide' | translate }} {{ exportType | uppercase }}
            <i class="icofont icofont-external-link ml-1"></i>
          </a>
        </div>
      </div>

      <hr />
      <button (click)="stepExportLog = Step.NOT_CONFIGURED; exportType = undefined" class="pull-right btn btn-sm btn-danger ml-2">{{ 'Annuler' | translate }}</button>
      <button *ngIf="stepExportLog == Step.EDITING" (click)="auth.currentOrganization.pushLog ? triggerModifyPushLogConf() : saveUpdatePushLogConf()" class="pull-right btn btn-sm btn-primary mb-3">
        {{ 'Validate' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #outgoingIps>
  <div class="form-group bg-light p-2">{{ 'LogsOutgoingIps' | translate }} 37.59.251.192/29, 152.228.196.160/28</div>
</ng-template>
