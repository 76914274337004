import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSiteComponent } from './add-site.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExpertComponent } from './expert/expert.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExceptionsComponent } from './exceptions/exceptions.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { GeoblockComponent } from './geoblock/geoblock.component';
import { AccessControlRulesComponent } from './access-control-rules/access-control-rules.component';
import { RewriteRulesComponent } from './rewrite-rules/rewrite-rules.component';
import { CacheComponent } from './cache/cache.component';
import { CallbackPipe } from 'app/shared/pipe/filter.pipe';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, DragDropModule, NgSelectModule],
  providers: [],
  exports: [AddSiteComponent, CertificatesComponent, ExceptionsComponent, GeoblockComponent, AccessControlRulesComponent, RewriteRulesComponent, ExpertComponent, CacheComponent],
  declarations: [AddSiteComponent, CertificatesComponent, ExceptionsComponent, GeoblockComponent, AccessControlRulesComponent, RewriteRulesComponent, ExpertComponent, CacheComponent, CallbackPipe],
})
export class AddSiteModule {}
