import { Validators } from 'app/shared/validators/validators';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from '../../../services/auth.service';
import { Global } from '../../../global';

@Component({
  selector: 'app-my-parameters',
  templateUrl: './my-parameters.component.html',
  styleUrls: ['./my-parameters.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class MyParametersComponent implements OnInit {
  lang: string;

  @ViewChild('profileForm') public editProfileForm: NgForm;

  user: any = {};
  pswd: any = {};
  editField: boolean;
  editPassword: boolean;
  hasApiKey = false;
  apiKey = '';

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngOnInit() {
    this.getUser();
    this.editField = false;
  }

  ngOnDestroy(): void {
    delete this.apiKey;
  }

  onEditField = () => {
    this.editField = true;
  };

  onEditPassword = () => {
    this.editPassword = true;
  };

  cancelEditPassword = () => {
    this.editPassword = false;
  };

  updateUser = () => {
    const data = { ...this.user };
    data.mfa = this.user.emailAuthentication ? 'email' : 'none';
    delete data.emailAuthentication;

    this.http.put(Global.baseUrl + 'v2/my-profile', data).subscribe({
      next: (res) => {
        if (data.lang != this.auth.getCurrentLanguage()) {
          return window.location.reload();
        } else {
          this.getUser();
          this.toastr.success(this.translate.instant('SuccfChang'));
          this.editField = false;
        }
      },
      error: (err) => {
        this.toastr.error(this.translate.instant('VeuillezRenseignerTousLesChamps'));
      },
    });
  };

  cancelEditUser = () => {
    this.getUser();
    this.editField = false;
  };

  resetPassword() {
    if (!Validators.validatePasswordRestrictions(this.pswd.password)) {
      this.toastr.error(this.translate.instant('MotDePasseFort'));
      return;
    }
    if (this.pswd.password !== this.pswd.password2) {
      this.toastr.error(this.translate.instant('MotDePasseNeCorrespondentPas'));
      return;
    }
    const request = {
      data: {
        email: this.user.email,
        password: this.pswd.ancienPassword,
        newPassword: this.pswd.password,
      },
    };
    this.http.post(Global.baseUrl + 'utilisateur/resetPassword', request).subscribe((res: any) => {
      if (!res.hasError) {
        this.pswd = {};
        const str = this.auth.getCurrentLanguage() === 'fr' ? 'Mot de passe modifié avec succès' : 'Password succesfully changed';
        this.toastr.success(str);
        this.editPassword = false;
      } else {
        const errorMsg = res.status.message || this.translate.instant('ErreurSurvenue');
        this.toastr.error(errorMsg, this.translate.instant('Erreur'));
      }
    });
  }

  getUser() {
    const user = this.http.get<any>(Global.baseUrl + 'v2/my-profile').subscribe((user) => {
      this.user = {
        email: user.email,
        lastName: user.lastName,
        firstName: user.firstName,
        emailAuthentication: user.mfa == 'email',
        lang: user.lang,
      };
      this.hasApiKey = user.hasApiKey;
      this.apiKey = user.hasApiKey ? '●●●●●●●' : '';
      this.auth.updateUser({
        ...user,
        nom: user.lastName,
        prenoms: user.firstName,
      });
    });
  }

  showKey() {
    this.auth.getApiKey().then((res: any) => {
      this.apiKey = res.item;
    });
  }
}
