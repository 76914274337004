<div class="block-site-selector" style="margin: 10px 10px 10px 0">
  <label>{{ translate.instant('Cluster') }} </label>
  <ng-select
    id="cluster"
    [notFoundText]="translate.instant('NoClusterFound')"
    class="selector select-site-selector"
    [addTag]="false"
    [(ngModel)]="sites[ctx].current.cluster"
    bindLabel="name"
    [clearable]="false"
    [items]="sites[ctx].store.clusters"
    [closeOnSelect]="true"
    [searchable]="true"
    (change)="refreshSiteList($event)"
  ></ng-select>
</div>
<div class="block-site-selector" *ngIf="showTags">
  <label>Tag</label>
  <ng-select
    [notFoundText]="translate.instant('NoTag')"
    class="selector select-site-selector"
    [addTag]="false"
    [(ngModel)]="sites[ctx].current.tag"
    [clearable]="true"
    [items]="sites[ctx].store.tags"
    [closeOnSelect]="true"
    [searchable]="true"
    (change)="refreshSiteList($event)"
    [placeholder]="translate.instant('All')"
  ></ng-select>
</div>
<div class="block-site-selector" style="flex-grow: 1" *ngIf="showSites">
  <label>{{ translate.instant('site') }}</label>
  <ng-select
    id="sites"
    [notFoundText]="translate.instant('NoHostFound')"
    class="selector custom-scrollbar select-site-selector"
    bindLabel="name"
    [addTag]="false"
    [(ngModel)]="sites[ctx].current.selectedSites"
    [clearable]="true"
    [items]="sites[ctx].store.sites"
    [multiple]="true"
    [searchable]="true"
    [closeOnSelect]="false"
    (close)="hostChange($event)"
    (clear)="hostChange($event)"
    (remove)="hostChange($event)"
    [placeholder]="translate.instant('All')"
  >
    <ng-template ng-option-tmp let-item="item">
      {{ item.name }}
      <small *ngIf="item.dryRun === true" class="label pull-right mr-2" style="background-color: #333">{{
        translate.instant('ModeAudit')
      }}</small>
      <small *ngIf="item.panicMode === true" class="label pull-right mr-2" style="background-color: #333">{{
        translate.instant('Mode Panique')
      }}</small>
    </ng-template>
  </ng-select>
</div>
