import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import 'daterangepicker';
import $ from 'jquery';
import moment, { Moment } from 'moment';
import { AuthService } from 'app/services/auth.service';
import {
  CalendarDataService,
  DATE_NOW,
  DATE_ALL,
  DATE_LAST_15_MINUTES,
  DATE_LAST_30_DAYS,
  DATE_LAST_4_HOURS,
  DATE_LAST_7_DAYS,
  DATE_LAST_90_DAYS,
  DATE_LAST_HOUR,
  DATE_TODAY,
  DATE_YESTERDAY_END,
  DATE_YESTERDAY_START,
} from './calendar-data.service';
import { ContextEnum, SitesService } from 'app/services/sites.service';
import { EPOCH_MILLI_4_HOURS } from '../utils/data-utils';

@Directive({
  selector: '[app-date-range-selector]',
})
export class DateRangeSelectorDirective implements OnInit, AfterViewInit, OnChanges {
  public lang: string;

  @Output() period = new EventEmitter<{ start: Moment; end: Moment; recomputeEndDateToNow: boolean }>();
  @Input() alwaysShowCalendars = true;
  @Input() detectChanges = true;
  @Input() defaultRange: string;
  @Input() timePicker: boolean;
  @Input() ranges = ['T', 'Y', '7', '30', '60', '90'];
  @Input() minDateDays;
  @Input() start: Moment;
  @Input() end: Moment;
  @Input() ctx: ContextEnum;

  calendarId: string;

  constructor(
    private auth: AuthService,
    private calendarData: CalendarDataService,
    private el: ElementRef,
    private sites: SitesService,
  ) {}

  ngOnInit() {
    this.lang = this.auth.getCurrentLanguage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.detectChanges) {
      if (changes.start) this.start = changes?.start?.currentValue;
      if (changes.end) this.end = changes?.end?.currentValue;
      this.analyzeDate({ startDate: this.start, endDate: this.end }, null);
    }
  }

  ngAfterViewInit(): void {
    this.calendarId = '#' + this.el.nativeElement.id;
    const element: any = $(this.calendarId);

    if (this.sites[this?.ctx]?.current?.period) {
      this.sites[this.ctx].refreshPeriod();
    }

    this.start = moment(this.start);
    this.end = moment(this.end);

    let options = {
      timePicker: this.timePicker,
      timePicker24Hour: true,
      startDate: this.start.locale(this.lang),
      endDate: this.end.locale(this.lang),
      opens: 'left',
      maxDate: DATE_NOW(),
      showDropdowns: true,
      alwaysShowCalendars: this.alwaysShowCalendars,
      ranges: this.calendarData.getRangesTranslations(this.ranges, this.lang),
      locale: this.lang === 'fr' ? this.calendarData.frenchLocal : this.calendarData.englishLocal,
      showCustomRangeLabel: false,
    };

    if (this.minDateDays) {
      options['minDate'] = DATE_NOW().subtract(this.minDateDays, 'days');
    }

    element.daterangepicker(options);

    this.analyzeDate({ startDate: this.start, endDate: this.end }, null);

    $(this.calendarId).on('apply.daterangepicker', (_: any, picker: any) => {
      this.start = picker.startDate;
      this.end = this.doesPrechoiceMustEndNow(picker) ? DATE_NOW() : picker.endDate;
      this.handleChange(this.start, this.end, picker?.chosenLabel);
    });
  }

  doesPrechoiceMustEndNow(picker) {
    return !!picker?.chosenLabel && picker?.chosenLabel != 'Yesterday' && picker?.chosenLabel != 'Hier';
  }

  handleChange(start, end, chosenLabel) {
    const recomputeEndDateToNow = this.analyzeDate({ startDate: start, endDate: end }, chosenLabel);

    this.period.emit({
      start: this.start,
      end: this.end,
      recomputeEndDateToNow,
    });
  }

  sameMinute(d1, d2) {
    return Math.abs(moment(d1).diff(d2, 'minutes')) <= 1;
  }

  analyzeDate(picker, chosenLabel) {
    let dateToPrint = null;
    let recomputeEndDateToNow = true;

    if (chosenLabel) {
      if (chosenLabel == '15 dernières minutes' || chosenLabel == 'Last 15 minutes') {
        this.start = DATE_LAST_15_MINUTES();
        this.end = DATE_NOW();
      } else if (chosenLabel == 'Dernière heure' || chosenLabel == 'Last hour') {
        this.start = DATE_LAST_HOUR();
        this.end = DATE_NOW();
      } else if (chosenLabel == '4 dernières heures' || chosenLabel == 'Last 4 hours') {
        this.start = DATE_LAST_4_HOURS();
        this.end = DATE_NOW();
      } else if (chosenLabel == "Aujourd'hui" || chosenLabel == 'Today') {
        this.start = DATE_TODAY();
        this.end = DATE_NOW();
      } else if (chosenLabel == 'Hier' || chosenLabel == 'Yesterday') {
        this.start = DATE_YESTERDAY_START();
        this.end = DATE_YESTERDAY_END();
      } else if (chosenLabel == 'Les 7 derniers jours' || chosenLabel == 'Last 7 days') {
        this.start = DATE_LAST_7_DAYS();
        this.end = DATE_NOW();
      } else if (chosenLabel == 'Les 30 derniers jours' || chosenLabel == 'Last 30 days') {
        this.start = DATE_LAST_30_DAYS();
        this.end = DATE_NOW();
      } else if (chosenLabel == 'Les 90 derniers jours' || chosenLabel == 'Last 90 days') {
        this.start = DATE_LAST_90_DAYS();
        this.end = DATE_NOW();
      } else if (chosenLabel == 'Tout' || chosenLabel == 'All') {
        this.start = DATE_ALL();
        this.end = DATE_NOW();
      }
    } else {
      this.start = picker.startDate;
      this.end = picker.endDate;
    }

    if (this.sameMinute(this.end, DATE_NOW()) && this.sameMinute(this.start, DATE_LAST_15_MINUTES())) {
      dateToPrint = this.lang == 'fr' ? '15 dernières minutes' : 'Last 15 minutes';
    } else if (this.sameMinute(this.end, DATE_NOW()) && this.sameMinute(this.start, DATE_LAST_HOUR())) {
      dateToPrint = this.lang == 'fr' ? 'Dernière heure' : 'Last hour';
    } else if (this.sameMinute(this.end, DATE_NOW()) && this.sameMinute(this.start, DATE_LAST_4_HOURS())) {
      dateToPrint = this.lang == 'fr' ? '4 dernières heures' : 'Last 4 hours';
    } else if (this.sameMinute(this.end, DATE_NOW()) && this.sameMinute(this.start, DATE_TODAY())) {
      dateToPrint = this.lang == 'fr' ? "Aujourd'hui" : 'Today';
    } else if (this.sameMinute(this.end, DATE_YESTERDAY_END()) && this.sameMinute(this.start, DATE_YESTERDAY_START())) {
      dateToPrint = this.lang == 'fr' ? 'Hier' : 'Yesterday';
      recomputeEndDateToNow = false;
    } else if (this.sameMinute(this.end, DATE_NOW()) && this.sameMinute(this.start, DATE_LAST_7_DAYS())) {
      dateToPrint = this.lang == 'fr' ? 'Les 7 derniers jours' : 'Last 7 days';
    } else if (this.sameMinute(this.end, DATE_NOW()) && this.sameMinute(this.start, DATE_LAST_30_DAYS())) {
      dateToPrint = this.lang == 'fr' ? 'Les 30 derniers jours' : 'Last 30 days';
    } else if (this.sameMinute(this.end, DATE_NOW()) && this.sameMinute(this.start, DATE_LAST_90_DAYS())) {
      dateToPrint = this.lang == 'fr' ? 'Les 90 derniers jours' : 'Last 90 days';
    } else if (this.sameMinute(this.end, DATE_NOW()) && this.sameMinute(this.start, DATE_ALL())) {
      dateToPrint = this.lang == 'fr' ? 'Tout' : 'All';
    } else {
      // specific interval
      recomputeEndDateToNow = false;
    }

    if (this.sites[this?.ctx]?.current?.period) {
      this.sites[this.ctx].current.period.autoRefresh = recomputeEndDateToNow && moment(this.end).diff(this.start) <= EPOCH_MILLI_4_HOURS + 1;
      this.sites[this.ctx].current.period.recomputeEndDateToNow = recomputeEndDateToNow;
    }

    const unionWord = this.lang === 'fr' ? 'au' : 'to';

    if (dateToPrint) {
      $(this.calendarId + ' span').html(dateToPrint);
    } else {
      $(this.calendarId + ' span').html(this.start.format('DD MMM YYYY HH:mm ') + unionWord + this.end.format(' DD MMM YYYY HH:mm') + '&nbsp;');
    }

    return recomputeEndDateToNow;
  }
}
