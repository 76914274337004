<ngx-datatable
  [messages]="{ emptyMessage: translate.instant('NoData') }"
  #myTable
  class="data-table table-responsive task-list-table"
  [limit]="print ? undefined : 10"
  [columnMode]="'flex'"
  [rows]="topBlockedIps"
  [headerHeight]="'auto'"
  [footerHeight]="50"
>
  <ngx-datatable-column [flexGrow]="4" cellClass="ip" headerClass="ip">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'IP' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div [attr.title]="countries.getCountryName(row.countryCode)" class="flex-ip-flag">
        @if (!print) {
          <span
            (click)="!print && trackCountry.emit(row.countryCode)"
            class="pointer fi fi-{{ row.countryCode.toLowerCase() }}"
          ></span>
        } @else {
          <span class="country-code">{{ row.countryCode }}</span>
        }
        <a class="clickable client-ip" (click)="!print && trackIp.emit(row.ip)">{{ row.ip }}</a>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="7" cellClass="site" headerClass="site">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'Site' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.site }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="3" cellClass="number blocked-by-ai" headerClass="number blocked-by-ai">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" style="color: #ff5370" [innerHtml]="'BlockedByAI' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.blockedByBrain | numberFormat }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="3" cellClass="number geoblocked" headerClass="number geoblocked">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" style="color: #ff5370" [innerHtml]="'Geoblocked' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.geoblocked | numberFormat }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="3" cellClass="number blocked-by-rule" headerClass="number blocked-by-rule">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" style="color: #ff5370" [innerHtml]="'BlockedByRule' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.blockedByRule | numberFormat }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="3" cellClass="number suspect" headerClass="number suspect">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" style="color: #f7a35c" [innerHtml]="'suspiciouses' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.suspicious | numberFormat }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="3" cellClass="number total" headerClass="number total">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'RequestsTotal' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span>{{ row.total | numberFormat }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column *ngIf="!print" [flexGrow]="4">
    <ng-template let-column="column" ngx-datatable-header-template>
      <p class="dataheader" [innerHtml]="'Actions' | translate"></p>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a
        class="ogo-link"
        (click)="row.canWhitelistIp && whitelistIp.emit({ ip: row.ip, site: row.site })"
        [ngStyle]="row.canWhitelistIp ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.4' }"
        >{{ 'PutThisIPInWhitelist' | translate }}</a
      >
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-footer>
    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-curPage="curPage" let-pageSize="pageSize">
      <datatable-pager
        [pagerLeftArrowIcon]="'datatable-icon-left'"
        [pagerRightArrowIcon]="'datatable-icon-right'"
        [pagerPreviousIcon]="'datatable-icon-prev'"
        [pagerNextIcon]="'datatable-icon-skip'"
        [page]="curPage"
        [size]="pageSize"
        [count]="rowCount"
        (change)="myTable.onFooterPage($event)"
      />
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>
