import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionAnchorDirective } from './accordion/accordionanchor.directive';
import { AccordionLinkDirective } from './accordion/accordionlink.directive';
import { AccordionDirective } from './accordion/accordion.directive';
import { CardComponent } from './card/card.component';
import { CardToggleDirective } from './card/card-toggle.directive';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DonutComponent } from './highcharts/donut/donut.component';
import { GaugeDirective } from './highcharts/gauge/gauge.directive';
import { GraphComponent } from './highcharts/graph/graph.component';
import { FormsModule } from '@angular/forms';
import { DateFormatPipe, DurationPipe, DurationSecondsPipe, NumberFormatPipe } from './pipe/filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { HttpLoaderComponent } from './http-loader/http-loader.component';
import { AccessDirective } from './access/access.directive';
import { ReversePipe } from './utils/reverse.directive';
import { TabsComponent } from './tabs/tabs.component';
import { TabComponent } from './tabs/tab.component';
import { TagsComponent } from './tags/tags.component';
import { SiteSelectorComponent } from './site-selector/site-selector.component';
import { JsonViewer } from './json-viewer/json-viewer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FeatureFlagDirective } from './utils/feature-flag.directive';
import { BarHorizontalComponent } from './highcharts/bar-horizontal/bar-horizontal.component';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  imports: [CommonModule, NgbModule, ClickOutsideModule, FormsModule, NgSelectModule, HighchartsChartModule],
  exports: [
    NgbModule,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    AccessDirective,
    FeatureFlagDirective,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    HttpLoaderComponent,
    ClickOutsideModule,
    DonutComponent,
    GaugeDirective,
    GraphComponent,
    DateFormatPipe,
    DurationPipe,
    DurationSecondsPipe,
    NumberFormatPipe,
    TranslateModule,
    ReversePipe,
    TabComponent,
    TabsComponent,
    TagsComponent,
    SiteSelectorComponent,
    JsonViewer,
    BarHorizontalComponent,
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    AccessDirective,
    FeatureFlagDirective,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    HttpLoaderComponent,
    DonutComponent,
    GaugeDirective,
    GraphComponent,
    DateFormatPipe,
    DurationPipe,
    DurationSecondsPipe,
    NumberFormatPipe,
    ReversePipe,
    TabComponent,
    TabsComponent,
    TagsComponent,
    SiteSelectorComponent,
    JsonViewer,
    BarHorizontalComponent,
  ],
  providers: [],
})
export class SharedModule {}
