import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ADMIN_CONTEXT, CONTEXT, ContextEnum, SitesService } from 'app/services/sites.service';
import { BANDWIDTH, GraphType, RESPONSE_TIME, TRAFIC } from 'app/shared/highcharts/graph/graph';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { ONE_MINUTE } from 'app/shared/utils/data-utils';
import { Validators } from 'app/shared/validators/validators';
import { saveAs } from 'file-saver';
import { firstValueFrom } from 'rxjs';
import { Global } from '../../global';
import { AuthService } from '../../services/auth.service';
import { MyLogsGraphsComponent } from './graphs/my-logs-graphs.component';

import { Country, CountryService } from 'app/services/country.service';
import { LogEntry, LogFilters, LogRequest, LogsService } from 'app/services/logs.service';
import { DateRange } from 'app/shared/utils/date-range';
import { Moment } from 'moment';

@Component({
  selector: 'app-my-logs',
  templateUrl: './my-logs.component.html',
  styleUrls: ['./my-logs.component.scss', '../../../assets/icon/icofont/css/icofont.scss'],
})
export class MyLogsComponent implements AfterViewInit {
  @ViewChild('graphsComponent') graphsComponent!: MyLogsGraphsComponent;
  @ViewChild('progressBar', { static: true }) progressBar: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('logsTable') logsTable;

  public countries: Country[];

  lang: string;
  Math = Math;

  ctx: ContextEnum = CONTEXT;

  logs: (LogEntry & { countryName?: string })[] = [];

  RESPONSE_TIME = RESPONSE_TIME;
  BANDWIDTH = BANDWIDTH;

  rules = [];

  total = 0;
  size = 20;
  index = 0;

  filters: LogFilters = {
    ips: [],
    statusCodes: [],
  };
  graphType: GraphType = TRAFIC;

  refreshInterval: any = null;
  countdownProgressInterval: any = null;
  countNextRefresh = 0;

  ADMIN_CONTEXT = ADMIN_CONTEXT;

  isLoading = false;

  logsTableMustScroll = false;

  constructor(
    private http: HttpClient,
    private logsService: LogsService,
    private auth: AuthService,
    public sites: SitesService,
    private countryService: CountryService,
    public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.lang = this.auth.getCurrentLanguage();

    const current = this.sites[this.ctx].current;
    if (this.router?.getCurrentNavigation()?.extras?.state) {
      // query-string passed
      this.parsePassedParameters();
    } else if (current.trackingFilters) {
      // else, check if there is a saved context
      Object.assign(this.filters, current.trackingFilters);
    }
  }

  ngAfterViewInit() {
    this.applyCSS();
  }

  async ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data.adminClusterView) {
        this.ctx = ADMIN_CONTEXT;
      }
    });

    await this.countryService.init();
    this.countries = this.countryService.getCountries();
    await this.sites.load(this.ctx);
    this.refresh();
  }

  ngOnDestroy() {
    this.filters = this.sites[this.ctx].current.trackingFilters = this.filters;
    this.clearRefreshInterval();
  }

  setRefreshInterval() {
    if (this.refreshInterval) {
      return;
    }

    this.countdownProgressInterval = setInterval(() => this.countNextRefresh++, 1000);
    this.refreshInterval = setInterval(() => this.refresh(), ONE_MINUTE);
  }

  clearRefreshInterval() {
    if (!this.refreshInterval) {
      return;
    }

    clearInterval(this.countdownProgressInterval);
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
    this.countNextRefresh = 0;
  }

  addTag(tag: string): string {
    return tag;
  }

  onSiteSelectionChanged() {
    this.loadData();
  }

  onPeriodEmitted(p: DateRange) {
    this.sites[this.ctx].current.period.start = p.start;
    this.sites[this.ctx].current.period.end = p.end;

    this.refresh();
  }

  onPeriodEmittedFromGraph(p: DateRange) {
    this.sites[this.ctx].current.period.recomputeEndDateToNow = false;
    this.sites[this.ctx].current.period.autoRefresh = false;
    this.sites[this.ctx].current.period.start = p.start;
    this.sites[this.ctx].current.period.end = p.end;

    this.refresh();
  }

  refresh() {
    this.index = 0;
    this.rules = [];
    this.filters.rule = '';
    this.sites[this.ctx].refreshPeriod();

    this.loadData();
  }

  loadData() {
    if (!this.sites[this.ctx].current.cluster?.id) {
      console.warn('*** loadData called with no cluster selected');
      this.graphsComponent.noSitesFallback();
      return;
    }

    this.index = 0;
    if (this.sites[this.ctx].current.period.autoRefresh) this.setRefreshInterval();
    else this.clearRefreshInterval();

    if (!this.validateFilters()) return;

    this.loadLogsPage();
    this.graphsComponent.resetData();
    this.graphsComponent.loadGraphs();
    if (this.isSingleSite()) {
      this.getRules(this.sites[this.ctx].current.selectedSites.at(0).id);
    }
  }

  async loadLogsPage() {
    if (this.isLoading || !this.sites[this.ctx].current.cluster?.id || !this.filters) {
      return;
    }

    this.isLoading = true;
    try {
      const page = await firstValueFrom(
        this.logsService.getLogs(this.getLogRequest(), { index: this.index, size: this.size }, this.ctx),
      );
      this.total = page.totalItems;
      this.logs = page.content.map((log) => {
        log['countryName'] = this.countryService.getCountryName(log.countryCode);
        return log;
      });
    } finally {
      this.isLoading = false;
    }
  }

  private getLogRequest(): LogRequest {
    return { ...this.sites[this.ctx].buildRequestData(), ...this.filters };
  }

  downloadLogs() {
    if (!this.filters) {
      return;
    }

    this.toastr.info(this.translate.instant('DownloadInProgress'), '', { disableTimeOut: true, tapToDismiss: false });

    const formatDate = (m: Moment) => m.toDate().toString().substr(4, 20).replace(/\s/g, '_');
    const period = this.sites[this.ctx].current.period;
    const beginDate = formatDate(period.start);
    const endDate = formatDate(period.end);

    this.logsService.downloadLogs(this.getLogRequest(), this.ctx).subscribe({
      next: (data) => {
        saveAs(data, `logs-${beginDate}-${endDate}.json`);
        this.toastr.clear();
        this.toastr.success(this.translate.instant('DownloadCompleted'));
      },
      error: () => {
        this.toastr.clear();
        this.toastr.error(this.translate.instant('OperationFailed'));
      },
    });
  }

  trackIp(ip, type) {
    ip = ip.trim();

    let index = this.filters.ips.indexOf(ip);

    if (index != -1) {
      if (type == 'out') {
        this.filters.ips.splice(index, 1);
        this.filters.ips = [`-${ip}`, ...this.filters.ips];
        this.loadData();
      }
    } else {
      this.filters.ips = [type == 'in' ? ip : `-${ip}`, ...this.filters.ips];
      if (type == 'in') {
        this.resetFilters('ips');
      }
      this.loadData();
    }
  }

  trackCountry(country) {
    this.filters.country = country;
    this.resetFilters('country');
    this.loadData();
  }

  trackAction(action) {
    this.filters.action = action;
    this.loadData();
  }

  trackCause(action: string, cause: string) {
    this.filters.action = action;
    this.filters.cause = cause;
    this.loadData();
  }

  async filterSite(siteName: string) {
    this.index = 0;
    const site = this.sites.setSite(this.ctx, siteName);
    await this.getRules(site.id);
  }

  async trackRule(siteName: string, rulePriority: number) {
    await this.filterSite(siteName);
    this.filters.rule = this.rules.find((r) => r.priority == rulePriority).id;
    this.loadData();
  }

  async trackSite(siteName: string) {
    await this.filterSite(siteName);
    this.filters.rule = '';
    this.loadData();
  }

  trackPath(path: string) {
    if (this.filters.path != path) {
      this.index = 0;
      this.filters.path = path;
      this.loadData();
    }
  }

  trackStatusCode(code: string) {
    if (!this.filters.statusCodes.includes(code)) {
      this.index = 0;
      this.filters.statusCodes = [code, ...this.filters.statusCodes];
      this.loadData();
    }
  }

  trackResponseTimeGte(responseTime: number) {
    this.index = 0;
    this.filters.responseTimeGte = responseTime;
    this.loadData();
  }

  trackContentLengthGte(contentLength: number) {
    this.index = 0;
    this.filters.contentLengthGte = contentLength;
    this.loadData();
  }

  async getRules(hoteId: number) {
    const res: any = await firstValueFrom(this.http.post(Global.baseUrl + 'rule/get', { data: { hoteId } }));
    this.rules = res.items ? this.sortRules(res.items.reverse()) : [];
  }

  sortRules(rules) {
    return rules.sort((a, b) => parseFloat(a.priority) - parseFloat(b.priority));
  }

  getRuleDetails(priority) {
    let r = this.rules.find((r) => r.priority == priority);
    if (r) {
      return `URIs: ${r.uris.join(', ')} | IPs: ${r.whitelistedIps.join(', ')}`;
    }
  }

  toggleExpandRow(row) {
    this.logsTable.rowDetail.toggleExpandRow(row);
  }

  changePage(evt: any) {
    this.index = evt.page - 1;
    this.loadLogsPage();
  }

  resetFilters(notModify) {
    this.index = 0;

    const resetProperties = [
      { prop: 'country', value: '' },
      { prop: 'rule', value: '' },
      { prop: 'action', value: '' },
      { prop: 'cause', value: '' },
      { prop: 'path', value: '' },
      { prop: 'statusCodes', value: [] },
      { prop: 'ips', value: [] },
    ];

    resetProperties.forEach((property) => {
      if (notModify !== property.prop) {
        this.filters[property.prop] = property.value;
      }
    });
  }

  onActionChanged() {
    this.filters.cause = '';
    this.refresh();
  }

  parsePassedParameters() {
    this.filters.country = this.getRouterParam('country');
    this.filters.action = this.getRouterParam('action');
    this.filters.cause = this.getRouterParam('cause');
    this.filters.path = this.getRouterParam('path');
    if (this.getRouterParam('ips')) {
      this.filters.ips.push(this.getRouterParam('ips'));
    }
  }

  validateFilters(): boolean {
    // TODO: handle validation elsewhere
    let hasError: boolean = false;

    if (this.filters.responseTimeGte) {
      if (typeof this.filters.responseTimeGte != 'number' || this.filters.responseTimeGte < 0) {
        this.toastr.error(this.translate.instant('WrongResponseTimeGte'));
        hasError = true;
      }
    }

    if (this.filters.contentLengthGte) {
      if (typeof this.filters.contentLengthGte != 'number' || this.filters.contentLengthGte < 0) {
        this.toastr.error(this.translate.instant('WrongContentLengthGte'));
        hasError = true;
      }
    }

    if (this.filters.statusCodes.length) {
      if (this.filters.statusCodes.some((code) => !Validators.validateHttpCode(code))) {
        this.toastr.error(this.translate.instant('WrongHttpCode'));
        hasError = true;
      }
    }

    if (this.filters.ips.length) {
      if (this.filters.ips.some((ip) => !Validators.validateIp(ip.replace('-', '')))) {
        this.toastr.error(this.translate.instant('WrongIp'));
        hasError = true;
      }
    }

    return !hasError;
  }

  isSingleSite() {
    return this.sites[this.ctx].current.selectedSites.length == 1;
  }

  getRouterParam(param) {
    return this.router?.getCurrentNavigation()?.extras?.state[param];
  }

  hasClickableRule(row: any): boolean {
    return row.rulePriority && row.rulePriority != 'supprimée' && row.rulePriority != 'deleted';
  }

  applyCSS() {
    const ngSelects = document.querySelectorAll('.ng-select-container');
    ngSelects.forEach((s) => {
      s.classList.add('custom-scrollbar');
    });

    this.breakpointObserver.observe(['(max-width: 1395px)']).subscribe((result) => {
      this.logsTableMustScroll = result.matches;
    });
  }
}
